import React from "react";
import styles from "./ColoredDiv.module.scss";

interface propParams {
  color?: "gray" | "ocean-green" | "blue" | "orange" | "gold" | "yellow" | "violet" | "green";
  className?: string;
  containerClass?: string;
  children: React.ReactNode;
  onClick?: () => void;
}

const ColoredDiv: React.FC<propParams> = ({
  className = "",
  color = "gray",
  children,
  onClick,
  containerClass = ""
}) => {
  return (
    <div className={`${styles["container"]} ${containerClass}`} onClick={onClick} >
      <div className={`hr-h-5 ${styles[`color-${color}`]}`} />
      <div className={`hr-p-10 ${className}`}>{children}</div>
    </div>
  );
};

export default ColoredDiv;
