import ApiService from "../util/api.service";
import { API_CONFIG_URLS } from "../constants/config";
import {
  forgetPasswordPayload,
  loginPayload,
  resetPasswordPayload,
  setPasswordPayload,
  signUpPayload,
  updatePasswordType
} from "../types";
import { utilService } from "../util/utils.service";
import {
  listingPayloadType,
  newHiringPayload,
  profileDetailsPayload,
  queryParameter,
  updateProfilePayload
} from "../types";

const AuthApiService = {
  login,
  signup,
  userDetails,
  logout,
  getHiringTeamListing,
  getProfileDetails,
  updateProfileDetails,
  AddHiringTeam,
  updateHiringTeamMember,
  deleteHiringTeamMember,
  updatePassword,
  forgetPassword,
  resetPassword,
  setPassword,
  getPermissions,
  getPermissionsList
};

async function login(payload: loginPayload) {
  const response = await ApiService.post(API_CONFIG_URLS.AUTH.LOGIN, payload);
  return response;
}

async function forgetPassword(payload: forgetPasswordPayload) {
  const response = await ApiService.post(API_CONFIG_URLS.AUTH.FORGET_PASSWORD, payload);
  return response;
}

async function resetPassword(payload: resetPasswordPayload) {
  const response = await ApiService.post(API_CONFIG_URLS.AUTH.RESET_PASSWORD, payload);
  return response;
}

async function signup(payload: signUpPayload) {
  const response = await ApiService.post(API_CONFIG_URLS.AUTH.SIGNUP, payload);
  return response;
}

async function logout() {
  const response = await ApiService.post(API_CONFIG_URLS.AUTH.LOGOUT);
  return response;
}

async function userDetails() {
  const response = await ApiService.get(API_CONFIG_URLS.AUTH.USER_DETAIL);
  return response;
}

async function getHiringTeamListing(payload: listingPayloadType) {
  const url = utilService.createDynamicUrl(API_CONFIG_URLS.AUTH.HIRING_TEAM_LISTING, payload);
  const response = await ApiService.get(url);
  return response;
}

async function getProfileDetails(payload: profileDetailsPayload) {
  const response = await ApiService.get(API_CONFIG_URLS.AUTH.PROFILE, payload);
  return response;
}

async function updateProfileDetails(payload: updateProfilePayload, query: profileDetailsPayload) {
  const response = await ApiService.put(API_CONFIG_URLS.AUTH.PROFILE, payload, { query });
  return response;
}

async function setPassword(payload: setPasswordPayload) {
  const response = await ApiService.put(API_CONFIG_URLS.AUTH.SET_PASSWORD, payload);
  return response;
}

async function AddHiringTeam(payload: newHiringPayload) {
  const response = await ApiService.post(API_CONFIG_URLS.AUTH.HIRING_TEAM, payload);
  return response;
}

async function updateHiringTeamMember(payload: newHiringPayload, id: queryParameter) {
  const url = utilService.createDynamicUrl(API_CONFIG_URLS.AUTH.UPDATE_HIRING_TEAM, id);
  const response = await ApiService.put(url, payload);
  return response;
}

async function deleteHiringTeamMember(id: queryParameter) {
  const url = utilService.createDynamicUrl(API_CONFIG_URLS.AUTH.UPDATE_HIRING_TEAM, id);
  const response = await ApiService.remove(url);
  return response;
}

async function updatePassword(payload: updatePasswordType) {
  const response = await ApiService.post(API_CONFIG_URLS.AUTH.UPDATE_PASSWORD, payload);
  return response;
}

async function getPermissions() {
  const response = await ApiService.get(API_CONFIG_URLS.PERMISSION.GET_PERMISSIONS);
  return response;
}

async function getPermissionsList() {
  const response = await ApiService.get(API_CONFIG_URLS.PERMISSION.GET_PERMISSIONS_LIST);
  return response;
}

export default AuthApiService;
