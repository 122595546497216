import React from "react";
import { useNavigate } from "react-router-dom";
import { Dropdown, MenuProps, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { AUTHENTICATED_ROUTES } from "../../constants/route-constant";
import { ReactComponent as ArrowDown } from "../../assets/icons/ArrowDown.svg";

interface propParams {
  logout: () => void;
  username?: string;
}
const ProfileDropdown: React.FC<propParams> = ({ logout, username = "User 001" }) => {
  const navigate = useNavigate();

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <h5
          onClick={() => {
            navigate(AUTHENTICATED_ROUTES.PROFILE);
          }}
        >
          Profile
        </h5>
      )
    },
    {
      key: "2",
      label: (
        <h5
          onClick={() => {
            navigate(AUTHENTICATED_ROUTES.CHANGE_PASSWORD);
          }}
        >
          Change Password
        </h5>
      )
    },
    {
      key: "3",
      label: (
        <div className="display-flex" onClick={logout}>
          <h5 className="text-red">Logout</h5>
        </div>
      )
    }
  ];

  return (
    <Dropdown menu={{ items }} placement="bottomRight" arrow className="cursor">
      <div className="d-flex-row align-center">
        <h4 className="hr-mr-12 hr-mr-6">{username}</h4>
        <ArrowDown />
      </div>
    </Dropdown>
  );
};

export default ProfileDropdown;
