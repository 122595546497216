import { Col, Form, Row, Skeleton } from "antd";

import Topbar from "../../../Topbar";
import Signature from "../../../../components/Signature";
import ColoredDiv from "../../../../components/ColoredDiv";
import ResumeSection from "./ResumeSection";
import RenderFormItem from "./MappingFormItems";
import FullPageLoader from "../../../../components/FullPageLoader";
import { CustomButton } from "../../../../components/Button";
import { ExternalFormLoader } from "./ExternalFormLoader";
import { fieldType, sectionType } from "../../../../types";
import { useExternalFormContainer } from "./ExternalFormContainer";
import { getLabelTranslation, utilService } from "../../../../util/utils.service";

import "./ExternalForm.scss";

const ExternalForm = () => {
  const {
    addCandidateLoader,
    language,
    loading,
    languageListing,
    setLanguage,
    languageLoading,
    templateData,
    displayAddress,
    onFormFinish,
    formInfo,
    formStep,
    form,
    file,
    setFile,
    templateInfo,
    workflowId,
    resumeUploading,
    signatureUploading
  } = useExternalFormContainer();

  if (addCandidateLoader) {
    return <FullPageLoader />;
  }
  return !utilService.isEmpty(language) && !loading ? (
    <div className="external-form-container">
      <Topbar
        isJobCreation={true}
        languageListing={languageListing?.list}
        selectedLanguage={language}
        setLanguage={setLanguage}
        isLoading={languageLoading}
      />
      <Row className="main-content">
        <Col span={24}>
          <Row className="hr-mb-10 icon-location-container" justify={"space-between"} align="bottom" gutter={[0, 6]}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <div className="responsive-logo">
                <Skeleton paragraph={{ rows: 1 }} active loading={loading}>
                  {templateData?.companyLogo ? (
                    <img src={templateData?.companyLogo} alt="logo" className="company-logo" />
                  ) : (
                    <h2 className="poppins-bold">{templateData?.company}</h2>
                  )}
                </Skeleton>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={15} xl={15} xxl={15}>
              <Skeleton paragraph={{ rows: 1 }} active loading={loading}>
                <h3 className="hr-fs-16 text-end ">{displayAddress}</h3>
              </Skeleton>
            </Col>
          </Row>
        </Col>
        <Form.Provider onFormFinish={onFormFinish}>
          <ColoredDiv color="orange" className=" hr-p-30 responive-colorDiv" containerClass="w-100">
            <p className="text-secondary-gray hr-fs-14 w-100">
              {getLabelTranslation(
                formInfo?.beforeText,
                ["formTemplate", formInfo?.formTemplateId, "beforeText"],
                language?.translation
              )}
            </p>

            {formInfo && !loading && (
              <Form form={form} scrollToFirstError>
                {templateData?.resumeUploadText && formStep === 1 && (
                  <ResumeSection
                    file={file}
                    setFile={setFile}
                    loading={loading}
                    setFieldValue={form.setFieldValue}
                    resumeUploadText={getLabelTranslation(
                      templateData?.resumeUploadText,
                      ["workflow", workflowId, "resumeUploadText"],
                      language?.translation
                    )}
                    uploadBtnText={getLabelTranslation("Choose File", ["button", "choosefile"], language?.translation)}
                    required={Boolean(templateData?.resumeUploadText)}
                    validationMessage={getLabelTranslation(
                      "This field is required",
                      ["validation", "required"],
                      language?.translation
                    )}
                  />
                )}
                <Skeleton active className="hr-mt-20" loading={loading}>
                  {templateInfo?.sections?.map((sectionInfo: sectionType) => {
                    return (
                      <>
                        <h5 className="poppins-semibold hr-mt-25">
                          {getLabelTranslation(
                            sectionInfo?.title,
                            ["section", sectionInfo?.sectionId],
                            language?.translation
                          )}
                        </h5>
                        <Col span={24} style={{ fontSize: "16px" }}>
                          <Row gutter={[8, 0]} style={{ fontSize: "16px" }}>
                            {Object.values(sectionInfo.fields).map((field: fieldType) => {
                              return RenderFormItem(
                                field,
                                form.getFieldValue,
                                form.setFieldValue,
                                language?.translation
                              );
                            })}
                          </Row>
                        </Col>
                      </>
                    );
                  })}
                </Skeleton>

                {formInfo?.signatureText && (
                  <Signature
                    label={getLabelTranslation(
                      formInfo?.signatureText,
                      ["formTemplate", formInfo?.formTemplateId, "signatureText"],
                      language?.translation
                    )}
                    setter={form.setFieldValue}
                    translation={language?.translation}
                  />
                )}

                <p className="text-secondary-gray hr-fs-14 w-100">
                  {getLabelTranslation(
                    formInfo?.afterText,
                    ["formTemplate", formInfo?.formTemplateId, "afterText"],
                    language?.translation
                  )}
                </p>
                <CustomButton
                  title={
                    formStep < templateData?.forms?.length
                      ? getLabelTranslation("Next", ["button", "next"], language?.translation)
                      : getLabelTranslation("Submit", ["button", "submit"], language?.translation)
                  }
                  type="primary"
                  htmlType="submit"
                  className="hr-mt-20"
                  isLoading={resumeUploading || addCandidateLoader || signatureUploading}
                  disabled={resumeUploading || addCandidateLoader || signatureUploading}
                />
              </Form>
            )}
          </ColoredDiv>
        </Form.Provider>
      </Row>
    </div>
  ) : (
    <ExternalFormLoader />
  );
};

export default ExternalForm;
