import ApiService from "../util/api.service";
import { API_CONFIG_URLS } from "../constants/config";

const LookupApiService = {
  userRoles,
  userExperiences,
  getLocations,
  getDepartments,
  getAccounts,
  getLocationBySearch,
  getLocationsByJobId,
  getJobOpenings,
  getLocationUsers,
  getFormTemplates,
  getWorkFlows,
  getLanguages,
  getFieldCategories
};

async function userRoles() {
  const response = await ApiService.get(API_CONFIG_URLS.LOOKUP.ROLES);
  return response;
}

async function userExperiences() {
  const response = await ApiService.get(API_CONFIG_URLS.LOOKUP.EXPERIENCES);
  return response;
}
async function getLocations(companyId?: number) {
  const response = await ApiService.get(API_CONFIG_URLS.LOOKUP.LOCATIONS, { companyId });
  return response;
}

async function getLocationsByJobId(query: { jobOpeningId?: number | undefined }) {
  const response = await ApiService.get(API_CONFIG_URLS.LOOKUP.LOCATION_BY_JOB_ID, query);
  return response;
}

async function getDepartments(companyId?: number | null) {
  const response = await ApiService.get(API_CONFIG_URLS.LOOKUP.DEPARTMENTS, { companyId });
  return response;
}

async function getAccounts(companyId: number | undefined) {
  const response = await ApiService.get(API_CONFIG_URLS.LOOKUP.ACCOUNTS, { companyId });
  return response;
}

async function getLocationBySearch(query?: any) {
  const response = await ApiService.get(API_CONFIG_URLS.LOOKUP.LOCATION_BY_SEARCH, query);
  return response;
}

async function getJobOpenings(companyId: number | undefined) {
  const response = await ApiService.get(API_CONFIG_URLS.LOOKUP.JOB_OPENINGS, { companyId });
  return response;
}

async function getLocationUsers(jobOpeningLocationId: number | undefined) {
  const response = await ApiService.get(API_CONFIG_URLS.LOOKUP.LOCATION_USERS, { jobOpeningLocationId });
  return response;
}

async function getFormTemplates(companyId: number | undefined) {
  const response = await ApiService.get(API_CONFIG_URLS.LOOKUP.FORM_TEMPLATES, { companyId });
  return response;
}

async function getWorkFlows(companyId: number | undefined) {
  const response = await ApiService.get(API_CONFIG_URLS.LOOKUP.WORK_FLOWS, { companyId });
  return response;
}

async function getLanguages(companyId: number | undefined) {
  const response = await ApiService.get(API_CONFIG_URLS.LOOKUP.LANGUAGES, { companyId });
  return response;
}

async function getFieldCategories(query?: { searchBy?: string | undefined }) {
  const response = await ApiService.get(API_CONFIG_URLS.LOOKUP.FIELD_CATEGORIES, query);
  return response;
}

export default LookupApiService;

