import { API_CONFIG_URLS, multiFormDataHeader } from "../constants/config";
import {  DeleteImageType } from "../constants/constant";
import { uploadImagePayload } from "../types";
import ApiService from "../util/api.service";
import { utilService } from "../util/utils.service";

const MediaApiService = {
  uploadProfileImage,
  deleteProfileImage,
  uploadAttachment,
  uploadMedia,
  uploadCompanyLogo,
  uploadRecruitmentCollateral,
  uploadSignature,
  deleteCompanyLogo
};

async function uploadProfileImage(payload: uploadImagePayload) {
  const response = await ApiService.post(API_CONFIG_URLS.MEDIA.PROFILE_UPLOAD, payload);
  return response;
}
async function deleteProfileImage() {
  const response = await ApiService.put(API_CONFIG_URLS.MEDIA.PROFILE_DELETE);
  return response;
}
async function deleteCompanyLogo(payload:DeleteImageType) {
  const url = utilService.createDynamicUrl(API_CONFIG_URLS.MEDIA.DELETE_COMPANY_LOGO, payload );
  const response = await ApiService.remove(url);
  return response;
}

async function uploadAttachment(payload: any) {
  const response = await ApiService.post(API_CONFIG_URLS.MEDIA.UPLOAD_ATTACHMENT, payload, {
    ...multiFormDataHeader
  });
  return response;
}

async function uploadMedia(payload: any) {
  const response = await ApiService.post(API_CONFIG_URLS.MEDIA.UPLOAD_MEDIA, payload, {
    ...multiFormDataHeader
  });
  return response;
}
async function uploadCompanyLogo(payload: any) {
  const response = await ApiService.post(API_CONFIG_URLS.MEDIA.UPLOAD_COMPANY_LOGO, payload, {
    ...multiFormDataHeader
  });
  return response;
}

async function uploadRecruitmentCollateral(payload: any) {
  const response = await ApiService.post(API_CONFIG_URLS.MEDIA.UPLOAD_RC, payload, {
    ...multiFormDataHeader
  });
  return response;
}

async function uploadSignature(payload: any) {
  const response = await ApiService.post(API_CONFIG_URLS.MEDIA.UPLOAD_SIGNATURE, payload, {
    ...multiFormDataHeader
  });
  return response;
}

export default MediaApiService;
