import { Checkbox, Col, Form, Space, Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

import { fieldType } from "../../../../../types";
import { getLabelTranslation } from "../../../../../util/utils.service";
import { optionTypes, translationType } from "../../../../../constants/constant";

type checkBoxProps = {
  field: fieldType;
  getter: (id: string) => any;
  translation: translationType;
  isMobile: boolean;
  translatedOptions: optionTypes[];
  conditionalFieldNames: any;
  getConditionArr: (conditionalFields: any, getter: any) => any;
  checkCondition: (allOne: string, conditionArr: Array<boolean>) => boolean;
};

const CheckBox = (props: checkBoxProps) => {
  const {
    field,
    getter,
    translation,
    isMobile,
    conditionalFieldNames,
    checkCondition,
    getConditionArr,
    translatedOptions
  } = props;
  const { label, required, type, id, condition, validationMessage, tip } = field;
  const name = id;
  const lowercasedType = type.toLowerCase();
  const { showHide, allOne, conditionalFields } = condition || {};
  return (
    <>
      <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
        {!condition ? (
          <div className="checkbox-group">
            <>
              <Space size={4} align={"end"}>
                <label className="external-form-label">
                  {getLabelTranslation(label, [lowercasedType, name], translation)}
                </label>
                {tip && (
                  <Tooltip
                    title={getLabelTranslation(tip, ["tip", name], translation)}
                    className="text-primary hr-fs-14"
                  >
                    <QuestionCircleOutlined />
                  </Tooltip>
                )}
              </Space>
              <Form.Item
                name={name}
                rules={[
                  {
                    required: required,
                    message: validationMessage
                      ? validationMessage
                      : getLabelTranslation("This field is required", ["validation", "required"], translation)
                  }
                ]}
                valuePropName="checked"
                required={false}
                className="external-form-item"
              >
                <Checkbox.Group options={translatedOptions} className={isMobile ? "d-flex-column " : ""} />
              </Form.Item>
            </>
          </div>
        ) : (
          <Form.Item noStyle dependencies={conditionalFieldNames}>
            {() => {
              const condition = checkCondition(allOne, getConditionArr(conditionalFields, getter));
              const shouldShow = showHide === (condition ? "show" : "hide");

              return shouldShow ? (
                <div className="checkbox-group">
                  <Space size={4} align={"end"}>
                    <label className="external-form-label">
                      {getLabelTranslation(label, [lowercasedType, name], translation)}
                    </label>
                    {tip && (
                      <Tooltip
                        title={getLabelTranslation(tip, ["tip", name], translation)}
                        className="text-primary hr-fs-14"
                      >
                        <QuestionCircleOutlined />
                      </Tooltip>
                    )}
                  </Space>

                  <Form.Item
                    name={name}
                    rules={[
                      {
                        required: required,
                        message: validationMessage
                          ? validationMessage
                          : getLabelTranslation("This field is required", ["validation", "required"], translation)
                      }
                    ]}
                    valuePropName="checked"
                    required={false}
                    className="external-form-item"
                  >
                    <Checkbox.Group options={translatedOptions} className={isMobile ? "d-flex-column " : ""} />
                  </Form.Item>
                </div>
              ) : (
                <></>
              );
            }}
          </Form.Item>
        )}
      </Col>
    </>
  );
};
export default CheckBox;
