import { Col, Form } from "antd";

import { fieldType } from "../../../../../types";
import { translationType } from "../../../../../constants/constant";
import { getLabelTranslation } from "../../../../../util/utils.service";

type noteType = {
  field: fieldType;
  getter: (id: string) => any;
  translation: translationType;
  screenColumns: number;
  conditionalFieldNames: any;
  getConditionArr: (conditionalFields: any, getter: any) => any;
  checkCondition: (allOne: string, conditionArr: Array<boolean>) => boolean;
};
const Note = (props: noteType) => {
  const { field, getter, translation, screenColumns, conditionalFieldNames, checkCondition, getConditionArr } = props;
  const { label, type, id, condition, predefinedValue } = field;
  const name = id;
  const lowercasedType = type.toLowerCase();
  const { showHide, allOne, conditionalFields } = condition || {};
  return (
    <Col xs={24} sm={24} md={screenColumns} lg={screenColumns} xl={screenColumns} xxl={screenColumns}>
      {!condition ? (
        <>
          <label className="external-form-label">
            {getLabelTranslation(label, [lowercasedType, name], translation)}
          </label>
          <p className="hr-fs-14 text-light-secondary">
            {getLabelTranslation(predefinedValue, ["predefinedvalue", name], translation)}
          </p>
        </>
      ) : (
        <Form.Item noStyle dependencies={conditionalFieldNames}>
          {() => {
            const condition = checkCondition(allOne, getConditionArr(conditionalFields, getter));
            const shouldShow = showHide === (condition ? "show" : "hide");

            return shouldShow ? (
              <>
                <label className="external-form-label ">
                  {getLabelTranslation(label, [lowercasedType, name], translation)}
                </label>
                <p className="hr-fs-14 text-light-secondary">
                  {getLabelTranslation(predefinedValue, ["predefinedvalue", name], translation)}
                </p>
              </>
            ) : (
              <></>
            );
          }}
        </Form.Item>
      )}
    </Col>
  );
};

export default Note;
