import { fieldType } from "../../../../types";
import { formatDate } from "../../../../util/dateTime.service";
import { allAreTrue, getLabelTranslation, isAnyTrue } from "../../../../util/utils.service";
import { CheckBox, DropDown, Note, Paragraph, RadioButton, TextArea } from "./ExternalFormFields";
import {
  DRAG_TYPE,
  ERROR_MESSAGES,
  FILLED_OPTIONS,
  VALIDATION_CONSTANTS,
  dateRegex,
  emailRegex,
  numberRegex,
  ssnRegex,
  translationType,
  zipCodeRegex
} from "./../../../../constants/constant";

import "./ExternalForm.scss";

function getConditionFlag(getFieldValue: (id: string) => any, field: any) {
  if (!field) {
    return false;
  }

  const { fieldId, fieldValue, filledOption } = field;
  switch (filledOption) {
    case FILLED_OPTIONS.IS_EQUALS:
      return getFieldValue(fieldId) === fieldValue || getFieldValue(fieldId)?.includes(fieldValue);

    case FILLED_OPTIONS.CONTAINS:
      return getFieldValue(fieldId)?.includes(fieldValue);

    case FILLED_OPTIONS.NOT_EQUALS:
      return getFieldValue(fieldId) !== fieldValue || !getFieldValue(fieldId)?.includes(fieldValue);

    case FILLED_OPTIONS.IS_FILLED:
      return !!getFieldValue(fieldId);
  }
  return false;
}

function checkCondition(allOne: string, conditionArr: Array<boolean>) {
  let flag = false;

  if (allOne === "all") {
    flag = allAreTrue(conditionArr);
  } else {
    flag = isAnyTrue(conditionArr);
  }

  return flag;
}

function getConditionArr(conditionalFields: any, getter: (id: string) => any) {
  const conditionArr = conditionalFields?.map((field: fieldType) => {
    return getConditionFlag(getter, field);
  });

  return conditionArr;
}

function getCustomValidation(validation: string) {
  switch (validation) {
    case VALIDATION_CONSTANTS.EMAIL:
      return { pattern: new RegExp(emailRegex), message: ERROR_MESSAGES.INVALID_EMAIL };

    case VALIDATION_CONSTANTS.DATE:
      return {
        validator: (_: any, momentDate: any) => {
          if (!momentDate) {
            return Promise.reject("");
          }
          const value = formatDate(momentDate);
          if (!dateRegex.test(value)) {
            return Promise.reject(ERROR_MESSAGES.INVALID_DATE);
          }
          return Promise.resolve("");
        }
      };

    case VALIDATION_CONSTANTS.DATE_RANGE:
      return {
        validator: (_: any, momentDate: any) => {
          if (!momentDate) {
            return Promise.reject("");
          }
          return Promise.resolve("");
        }
      };
    case VALIDATION_CONSTANTS.SSN:
      return { pattern: new RegExp(ssnRegex), message: ERROR_MESSAGES.INVALID_SSN };
    case VALIDATION_CONSTANTS.ZIP:
      return { pattern: new RegExp(zipCodeRegex), message: ERROR_MESSAGES.INVALID_ZIP };
    default:
      return;
  }
}

const RenderFormItem = (
  field: fieldType,
  getter: (id: string) => any,
  setter: any,
  translation: translationType,
  isMobile: boolean = false
) => {
  const { options, type, condition } = field;

  const { showHide, allOne, conditionalFields } = condition || {};

  const conditionalFieldNames: Array<string> = conditionalFields?.map((field: fieldType) => field?.fieldId);

  const translatedOptions = options?.map((option: any) => {
    option.label = getLabelTranslation(option.label, ["option", option.id], translation);
    return option;
  });

  const screenColumns1 = isMobile ? 24 : 12;
  const screenColumns2 = isMobile ? 12 : 24;

  switch (type) {
    case DRAG_TYPE.PARAGRAPH:
      return (
        <Paragraph
          field={field}
          getter={getter}
          translation={translation}
          screenColumns={screenColumns1}
          conditionalFieldNames={conditionalFieldNames}
          checkCondition={checkCondition}
          getConditionArr={getConditionArr}
          getCustomValidation={getCustomValidation}
        />
      );
    case DRAG_TYPE.TEXT_AREA:
      return (
        <TextArea
          field={field}
          getter={getter}
          setter={setter}
          translation={translation}
          conditionalFieldNames={conditionalFieldNames}
          checkCondition={checkCondition}
          getConditionArr={getConditionArr}
          getCustomValidation={getCustomValidation}
        />
      );

    case DRAG_TYPE.CHECKBOX:
      return (
        <CheckBox
          field={field}
          getter={getter}
          translation={translation}
          isMobile={isMobile}
          checkCondition={checkCondition}
          getConditionArr={getConditionArr}
          translatedOptions={translatedOptions}
          conditionalFieldNames={conditionalFieldNames}
        />
      );
    case DRAG_TYPE.RADIO:
      return (
        <RadioButton
          field={field}
          getter={getter}
          setter={setter}
          translation={translation}
          isMobile={isMobile}
          checkCondition={checkCondition}
          getConditionArr={getConditionArr}
          conditionalFieldNames={conditionalFieldNames}
        />
      );
    case DRAG_TYPE.DROP_DOWN:
      return (
        <DropDown
          field={field}
          getter={getter}
          translation={translation}
          translatedOptions={translatedOptions}
          screenColumns={screenColumns1}
          checkCondition={checkCondition}
          getConditionArr={getConditionArr}
          conditionalFieldNames={conditionalFieldNames}
        />
      );

    case DRAG_TYPE.NOTE:
      return (
        <Note
          field={field}
          getter={getter}
          translation={translation}
          screenColumns={screenColumns2}
          conditionalFieldNames={conditionalFieldNames}
          checkCondition={checkCondition}
          getConditionArr={getConditionArr}
        />
      );

    default:
      break;
  }
};

export default RenderFormItem;
