import { useQuery } from "@tanstack/react-query";
import { listingPayloadType } from "../../../../types";
import { JobOpeningApiService } from "../../../../apiServices";

export const externalQueryKeys = {
  getExternalList: (payload: listingPayloadType) => ["EXTERNAL_LOCATIONS", "LIST", payload]
};

export const useGetExternalList = (payload: listingPayloadType) => {
  return useQuery(
    externalQueryKeys.getExternalList(payload),
    async () => {
      const { ok, response, data } = await JobOpeningApiService.getExternalList(payload);
      if (ok) {
        return data;
      }
      throw response?.message;
    },
    {
      onError: (err) => {
        throw err;
      },
      staleTime: 0
    }
  );
};

