import React from "react";
import { Col, Form } from "antd";
import  { useRef, useState } from "react";

import FileUpload from "../FileUpload";
import FloatInput from "../FloatInput";
import FloatSelect from "../FloatSelect";
import { CustomButton } from "../Button";
import SignatureCanvas from "react-signature-canvas";
import { generateUniqueId, getLabelTranslation } from "../../util/utils.service";

export const SIGNATURE_TYPE = {
  TYPE: "TYPE",
  UPLOAD: "UPLOAD",
  MANUAL: "MANUAL"
};

const Signature: React.FC<any> = React.memo(({ label, setter, translation }: { label: string; setter: any; translation: any }) => { 
   const [file, setFile] = useState<any>({});
  const canvasRef = useRef<any>();

  const name = generateUniqueId();

  const handleCanvasClear = () => {
    setter(`${name}_MANUAL`, undefined);
    canvasRef.current.clear();
  };

  const handleSign = () => {
    setter(`${name}_MANUAL`, canvasRef.current.getTrimmedCanvas().toDataURL("image/png"));
  };

  const handleSignatureTypeChange = () => {
    setter(name, undefined);
    setFile({});
  };
  
  return (
    <>
      <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
        <Form.Item
          name={"signatureType"}
          rules={[
            {
              required: true,
              message: getLabelTranslation("This field is required", ["validation", "required"], translation)
            }
          ]}
        >
          <FloatSelect
            label={label}
            options={[
              { value: SIGNATURE_TYPE.TYPE, label: getLabelTranslation("Type", ["signature", "type"], translation) },
              {
                value: SIGNATURE_TYPE.UPLOAD,
                label: getLabelTranslation("Upload", ["signature", "upload"], translation)
              },
              {
                value: SIGNATURE_TYPE.MANUAL,
                label: getLabelTranslation("Manual", ["signature", "manual"], translation)
              }
            ]}
            onChange={handleSignatureTypeChange}
          />
        </Form.Item>
      </Col>

      <Col xs={24}>
        <Form.Item noStyle dependencies={["signatureType"]}>
          {({ getFieldValue }) => {
            switch (getFieldValue("signatureType")) {
              case SIGNATURE_TYPE.TYPE:
                return (
                  <Form.Item
                    name={`${name}_BASIC`}
                    required={false}
                    rules={[
                      {
                        required: true,
                        message: `Please type your name here`
                      }
                    ]}
                  >
                    <FloatInput label={"Type your name"} placeholder={"Enter your name"}  />
                  </Form.Item>
                );
              case SIGNATURE_TYPE.UPLOAD:
                return (
                  <Form.Item name={`${name}_UPLOAD`} required={false}>
                    <FileUpload
                      name={"signatureUpload"}
                      file={file}
                      setFile={setFile}
                      setFieldValue={setter}
                      loading={false}
                      allowedTypes={".png"}
                      multipleFiles={false}
                      maxFiles={1}
                    />
                  </Form.Item>
                );

              case SIGNATURE_TYPE.MANUAL:
                return (
                  <Form.Item
                    name={`${name}_MANUAL`}
                    required={false}
                    rules={[
                      {
                        required: true,
                        message: `Please add your signature here`
                      }
                    ]}
                  >
                    <SignatureCanvas
                      ref={canvasRef}
                      penColor="black"
                      canvasProps={{ className: "signature-canvas" }}
                      onEnd={handleSign}
                    />
                    <CustomButton title={"Clear"} type={"default"} htmlType="button" onClick={handleCanvasClear} />
                  </Form.Item>
                );

              default:
                return <></>;
            }
          }}
        </Form.Item>
      </Col>
    </>
  );
});

export default Signature;