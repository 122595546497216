import { Col, Form } from "antd";

import { fieldType } from "../../../../../types";
import FloatSelect from "../../../../../components/FloatSelect";
import { getLabelTranslation } from "../../../../../util/utils.service";
import { optionTypes, translationType } from "../../../../../constants/constant";

type dropDownType = {
  field: fieldType;
  getter: (id: string) => any;
  translation: translationType;
  translatedOptions: optionTypes[];
  screenColumns: number;
  conditionalFieldNames: Array<string>;
  getConditionArr: (conditionalFields: any, getter: any) => any;
  checkCondition: (allOne: string, conditionArr: Array<boolean>) => boolean;
};

const DropDown = (props: dropDownType) => {
  const {
    field,
    getter,
    translation,
    screenColumns,
    translatedOptions,
    conditionalFieldNames,
    checkCondition,
    getConditionArr
  } = props;
  const { label, required, type, id, condition, validationMessage, tip, predefinedValue } = field;
  const name = id;
  const lowercasedType = type.toLowerCase();
  const { showHide, allOne, conditionalFields } = condition || {};
  return (
    <Col xs={24} sm={24} md={screenColumns} lg={screenColumns} xl={screenColumns} xxl={screenColumns}>
      {!condition ? (
        <>
          <Form.Item
            name={name}
            rules={[
              {
                required: required,
                message: validationMessage
                  ? validationMessage
                  : getLabelTranslation("This field is required", ["validation", "required"], translation)
              }
            ]}
            className="external-form-item"
            initialValue={predefinedValue}
          >
            <FloatSelect
              label={getLabelTranslation(label, [lowercasedType, name], translation)}
              options={translatedOptions}
              tip={getLabelTranslation(tip, ["tip", name], translation)}
            />
          </Form.Item>
        </>
      ) : (
        <Form.Item noStyle dependencies={conditionalFieldNames}>
          {() => {
            const condition = checkCondition(allOne, getConditionArr(conditionalFields, getter));
            const shouldShow = showHide === (condition ? "show" : "hide");

            return shouldShow ? (
              <>
                <Form.Item
                  name={name}
                  rules={[
                    {
                      required: required,
                      message: validationMessage
                        ? validationMessage
                        : getLabelTranslation("This field is required", ["validation", "required"], translation)
                    }
                  ]}
                  className="external-form-item"
                  initialValue={predefinedValue}
                >
                  <FloatSelect
                    label={getLabelTranslation(label, [lowercasedType, name], translation)}
                    options={translatedOptions}
                    tip={getLabelTranslation(tip, ["tip", name], translation)}
                  />
                </Form.Item>
              </>
            ) : (
              <></>
            );
          }}
        </Form.Item>
      )}
    </Col>
  );
};

export default DropDown;
