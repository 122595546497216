import { Card, Checkbox, Col, Empty, Form, Pagination, Row, Skeleton, Space, Tag } from "antd";

import { PAGE_SIZE } from "../../../../constants/constant";
import { hasDatePassed } from "../../../../util/dateTime.service";
import { getLabelTranslation } from "../../../../util/utils.service";
import { ExternalListingsLoader } from "./ExternalListingsLoader";
import { ExclamationCircleFilled } from "@ant-design/icons";

function JobListings(props: any) {
  const { isLoading, totalCount, listings, translation, onPaginate } = props;

  const onPaginationChange = (page: number, pageSize: number) => {
    const newOffset = pageSize * (page - 1);
    return onPaginate && onPaginate(newOffset, pageSize);
  };

  const renderLoader = () => {
    return <ExternalListingsLoader rows={5} size="large" />;
  };

  const renderPlaceholder = () => {
    return (
      <Col className="h-50vh display-flex">
        <Empty description={"There are currently no job openings at this location"} />
      </Col>
    );
  };

  const renderListings = () => {
    return (
      <Col xs={24}>
        <h3 className="poppins-bold">{getLabelTranslation("Job Openings", ["text", "jobOpenings"], translation)}</h3>

        <div className="jobs-scroll-view hr-mt-5">
          <Form.Item name={"jobOpeningIds"} noStyle>
            <Checkbox.Group className="w-100">
              {listings?.map((job: any) => {
                return (
                  <Card className="hr-mb-10 " key={job?.id}>
                    <Checkbox value={job?.id}>
                      <Space>
                        <h4 className="poppins-medium">
                          {getLabelTranslation(job.title, ["jobOpening", job.id, "title"], translation)}
                        </h4>
                        {!job.isAlwaysOpen && hasDatePassed(job.endsAt) && (
                          <Tag icon={<ExclamationCircleFilled />} color="gold">
                            Expired
                          </Tag>
                        )}
                      </Space>
                    </Checkbox>
                  </Card>
                );
              })}
            </Checkbox.Group>
          </Form.Item>
        </div>
      </Col>
    );
  };

  return (
    <Row justify={"center"}>
      <Col xs={24} lg={12}>
        <Row className="w-100 hr-mt-20" justify={"center"} align={"middle"}>
          {isLoading ? renderLoader() : !listings?.length ? renderPlaceholder() : renderListings()}
        </Row>

        <Row justify={"end"} className="w-100 hr-mt-8">
          <Col>
            <Pagination
              className="hr-mt-10 customized-ant-pagination"
              size="small"
              defaultCurrent={1}
              disabled={isLoading}
              hideOnSinglePage
              pageSize={PAGE_SIZE}
              total={totalCount}
              onChange={onPaginationChange}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default JobListings;
