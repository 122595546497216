import { Col, Result, Row } from "antd";

import "./index.scss"
import { CustomButton } from "../../../../components/Button";
import { ReactComponent as ThankYou } from "../../../../assets/images/thankyou-image.svg";
import { useLocation, useNavigate } from "react-router";

const ThankyouPage = () => {
  const {
    state: { externalLocationsUrl }
  } = useLocation();
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate(externalLocationsUrl);
  };

  return (
    <Row justify={"center"} align={"middle"} className="thankyou-page">
      <Col xs={24}>
      <Result
        icon={<ThankYou className="thankyou-img"/>}
        title={"Thankyou for applying to this job!"}
        subTitle={`We've received your application and you will be contacted soon!`}
        extra={<CustomButton type="primary" title="Browse Jobs" onClick={handleRedirect} />}
        />
        </Col>
    </Row>
  );
};

export default ThankyouPage;
