import { Col, Row, Spin } from "antd";
import Topbar from "../../../Topbar";
import ColoredDiv from "../../../../components/ColoredDiv";

export const ExternalFormLoader = () => {
  return (
    <div className="external-form-container">
      <Topbar isJobCreation={true} />
      <Row className="main-content h-100">
        <Col span={24}>
          <ColoredDiv color="orange" className="d-flex justify-center align-center h-100" containerClass="w-100 h-100">
            <Spin className="d-flex" />
          </ColoredDiv>
        </Col>
      </Row>
    </div>
  );
};
