import { Form, Row, Skeleton } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import Topbar from "../../../Topbar";
import JobListings from "./JobListings";
import useGridRequest from "../../../../hooks/useGridRequest";
import FullPageLoader from "../../../../components/FullPageLoader";
import notificationService from "../../../../util/notification.service";
import NearbyLocationsListing from "./NearbyLocationsListing";
import { CustomButton } from "../../../../components/Button";
import { useGetExternalList } from "./ExternalJobLocationQueries";
import { useLookupLanguages } from "../Localization/localizationQueries";
import { getLabelTranslation } from "../../../../util/utils.service";
import { AUTHENTICATED_ROUTES } from "../../../../constants/route-constant";

import "./ExternalJobLocation.scss";

function ExternalJobLocation() {
  const [language, setLanguage] = useState<any>({});
  const [form] = Form.useForm();
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const location = window.location;
  const externalLocationsUrl = location.pathname + location.search; //check this logic and try to improve

  const isQr = searchParams.get("isQr");
  const isSms = searchParams.get("isSms");
  const companyId = searchParams.get("companyId");
  const companyLocationId = searchParams.get("companyLocationId");

  const {
    gridRequest: externalListRequest,
    getNormalizeRequest,
    onPagination
  } = useGridRequest({
    companyLocationId: Number(companyLocationId)
  });
  const {
    data: externalList,
    isLoading: externalListLoading,
    isFetching: externalListFetching
  } = useGetExternalList(getNormalizeRequest(externalListRequest));

  const { data: languageListing, isLoading: languageLoading } = useLookupLanguages(externalList?.companyId);

  const onNavigateExternal = (basicUrl: string, jobOpeningIds: Array<number>, locationIds: Array<number>) => {
    if (isQr) {
      basicUrl += `&isQr=${isQr}`;
    }
    if (isSms) {
      basicUrl += `&isSms=${isSms}`;
    }
    navigate(basicUrl, {
      state: { jobOpeningIds, locationIds, externalLocationsUrl: externalLocationsUrl, language: language }
    });
  };

  useEffect(() => {
    if (languageListing) setLanguage(languageListing?.defaultLanguage);
  }, [languageListing]);

  const onSubmit = (values: any) => {
    const { jobOpeningIds, jobLocationIds } = values;

    const defaultId = externalList?.nearbyLocations[0]?.companyLocationId;

    const locationIds = [defaultId, ...(jobLocationIds || [])];

    if (!jobOpeningIds || !jobOpeningIds.length) {
      notificationService.warning("Please select a job to apply");
      return;
    }

    let url = `${AUTHENTICATED_ROUTES.EXTERNAL_FORM}?companyId=${externalList?.companyId}&companyLocationId=${companyLocationId}`;

    onNavigateExternal(url, jobOpeningIds, locationIds);
  };

  if (languageLoading) return <FullPageLoader />;
  return (
    <div className="external-listing-container">
      <Topbar
        isJobCreation={true}
        languageListing={languageListing?.list}
        selectedLanguage={language}
        setLanguage={setLanguage}
        isLoading={languageLoading}
      />

      <div className="main-content">
        <div className="d-flex-column align-center hr-mt-10">
          {externalListFetching ? (
            <Skeleton.Button active block />
          ) : (
            <>
              {externalList?.companyLogo ? (
                <img src={externalList?.companyLogo} alt="logo" className="company-logo" />
              ) : (
                <h2 className="poppins-bold">{externalList?.company}</h2>
              )}
            </>
          )}
          <Skeleton
            paragraph={{ rows: 0 }}
            className="default-location-loader"
            loading={externalListLoading || externalListFetching}
            active={externalListLoading || externalListFetching}
          >
            <h3 className="poppins hr-fs-16">
              {externalList?.nearbyLocations && externalList?.nearbyLocations[0]?.address}
            </h3>
          </Skeleton>
        </div>
        <Form className="w-100 d-flex-column justify-center" form={form} onFinish={onSubmit}>
          <JobListings
            listings={externalList?.jobOpenings}
            onPaginate={onPagination}
            totalCount={externalList?.filteredCount}
            isLoading={externalListLoading || externalListFetching}
            translation={language?.translation}
          />
          {externalList?.nearbyLocations?.length > 1 && (
            <NearbyLocationsListing
              data={externalList?.nearbyLocations}
              translation={language?.translation}
              isLoading={externalListLoading || externalListFetching}
            />
          )}

          {!(externalListLoading || externalListFetching || !externalList?.jobOpenings?.length) && (
            <Row justify={"center"}>
              <CustomButton
                title={getLabelTranslation("Apply", ["button", "apply"], language?.translation)}
                htmlType="submit"
                className="hr-mt-20"
              />
            </Row>
          )}
        </Form>
      </div>
    </div>
  );
}

export default ExternalJobLocation;
