import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import dayjs, { Dayjs, OpUnitType } from "dayjs";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.extend(relativeTime);

export const DATE_TIME_FORMAT = {
  US_DATE_FORMAT_SLASH: "MM/DD/YYYY",
  FORMAT: "YYYY-MM-DD",
  LONG_DATE_FORMAT: "YYYY-MM-DD HH:mm:ss",
  US_DATE_FORMAT: "MM-DD-YYYY",
  DATE_FORMAT: "MM-DD-YYYY",
  TIME: "hh:mm A",
  US_DATE_TIME_FORMAT: "MM/DD/YY - h:mm A",
  US_TIME_FORMAT: "h:mm A",
  YEAR: "YYYY",
  FORMAT_TIME_ZONE: "YYYY-MM-DDTHH:mm:ss.SSZ",
  AM_PM_FORMAT: "hh:mm a",
  TIME_ZONE_FORMAT: "YYYY-MM-DDTHH:mm:ss.SSS[Z]",
  LONG_FORMATTED_DATE: "dddd, MMMM D, YYYY",
  DAY_INITIALS: "ddd",
  DATE: "DD",
  MONTH_INITIALS: "MMM",
  SHORT_TIME_FORMAT: "h A",
  SHORT_DATE_FORMAT: "MMM DD, YYYY",
  REVERSED_FORMAT: "DD-MM-YYYY"
};

export const getCurrentDateTime = (date: string | Date | Dayjs, format = DATE_TIME_FORMAT.US_DATE_TIME_FORMAT) => {
  return dayjs.utc(date).local().format(format);
};

export function disabledBeforeToday(current: string | Date | Dayjs) {
  // Can not select days after today
  return current < dayjs().startOf("day");
}

export function disabledAfterToday(current: string | Date | Dayjs) {
  // Can not select days after today
  return current > dayjs().endOf("day");
}

export function getFormattedTime(time: string, format: string) {
  return dayjs.utc(time).local().format(format);
}

export function getFormattedDate(date: string, format: string) {
  return dayjs.utc(date).local().format(format);
}

export function getStartEndTimePeriod(timePeriodType: string, startOrEnd: string) {
  if (startOrEnd === "start") {
    return getCurrentDateTime(dayjs().startOf(timePeriodType as OpUnitType), DATE_TIME_FORMAT.TIME_ZONE_FORMAT);
  }
  return getCurrentDateTime(dayjs().endOf(timePeriodType as OpUnitType), DATE_TIME_FORMAT.TIME_ZONE_FORMAT);
}

export function getTimeZone() {
  const userTimezone = dayjs.tz.guess();

  const currentTimeInUserTimezone = dayjs().tz(userTimezone);
  const timezoneOffsetString = currentTimeInUserTimezone.format("Z");
  const currentTimezone = new Intl.DateTimeFormat("en", { timeZoneName: "long" }).format();
  const timezoneName = currentTimezone.split(",")[1].trim();

  const formattedTimezone = `GMT(${timezoneOffsetString}) ${timezoneName}`;

  return formattedTimezone;
}

export function formatDate(dateObj: any) {
  const newObj = dateObj.toDate();
  const dateString = dayjs(newObj).format(DATE_TIME_FORMAT.REVERSED_FORMAT);

  return dateString;
}

// export function calculateDaysFromNow(targetDate: string) {
//   const now = dayjs();
//   const specifiedDate = dayjs(targetDate);
//   const durationObj = dayjs.duration(specifiedDate.diff(now));

//   const weeks = durationObj.weeks();
//   const days = durationObj.days() % 7;
//   const hours = durationObj.hours();

//   const formattedResult = `${weeks}w ${days}d ${hours}h`;
//   const timeStr = formattedResult.replaceAll("-", "");
//   return timeStr;
// }

export function calculateDaysFromNow(targetDate: string) {
  const specifiedDate = dayjs.utc(targetDate).local();
  const timeAgo = specifiedDate.fromNow();

  return timeAgo;
}

export function getUtcTime(time: string | Date | Dayjs) {
  return dayjs.utc(time).format(DATE_TIME_FORMAT.TIME_ZONE_FORMAT);
}

export function hasDatePassed(date: string | Date | Dayjs) {
  const inputDate = dayjs.utc(date).local();

  const currentDate = dayjs();

  const hasPassed = inputDate.isBefore(currentDate, "day");

  return hasPassed;
}

export const getEndDateTime = (date: string | Date | Dayjs, format = DATE_TIME_FORMAT.US_DATE_TIME_FORMAT) => {
  return dayjs.utc(date).local().endOf("day").format(format);
};

export const getStartDateTime = (date: string | Date | Dayjs, format = DATE_TIME_FORMAT.US_DATE_TIME_FORMAT) => {
  return dayjs.utc(date).local().startOf("day").format(format);
};
