import React from "react";
import { Button, Card } from "antd";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

import AuthWrapper from "../../../layouts/AuthWrapper";

import styles from "./Auth.module.scss";
import { UNAUTHENTICATED_ROUTES } from "../../../constants/route-constant";

const CheckEmail = ({ email, loading, onSubmit }: { email?: string; loading: boolean; onSubmit: any }) => {
  const [disableButton, setDisableButton] = useState(false);
  const [countdown, setCountdown] = useState(60);
  const navigate = useNavigate();

  let intervalId: NodeJS.Timer;

  const HandleClick = () => {
    onSubmit({ email });
    if (!loading) {
      setDisableButton(true);
     intervalId = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);

      setTimeout(() => {
        clearInterval(intervalId);
        setDisableButton(false);
        setCountdown(60);
      }, 60000);
    }
  };

  useEffect(() => {
    // Cleanup function to clear the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    }
  }, []);
  return (
    <AuthWrapper>
      <Card className={`${styles["card-container"]}`}>
        <div className={`${styles["email-container"]}`}>
          <h1 className={`hr-mb-40 ${styles["heading-input"]}`}>Check Your Email</h1>
          <p className={`hr-mb-40 ${styles["password-reset"]}`}>
            We have sent a password reset link to <span className={`${styles.email}`}>{email}</span>
          </p>
          <p className={` ${styles["receive-email"]}`}>Didn't receive the link?</p>
          <Button
            // onClick={() => navigate(UNAUTHENTICATED_ROUTES.RESET_PASSWORD)}
            onClick={HandleClick}
            type="primary"
            className={`${styles["btn-auth"]} ${styles["hr-mb-0"]}` }
            loading={loading}
            disabled={loading ? true : disableButton}
          >
            Resend
          </Button>

          {disableButton && (
            <p style={{ textAlign: "center" }} className="timer-tag">
              Resend request in {countdown} seconds
            </p>
          )}

          <p
            className={`${styles["back-to"]}  ${styles["hr-mt-28"]}`}
            onClick={() => {
              navigate(UNAUTHENTICATED_ROUTES.LOGIN);
            }}
          >
            Back To Login
          </p>
        </div>
      </Card>
    </AuthWrapper>
  );
};

export default CheckEmail;
