import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { Image, Skeleton } from "antd";
import React, { useState } from "react";

import LogoutModal from "./LogoutModal";
import CustomAvatar from "../../components/CustomAvatar";
import ProfileDropdown from "../../components/ProfileDropdown";
import NotificationBell from "../../components/NotificationBell";
import { utilService } from "../../util/utils.service";
import { userInfoType } from "../../types";
import { CustomSelect } from "../../components/Select";
import { STORAGE_CONST } from "../../constants/constant";
import { AUTHENTICATED_ROUTES } from "../../constants/route-constant";
import { ReactComponent as AppLogo } from "../../assets/icons/app-logo-light.svg";

import "./index.scss";

interface TopBarProps {
  isJobCreation?: boolean;
  languageListing?: Array<any>;
  selectedLanguage?: any;
  setLanguage?: (language: any) => void;
  isLoading?: Boolean;
}

const Topbar: React.FC<TopBarProps> = ({
  isJobCreation = false,
  languageListing,
  selectedLanguage,
  setLanguage,
  isLoading
}) => {
  const { data: userInfo }: { data?: userInfoType } = useQuery([STORAGE_CONST.USER_INFO], () => {}, { enabled: false });
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const navigate = useNavigate();
  const navigateToHome = () => {
    navigate(AUTHENTICATED_ROUTES.JOB_OPENING);
  };

  const data = [
    {
      description: "You just have receive a job",
      createdAt: "Just Now",
      type: 1,
      id: 1
    },
    {
      description: "New user registered",
      createdAt: "59 minutes ago",
      type: 2,
      id: 2
    },
    {
      description: "You have a new order from Alex",
      createdAt: "12 hours ago",
      type: 3,
      id: 3
    },
    {
      description: "You have a new order from Adrian",
      createdAt: "Today, 11:59 AM",
      type: 4,
      id: 4
    }
  ];

  const handleLanguageChange = (id: any) => {
    if (setLanguage) {
      const selectedLanguage: any = languageListing?.filter((translation: any) => translation.id === id) || {};
      setLanguage(selectedLanguage[0]);
    }
  };

  const getContent = () => {
    return !isJobCreation ? (
      <>
        <LogoutModal
          visible={showLogoutModal}
          onCancel={() => {
            setShowLogoutModal(false);
          }}
          onSuccess={() => {
            setShowLogoutModal(false);
          }}
        />
        <div className="img-container">
          {userInfo?.companyLogo?.viewableLink ? (
            <Image preview={false} src={userInfo?.companyLogo?.viewableLink} alt="app logo" className="img-container" />
          ) : null}
        </div>

        <div className="display-flex">
          <NotificationBell data={data} />
          <CustomAvatar
            imageUrl={userInfo?.imageUrl}
            name={userInfo?.fullName}
            className="hr-mr-15 hr-ml-25"
            size={45}
          />
          <ProfileDropdown username={userInfo?.fullName} logout={() => setShowLogoutModal(true)} />
        </div>
      </>
    ) : (
      <div className="external-form-header">
        <AppLogo onClick={navigateToHome} />
        {isLoading ? (
          <Skeleton.Button active={true} />
        ) : (
          <>
            {languageListing && (
              <div className="display-flex gap-6">
                <p className="poppins-medium language-dropdown">Select Language</p>
                <CustomSelect
                  options={languageListing}
                  defaultValue={
                    !utilService.isEmpty(selectedLanguage)
                      ? selectedLanguage
                      : languageListing?.find((language: any) => language.isDefault)
                  }
                  onChange={handleLanguageChange}
                />
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  return <div className={!isJobCreation ? "topbar-container" : "detail-topbar-container"}>{getContent()}</div>;
};

export default Topbar;
