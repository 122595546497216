import { Form } from "antd";
import { ClientJS } from "clientjs";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { sectionType } from "../../../../types";
import { formatDate } from "../../../../util/dateTime.service";
import { useLookupLanguages } from "../Localization/localizationQueries";
import { FORM_PAYLOAD_CONSTANTS } from "./utils/constants";
import { useGetExternalWorkflow } from "../Workflows/workflowQueries";
import { UNAUTHENTICATED_ROUTES } from "../../../../constants/route-constant";
import { generateFieldValue, generatePayload } from "./utils/externalFormUtils";
import { SESSION_REFERER, VALIDATION_CONSTANTS } from "../../../../constants/constant";
import { base64ToBlob, getSignatureKey, searchKeyWithSubstring, utilService } from "../../../../util/utils.service";
import {
  useAddCandidate,
  useCandidateSession,
  useGetCompanyLocation,
  useUploadAttachment,
  useUploadSignature
} from "./externalFormQueries";

import "./ExternalForm.scss";

type infoType = {
  values: any;
  forms: any;
};

export type formResponseType = {
  [key: string]: any;
};

type fieldValueType = {
  fieldIdentifier: string;
  type: string;
  label: string;
  answers: Array<string> | null | [];
};

const client = new ClientJS();

let payload: any = {};
let formResponses: Array<any> = [];
const mediaIds: Array<number> = [];

export const useExternalFormContainer = () => {
  const [file, setFile] = useState<any>({});
  const [formStep, setFormStep] = useState<number>(1);
  const [language, setLanguage] = useState<any>({});
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { state } = useLocation();
  let [searchParams] = useSearchParams();

  const userAgent = client.getUserAgent();

  const isQr = searchParams.get("isQr");
  const isSms = searchParams.get("isSms");
  const companyId = searchParams.get("companyId");
  const workflowId = searchParams.get("workflowId");
  const companyLocationId = searchParams.get("companyLocationId");

  const { data: languageListing, isLoading: languageLoading } = useLookupLanguages(companyId as any);

  const {
    data: templateData,
    isLoading: formLoading,
    isFetching: formFetching
  } = useGetExternalWorkflow(state?.jobOpeningIds);

  const {
    data: locationData,
    isLoading: locationLoading,
    isFetching: locationFetching
  } = useGetCompanyLocation(companyLocationId);

  const locationCount = state?.locationIds?.length - 1;
  const primaryLocation = locationData?.address?.streetAddress;

  const displayAddress = locationCount > 0 ? `${primaryLocation} + ${locationCount} other` : primaryLocation;

  const onSuccess = () => {
    setFile({});
    form.resetFields();
    payload = {};
    formResponses = [];
    navigate(UNAUTHENTICATED_ROUTES.THANKYOU_PAGE, { state: { externalLocationsUrl: state?.externalLocationsUrl } });
  };

  const { mutateAsync: sessionMutate, data: sessionId } = useCandidateSession();

  const { mutateAsync: uploadAttachment, isLoading: resumeUploading } = useUploadAttachment();
  const { mutateAsync: uploadSignature, isLoading: signatureUploading } = useUploadSignature();
  const { mutate: addCandidate, isLoading: addCandidateLoader } = useAddCandidate(onSuccess);

  const formInfo = (!utilService.isEmpty(templateData) && templateData?.forms[formStep - 1]) || [];

  const templateInfo: any = (!utilService.isEmpty(templateData) && JSON.parse(formInfo?.template)) || {};
  const loading: boolean = formLoading || formFetching || locationLoading || locationFetching || languageLoading;

  useEffect(() => {
    if (state?.language) {
      setLanguage(state?.language);
    }
    // if (languageListing) setLanguage(languageListing?.defaultLanguage);
  }, [languageListing, state]);

  useEffect(() => {
    if (companyLocationId) {
      const referer = isQr ? SESSION_REFERER.QR : isSms ? SESSION_REFERER.SMS : document.referrer;
      sessionMutate({
        companyLocationId: +companyLocationId,
        referer: referer,
        userAgent
      });
    }
  }, [companyLocationId]);

  async function getMediaValues(resume: Array<any>, signatureUpload: Array<any>) {
    const uploadsArr = [...resume, ...(signatureUpload ? [...signatureUpload] : [])];
    const promises =
      uploadsArr &&
      uploadsArr.map((data) => {
        let formData = new FormData();
        formData.append("Files", data.originFileObj);
        if (data.type === "application/pdf") {
          return uploadAttachment(formData);
        } else {
          return uploadSignature(formData);
        }
      });

    const mediaValues: any = promises && (await Promise.all(promises));

    return mediaValues;
  }

  function onUploadSuccess(formValues: formResponseType) {
    const fieldValues: any = [];
    const formResponse: any = {};

    const formInfo = { ...templateData?.forms[formStep - 1] };
    const { formTemplateId, order, template, title, ...rest } = formInfo;

    const response = {
      templateTitle: title,
      ...rest
    };

    const updatedSections = templateInfo?.sections?.map((section: sectionType) => {
      Object.keys(section.fields).forEach((fieldKey: string) => {
        const validation = section.fields[fieldKey][FORM_PAYLOAD_CONSTANTS.VALIDATION];

        if (validation && validation === VALIDATION_CONSTANTS.DATE) {
          formValues[fieldKey] = formatDate(formValues[fieldKey]);
        } else if (validation && validation === VALIDATION_CONSTANTS.DATE_RANGE) {
          const formattedAnswer = formValues[fieldKey]?.map((date: any) => formatDate(date));
          formValues[fieldKey] = formattedAnswer?.join(" - ");
        }

        const fieldValue = generateFieldValue(section, fieldKey, formValues);

        fieldValues.push(fieldValue as fieldValueType);

        generatePayload(payload, section, fieldKey, formValues);
      });

      return section;
    });

    if (rest[FORM_PAYLOAD_CONSTANTS.SIGNATURE_TEXT]) {
      const signatureKey = getSignatureKey(formValues);
      response[FORM_PAYLOAD_CONSTANTS.SIGNATURE] = formValues[signatureKey as string];
      response[FORM_PAYLOAD_CONSTANTS.SIGNATURE_TYPE] = formValues[FORM_PAYLOAD_CONSTANTS.SIGNATURE_TYPE];
    }

    response[FORM_PAYLOAD_CONSTANTS.SECTIONS] = updatedSections;
    formResponse[FORM_PAYLOAD_CONSTANTS.FORM_TEMPLATE_ID] = formTemplateId;
    formResponse[FORM_PAYLOAD_CONSTANTS.RESPONSE] = JSON.stringify(response);
    formResponse[FORM_PAYLOAD_CONSTANTS.FIELD_VALUES] = fieldValues;

    formResponses.push(formResponse);

    if (formStep < templateData?.forms?.length) {
      setFormStep((prev) => prev + 1);
    } else {
      Object.assign(payload, {
        companyId: Number(companyId),
        sessionId,
        formResponses,
        mediaIds: mediaIds.length ? mediaIds : null,
        languageId: language?.id,
        jobOpeningIds: state?.jobOpeningIds,
        companyLocationIds: state?.locationIds
      });

      addCandidate(payload);
    }
  }

  async function onFormFinish(formName: string, info: infoType) {
    const { values } = info;

    const { resume, signatureUpload, ...rest } = values;

    const signatureManual = searchKeyWithSubstring(rest, "MANUAL");

    if (signatureManual) {
      const blob = base64ToBlob(rest[signatureManual]);
      let formData = new FormData();
      formData.append("Files", blob, "signature.png");

      const uploadedSignature = await uploadSignature(formData);

      const viewableLink: string = uploadedSignature && uploadedSignature[0]?.viewableLink;

      rest[signatureManual] = viewableLink;
    }

    if (resume && signatureUpload) {
      const mediaValues: any = await getMediaValues(resume, signatureUpload);

      const signatureKey = searchKeyWithSubstring(rest, "UPLOAD") as string;

      mediaIds.push(mediaValues[0][0]?.id);
      const viewableLink = mediaValues[1] && mediaValues[1][0]?.viewableLink;

      rest[signatureKey] = viewableLink;
    } else if (!resume && signatureUpload) {
      let formData = new FormData();
      formData.append("Files", signatureUpload[0]?.originFileObj);

      const uploadedSignature = await uploadSignature(formData);

      const signatureKey = searchKeyWithSubstring(rest, "UPLOAD") as string;

      const viewableLink: string = uploadedSignature && uploadedSignature[0]?.viewableLink;

      rest[signatureKey] = viewableLink;
    } else if (resume && !signatureUpload) {
      let formData = new FormData();
      formData.append("Files", resume[0]?.originFileObj);

      const uploadedAttachment = await uploadAttachment(formData);
      mediaIds.push(uploadedAttachment[0]?.id);
    }

    onUploadSuccess(rest);
    form.resetFields();
  }

  return {
    file,
    setFile,
    formStep,
    setFormStep,
    language,
    setLanguage,
    form,
    languageListing,
    languageLoading,
    templateData,
    formLoading,
    formFetching,
    locationData,
    locationLoading,
    locationFetching,
    displayAddress,
    loading,
    templateInfo,
    formInfo,
    addCandidateLoader,
    onFormFinish,
    workflowId,
    resumeUploading,
    signatureUploading
  };
};
