import { sidebarIcons } from "../containers/Sidebar/sidebarIcons";
import { PermissionConstants } from "./permissionConstants";

export const UNAUTHENTICATED_ROUTES = {
  LOGIN: "/login",
  SIGN_UP: "/sign-up",
  SET_PASSWORD: "/set-password",
  RESET_PASSWORD: "/reset-password",
  FORGOT_PASSWORD: "/forgot-password",
  THANKYOU_PAGE: "/success"
};

export const AUTHENTICATED_ROUTES = {
  DASHBOARD: "/dashboard",
  JOB_OPENING: "/job-openings",
  UPDATE_JOB_OPENING: "/job-opening/:id",
  LOCATIONS: "/locations",
  SUBSIDIARIES: "/subsidiaries",
  DEPARTMENTS: "/departments",
  HIRING_TEAM: "/hiring-team",
  SETTINGS: "/change-logo",
  FORMS: "/forms",
  ADD_FORM_TEMPLATES: "/forms/new",
  EDIT_FORM_TEMPLATES: "/forms/:id",
  VIEW_FORM_TEMPLATES: "/forms/view/:id",

  HELP: "/help",
  CREATE_JOB: "/create-job-opening",
  JOB_CREATION_SUCCESS: "/job-creation-success",
  JOB_DETAILS: "/job-openings/job-details",
  JOB_CANDIDATE_DETAILS: "/job-openings/job-candidate-details",
  JOB_LOCATION_DETAILS: "/locations/details/:id",
  PROFILE: "/profile",
  CHANGE_PASSWORD: "/change-password",
  EDIT_PROFILE: "/edit-profile",
  EXTERNAL_FORM: "/external-form",
  APPLICATION_REPORT: "/application-report",
  APPLICANT_HIRED_REPORT: "/applicant-hired-report",
  RECRUITING_ACTIVITY_REPORT: "/recruiting-activity-report",
  USER_LOCATIONS_REPORT: "/user-locations-report",
  EEO_REPORT_1: "/eeo-report-i",
  EEO_REPORT_2: "/eeo-report-ii",
  APPLICANT_SOURCES_REPORT: "/applicant-sources-report",
  LOGINS_REPORT: "/logins-report",
  APPLICANT_FIELDS_REPORT: "/applicant-fields-report",
  LOCATION_STATS_REPORT: "/location-stats-report",

  EXTERNAL_JOB_LOCATION: "/external-job-location",
  LOCALIZATION: "/languages",
  ADD_LOCALIZATION: "/languages/labels",
  WORKFLOWS: "/workflows",
  ADD_WORKFLOW: "/workflows/new",
  EDIT_WORKFLOW: "/workflows/:id",
  FORM_PREVIEW: "/form-preview"
};

export const SIDE_BAR_OPTIONS = {
  [PermissionConstants.VIEW_REQUISITION_HISTORY]: {
    name: "Job Openings",
    link: AUTHENTICATED_ROUTES.JOB_OPENING,
    selectedKey: "job-openings",
    icon: sidebarIcons.JobOpeningIcon
  },
  [PermissionConstants.MANAGE_LOCATIONS]: {
    name: "Locations",
    link: AUTHENTICATED_ROUTES.LOCATIONS,
    selectedKey: "locations",
    icon: sidebarIcons.LocationIcon
  },

  [PermissionConstants.MANAGE_DEPARTMENTS]: {
    name: "Departments",
    link: AUTHENTICATED_ROUTES.DEPARTMENTS,
    selectedKey: "departments",
    icon: sidebarIcons.DepartmentIcon
  },
  [PermissionConstants.MANAGE_HIRING_TEAM]: {
    name: "Hiring Team",
    link: AUTHENTICATED_ROUTES.HIRING_TEAM,
    selectedKey: "hiring-team",
    icon: sidebarIcons.HiringTeamIcon
  },
  [PermissionConstants.CREATE_JOB_PAGES]: {
    name: "Workflows",
    link: AUTHENTICATED_ROUTES.WORKFLOWS,
    selectedKey: "workflows",
    icon: sidebarIcons.WorkflowIcon
  },
  [PermissionConstants.MANAGE_FORMS]: {
    name: "Forms",
    link: AUTHENTICATED_ROUTES.FORMS,
    selectedKey: "forms",
    icon: sidebarIcons.FormIcon
  },
  [PermissionConstants.EDIT_CUSTOMER]: {
    name: "Change Logo",
    link: AUTHENTICATED_ROUTES.SETTINGS,
    selectedKey: "changeLogo",
    icon: sidebarIcons.LogoIcon
  },
  [PermissionConstants.MANAGE_LANGUAGE]: {
    name: "Languages",
    link: AUTHENTICATED_ROUTES.LOCALIZATION,
    selectedKey: "languages",
    icon: sidebarIcons.localization
  },

  [PermissionConstants.REPORT_VIEW]: {
    name: "Reports",
    link: AUTHENTICATED_ROUTES.SETTINGS,
    selectedKey: "reports",
    icon: sidebarIcons.DepartmentIcon,
    subMenu: [
      {
        name: "Application Report",
        link: AUTHENTICATED_ROUTES.APPLICATION_REPORT,
        selectedKey: "application-report"
      },
      {
        name: "Applicant Hired Report",
        link: AUTHENTICATED_ROUTES.APPLICANT_HIRED_REPORT,
        selectedKey: "applicant-hired-report"
      },
      {
        name: "Recruiting Activity Report",
        link: AUTHENTICATED_ROUTES.RECRUITING_ACTIVITY_REPORT,
        selectedKey: "recruiting-activity-report"
      },
      {
        name: "User Locations Report",
        link: AUTHENTICATED_ROUTES.USER_LOCATIONS_REPORT,
        selectedKey: "user-locations-report"
      },
      {
        name: "EEO Report 1",
        link: AUTHENTICATED_ROUTES.EEO_REPORT_1,
        selectedKey: "eeo-report-i"
      },
      {
        name: "EEO Report 2",
        link: AUTHENTICATED_ROUTES.EEO_REPORT_2,
        selectedKey: "eeo-report-ii"
      },
      {
        name: "Applicant Sources Report",
        link: AUTHENTICATED_ROUTES.APPLICANT_SOURCES_REPORT,
        selectedKey: "applicant-sources-report"
      },
      {
        name: "Logins Report",
        link: AUTHENTICATED_ROUTES.LOGINS_REPORT,
        selectedKey: "logins-report"
      },
      {
        name: "Applicant Fields Report",
        link: AUTHENTICATED_ROUTES.APPLICANT_FIELDS_REPORT,
        selectedKey: "applicant-fields-report"
      },
      {
        name: "Location Stats Report",
        link: AUTHENTICATED_ROUTES.LOCATION_STATS_REPORT,
        selectedKey: "location-stats-report"
      }
    ]
  }
};
