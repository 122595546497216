import { Button, Card, Form } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useSetPassword } from "./authQueries";
import { VALIDATE } from "../../../constants/constant";
import AuthWrapper from "../../../layouts/AuthWrapper";
import CustomPassword from "../../../components/CustomPassword";

import styles from "./Auth.module.scss";
import notificationService from "../../../util/notification.service";
import { PatternFormat } from "react-number-format";
import FloatInput from "../../../components/FloatInput";
import { setPasswordPayload } from "../../../types";
import { UNAUTHENTICATED_ROUTES } from "../../../constants/route-constant";

const SetPassword = () => {
  const [form] = Form.useForm();
  let [searchParams] = useSearchParams();
  const accountId = searchParams.get("userId");
  const navigate = useNavigate();

  const onSuccess = () => {
    navigate(UNAUTHENTICATED_ROUTES.LOGIN);
  };
  const { mutate: setPassword, isLoading } = useSetPassword(onSuccess);

  const onFinish = (value: setPasswordPayload) => {
    if (accountId) {
      setPassword({
        accountId: accountId,
        phoneNumber: value.phoneNumber,
        password: value.password
      });
    } else {
      notificationService.error("Account Id do not exist");
    }
  };

  return (
    <AuthWrapper>
      <Card className={`${styles["card-container"]}`}>
        <div className={`${styles["reset-container"]}`}>
          <h1 className={`hr-mb-40 ${styles["heading-input"]}`}>Set Password</h1>
          <Form form={form} onFinish={onFinish}>
            <Form.Item
              name="phoneNumber"
              rules={[
                ...VALIDATE.NUMBER,
                () => ({
                  validator(_, value) {
                    if (!value || value === value.split("_")[0]) {
                      return Promise.resolve();
                    }
                    return Promise.reject("Invalid Phone Number");
                  }
                })
              ]}
            >
              <PatternFormat
                format="+1 (###) ###-####"
                mask="_"
                type="tel"
                label="Phone Number"
                customInput={FloatInput}
              />
            </Form.Item>
            <Form.Item name="password" rules={[...VALIDATE.PASSWORD, ...VALIDATE.PASSWORD_PATTERN]}>
              <CustomPassword label="Password" />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              required={false}
              rules={[
                ...VALIDATE.CONFIRM_PASSWORD,
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject("Passwords do not match");
                  }
                })
              ]}
            >
              <CustomPassword label="Confirm Password" />
            </Form.Item>

            <Button
              className={`${styles["btn-auth"]}`}
              type="primary"
              htmlType="submit"
              loading={isLoading}
              disabled={isLoading}
            >
              Set Password
            </Button>
          </Form>
        </div>
      </Card>
    </AuthWrapper>
  );
};

export default SetPassword;
