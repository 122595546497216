import React from "react";
import { Select } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import "./index.scss"
import FloatLabel from "../FloatLabel";
import { ReactComponent as ArrowDown } from "../../assets/icons/ArrowDown.svg";

type optionTypes = {
  label: string;
  value: string;
};

type FloatSelectParams = {
  label: string;
  type?: string;
  options: optionTypes[];
  value?: string | number | number[];
  required?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  allowClear?: boolean;
  [key: string]: any;
};

const FloatSelect: React.FC<FloatSelectParams> = ({
  label,
  type,
  value,
  required,
  options,
  allowClear = false,
  isLoading = false,
  ...rest
}) => {
  const inputRef = React.useRef<any>();
  const handleInputFocus = () => {
    inputRef.current.focus();
  };
  return (
    <FloatLabel
      label={label}
      value={value}
      required={required}
      onClick={handleInputFocus}
      className={rest?.disabled ? "disabled-select" : ""}
    >
      <Select
        ref={inputRef}
        {...rest}
        options={options}
        value={value}
        suffixIcon={isLoading ? <LoadingOutlined /> : <ArrowDown />}
        loading={isLoading}
        allowClear={allowClear}
        getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
      />
    </FloatLabel>
  );
};

export default FloatSelect;
