import { CHILD_TYPE } from "../../../../../constants/constant";
import { sectionType } from "../../../../../types";
import { getLabelsFromAnswer, removeSpacesDashesBrackets } from "../../../../../util/utils.service";
import { formResponseType } from "../ExternalFormContainer";
import { FIELD_LABEL_CONSTANTS, FORM_PAYLOAD_CONSTANTS } from "./constants";

export const generatePayload = (payload: any, section: sectionType, fieldKey: string, formValues: formResponseType) => {
  switch (section.fields[fieldKey]["label"].toLowerCase()) {
    case FIELD_LABEL_CONSTANTS.EMAIL:
    case FIELD_LABEL_CONSTANTS.EMAIL_ADDRESS:
      payload[FORM_PAYLOAD_CONSTANTS.EMAIL] = formValues[fieldKey];
      break;

    case FIELD_LABEL_CONSTANTS.NAME:
      payload[FORM_PAYLOAD_CONSTANTS.FULLNAME] = formValues[fieldKey];
      break;
    case FIELD_LABEL_CONSTANTS.FIRST_NAME:
      payload[FORM_PAYLOAD_CONSTANTS.FULLNAME] = formValues[fieldKey];
      break;
    case FIELD_LABEL_CONSTANTS.LAST_NAME:
      payload[FORM_PAYLOAD_CONSTANTS.FULLNAME] = payload[FORM_PAYLOAD_CONSTANTS.FULLNAME]?.concat(
        " ",
        formValues[fieldKey]
      );
      break;
    case FIELD_LABEL_CONSTANTS.FULL_NAME:
      payload[FORM_PAYLOAD_CONSTANTS.FULLNAME] = formValues[fieldKey];
      break;

    case FIELD_LABEL_CONSTANTS.PHONE:
    case FIELD_LABEL_CONSTANTS.PHONE_NUMBER:
    case FIELD_LABEL_CONSTANTS.CONTACT:
    case FIELD_LABEL_CONSTANTS.CONTACT_NUMBER:
    case FIELD_LABEL_CONSTANTS.MOBILE_TELEPHONE:
    case FIELD_LABEL_CONSTANTS.HOME_TELEPHONE:
    case FIELD_LABEL_CONSTANTS.CELL_PHONE:
      payload[FORM_PAYLOAD_CONSTANTS.PHONE_NUMBER] = removeSpacesDashesBrackets(formValues[fieldKey]);
      break;
  }
  section.fields[fieldKey]["answer"] = Array.isArray(formValues[fieldKey])
    ? formValues[fieldKey]
    : formValues[fieldKey]
    ? [formValues[fieldKey]]
    : [];

  return section;
};

export const generateFieldValue = (section: sectionType, fieldKey: string, formValues: formResponseType) => {
  const fieldValue = {
    type: section.fields[fieldKey]["type"],
    fieldIdentifier: section.fields[fieldKey]["id"],
    label: section.fields[fieldKey]["label"],
    answers: CHILD_TYPE.includes(section.fields[fieldKey]["type"])
      ? getLabelsFromAnswer(
          section.fields[fieldKey]["options"],
          Array.isArray(formValues[fieldKey]) ? formValues[fieldKey] : [formValues[fieldKey]]
        )
      : formValues[fieldKey]
      ? [formValues[fieldKey]]
      : []
  };

  return fieldValue;
};
