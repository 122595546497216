import { AxiosRequestConfig } from "axios";
import { CancelToken, create } from "apisauce";

import { utilService } from "./utils.service";
import { queryClient } from "../apiServices/client";
import { STORAGE_CONST } from "../constants/constant";
import notificationService from "./notification.service";

export let requestCancelOngoing = CancelToken.source();

type UserInfo = {
  token: string;
};

const apiService = {
  apiSauceInstance: create({
    baseURL: utilService.apiUrl
  }),
  post,
  get,
  put,
  patch,
  remove,
  handleResponse: handleResponse
};

const apiServiceUnAuthorized = {
  apiSauceInstance: create({
    baseURL: utilService.apiUrl
  }),
  getUnAuthorized,
  postUnAuthorized,
  handleResponse: handleResponse
};

const LOGIN_URL = "/login";

async function get(url: string, queryParams?: any, axiosConfig?: AxiosRequestConfig) {
  const response = await apiService.apiSauceInstance.get(url, queryParams, axiosConfig);
  return apiService.handleResponse(response);
}

async function getUnAuthorized(url: string, queryParams: any) {
  const response = await apiServiceUnAuthorized.apiSauceInstance.get(url, queryParams);
  return apiService.handleResponse(response);
}

async function postUnAuthorized(url: string, queryParams: any) {
  const response = await apiServiceUnAuthorized.apiSauceInstance.post(url, queryParams);
  return apiService.handleResponse(response);
}

async function post(url: string, data?: any, config?: AxiosRequestConfig) {
  const response = await apiService.apiSauceInstance.post(url, data, config);
  return apiService.handleResponse(response);
}

async function put(url: string, data?: any, config?: any) {
  const response = await apiService.apiSauceInstance.put(url, data, config);
  return apiService.handleResponse(response);
}

async function patch(url: string, data: any, config?: AxiosRequestConfig) {
  const response = await apiService.apiSauceInstance.patch(url, data, config);
  return apiService.handleResponse(response);
}

async function remove(url: string, data?: any, config?: AxiosRequestConfig) {
  const response = await apiService.apiSauceInstance.delete(url, data, config);
  return apiService.handleResponse(response);
}

function handleResponse(response: any) {
  const mutatedResponse = {
    ok: response.ok,
    status: response.status,
    header: response.headers,
    response: {
      code: response.status,
      message: response.data?.response?.message,
      errorCode: response.data?.errorCode,
      pages: response?.data?.pages
    }
  };

  const data = response.data?.data ?? response?.data;

  if (response.status === 401) {
    notificationService.error("You are not authorized to perform this action");
    utilService.redirectToLogin(LOGIN_URL);
    return { ...mutatedResponse, data: !utilService.isEmpty(data) ? data : null };
  }
  if (response.status === 500) {
    return { ...mutatedResponse, data: !utilService.isEmpty(data) ? data : null };
  }
  if (response.ok) {
    return { ...mutatedResponse, data };
  } else {
    return { ...mutatedResponse, data: !utilService.isEmpty(data) ? data : null };
  }
}

apiService.apiSauceInstance.addRequestTransform((request: AxiosRequestConfig) => {
  const token = queryClient.getQueryData<UserInfo>([STORAGE_CONST.TOKEN]);

  request.headers = request.headers ?? {};
  request.headers["Authorization"] = `Bearer ${token}`;

  if (Boolean(requestCancelOngoing.token.reason)) requestCancelOngoing = CancelToken.source();
  request.cancelToken = requestCancelOngoing.token;
});

const ApiService = {
  post: apiService.post,
  get: apiService.get,
  patch: apiService.patch,
  put: apiService.put,
  remove: apiService.remove,
  getUnAuthorized: apiServiceUnAuthorized.getUnAuthorized,
  postUnAuthorized: apiServiceUnAuthorized.postUnAuthorized
};

export default ApiService;
