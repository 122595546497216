import dayjs from "dayjs";
import * as FileSaver from "file-saver";
import { UploadRequestOption as RcCustomRequestOptions } from "rc-upload/lib/interface";
import { v4 as uuid } from "uuid";

import { queryClient } from "../apiServices/client";
import {
  PAGINATION_CONST,
  STORAGE_CONST,
  supportedFileFormat,
  wordFormats,
  CANDIDATE_STATUS,
  DISPOSITION_TYPE
} from "../constants/constant";
import { addressType, editPdfState, pdfStateType } from "../types";
import notificationService from "./notification.service";

type lastPageParams = {
  totalRecords: number;
};
type allPageParams = {
  length: number;
};

export const utilService = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  apiUrl: process.env.REACT_APP_API_URL,
  get,
  isEmpty,
  redirectTo,
  formatNumber,
  getAvatarText,
  getDateDuration,
  redirectToLogin,
  createDynamicUrl,
  getNextPageParam,
  fromState,
  toState,
  fileMaxSize,
  getValueFromEvent,
  textFieldModify
};

function getNextPageParam(lastPage: lastPageParams, allPages: allPageParams) {
  return lastPage?.totalRecords / PAGINATION_CONST.PAGE_SIZE > allPages?.length ? allPages.length : undefined;
}

function formatNumber(value: number) {
  return value.toLocaleString("en-US");
}

// function get(object: object, path: string | Array<string>, defaultValue: number | string) {
function get(object: any, path: string | any, defaultValue: any) {
  const paths = Array.isArray(path) ? path : path.split(".");

  let index = 0;
  let len = paths.length;
  let obj: any = object; // ** FIX THIS

  while (obj != null && index < len) {
    obj = obj[String(paths[index])];
    index++;
  }

  const value = index && index === len ? obj : undefined;

  return value !== undefined ? value : defaultValue;
}

function isEmpty(value: string | number | boolean | Array<any> | object): boolean {
  return (
    value === undefined ||
    value === null ||
    value === false ||
    value === 0 ||
    Number.isNaN(value) ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" && value.trim().length === 0)
  );
}
function redirectToLogin(loginUrl: string) {
  queryClient.setQueryData([STORAGE_CONST.TOKEN], null);
  queryClient.setQueryData([STORAGE_CONST.USER_INFO], null);
  const returnUrl = encodeURIComponent(window.location.pathname.replace(/[//]+/, "") + window.location.search);
  const url = loginUrl + "?returnUrl=" + returnUrl;
  setTimeout(() => {
    utilService.redirectTo(url);
  }, 1000);
}

function redirectTo(url: string) {
  window.location.href = utilService.baseUrl + url;
}

export function generateUniqueId() {
  const uniqueId = uuid();
  return uniqueId;
}

function getAvatarText(name: string) {
  if (name == null || name === undefined) {
    return "";
  }
  let avatar = name.split(" ");
  return `${avatar[0]?.trim()[0]?.toUpperCase() ?? ""}${avatar[1]?.trim()[0]?.toUpperCase() ?? ""}`;
}

function createDynamicUrl(dynamicUrl: string, object: any) {
  for (const key in object) {
    if (!isEmpty(object[key]) && typeof object[key] === "object") {
      for (const childKey in object[key]) {
        dynamicUrl = dynamicUrl.replace(`{${childKey}}`, object[key][childKey]);
      }
    } else {
      dynamicUrl = dynamicUrl.replace(`{${key}}`, object[key] ?? "");
    }
  }
  return dynamicUrl;
}

export const checkValidation = (data: any) => {
  const { value, pattern, emptyMessage, patternMessage, checkEmpty = true } = data;
  let error = "";
  if (checkEmpty && isEmpty(value)) {
    error = emptyMessage;
  } else if (value && !pattern.test(value)) {
    error = patternMessage;
  }
  return error;
};

function getDateDuration(date: string) {
  const startDate = dayjs(date);
  const endDate = dayjs();

  const diffMonths = endDate.diff(startDate, "month");
  const endDateMinusMonths = endDate.subtract(diffMonths, "month");
  const diffDays = endDateMinusMonths.diff(startDate, "day");

  const months = diffMonths;
  const weeks = Math.floor(diffDays / 7);
  const days = diffDays - weeks * 7;
  const hours = endDate.diff(startDate, "hour") % 24;

  let result = "";

  if (months > 0) {
    result += `${months}m `;
  }

  if (weeks > 0) {
    result += `${weeks}w `;
  }

  if (days > 0) {
    result += `${days}d `;
  }

  if (hours > 0) {
    result += `${hours}h`;
  }

  return result;
}
export const dummyRequest = (options: RcCustomRequestOptions<any>) => {
  const { file, onSuccess } = options;
  setTimeout(() => {
    if (onSuccess) {
      onSuccess("ok");
    }
  }, 0);
};

export function getRouteWithId(route: string, id: any) {
  return route.replace(":id", id);
}

export function getAddress(address: addressType, showStreetAddress: boolean = true) {
  if (!address) return "";
  const { streetAddress, city, state, zipCode } = address ?? {};
  const cityState = city && state ? `${city}, ${state}` : city || state || "";
  return `${showStreetAddress ? `${streetAddress},` : ""} ${cityState} ${zipCode || ""}`;
}

const fileExtensions = {
  excel: {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    extension: ".xlsx"
  },
  csv: {
    type: "text/csv",
    extension: ".csv"
  },
  pdf: {
    type: "application/pdf",
    extension: ".pdf"
  }
};
export function exportToFile(data: any, fileName: string, type: string) {
  var fileMeta = fileExtensions["csv"];
  const response = new Blob([data], { type: fileMeta.type });
  FileSaver.saveAs(response, fileName + fileMeta.extension);
}

function fromState(state: pdfStateType): editPdfState[] {
  if (!state["1"]) return [];
  const arr: any = [];
  for (const page in state) {
    for (const id in state[page]) {
      arr.push({ ...state[page][id], identifier: id, pageNumber: +page });
    }
  }
  return arr;
}

function toState(state: editPdfState[]) {
  if (!state?.length) return {};
  const obj: pdfStateType = {};
  for (const item of state) {
    obj[item.pageNumber || "0"] = { ...obj[item.pageNumber || "0"], [item.identifier || "0"]: { ...item } };
  }
  return obj;
}

function fileMaxSize(file: any, max: number) {
  if (![...supportedFileFormat, ...wordFormats].includes(file.type)) {
    return Promise.reject();
  } else {
    const fileSizeLimit = file.size / 1024 / 1024 < max;
    if (!fileSizeLimit) {
      notificationService.error(`File must be smaller than ${max}MB!`);
      return Promise.reject();
    }
  }
}

function getValueFromEvent(e: any) {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
}
export function disabledHours() {
  const disabledHours = [];
  for (let i = 0; i < 24; i++) {
    // Disable hours that are not between 7 AM (7) and 8 PM (20)
    if (i < 7 || i > 20) {
      disabledHours.push(i);
    }
  }
  return disabledHours;
}

function textFieldModify(text: string | undefined, phone: string | undefined) {
  let updateText: any = text;
  if (phone) {
    updateText = updateText?.replaceAll("[PHONE]", phone);
  }
  updateText = updateText?.replaceAll("[BR]", "\n");
  return updateText;
}

export function allAreTrue(arr: any) {
  return arr?.every((item: any) => item);
}

export function isAnyTrue(arr: any) {
  return arr?.some((item: any) => item);
}

export function getLabelsFromAnswer(options: any, answer: string[]) {
  const labels: any = [];

  answer?.forEach((selectedValue: string) => {
    const selectedOption = options.find((option: any) => option.value === selectedValue);
    if (selectedOption) {
      labels.push(selectedOption.label);
    }
  });

  return labels;
}

export function searchKeyWithSubstring(obj: any, substring: string) {
  for (let key in obj) {
    if (key.includes(substring)) {
      return key;
    }
  }
  return null;
}

export function getSignatureKey(obj: any) {
  const keysArr = Object.keys(obj);
  const keywords = ["UPLOAD", "BASIC", "MANUAL"];

  const signatureKey = keysArr.find((key) => keywords.some((keyword) => key.includes(keyword)));

  return signatureKey;
}

export function capitalizedString(inputString: string) {
  const words = inputString.split("_");
  const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
  return capitalizedWords.join("");
}

export function getLabelTranslation(defaultLabel: string, arr: any, translation: any) {
  const labelKey = arr.join("_");

  return translation ? translation[labelKey] || defaultLabel : defaultLabel;
}

export function removeProperties(obj: { [key: string]: any }, propertiesToRemove?: string[]) {
  const updatedPayload = { ...obj };
  for (const value in obj) {
    if (propertiesToRemove?.includes(value)) {
      delete updatedPayload[value];
    } else if (
      updatedPayload[value] === null ||
      updatedPayload[value] === "" ||
      (Array.isArray(updatedPayload[value]) && updatedPayload[value].length === 0)
    ) {
      delete updatedPayload[value];
    } else if (typeof updatedPayload[value] === "object") {
      removeProperties(updatedPayload[value], propertiesToRemove); // Recursively check nested objects
    }
  }
  return updatedPayload;
}

export function addQueryParameters(url: string, payload: any) {
  const queryParams: any = [];
  for (const key in payload) {
    if (payload.hasOwnProperty(key)) {
      queryParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(payload[key])}`);
    }
  }

  const queryString = queryParams.join("&");
  if (queryString) {
    return `${url}?${queryString}`;
  } else {
    return url;
  }
}

export function base64ToBlob(base64String: string) {
  const byteCharacters = atob(base64String.split(",")[1]);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: "image/png" });
}

export function getDateWithoutTime(dt: any) {
  dt.setHours(0, 0, 0, 0);
  return dt;
}

export function constructExternalFormUrl(url: string, jobIds: Array<number>) {
  let queryString = "";

  jobIds?.forEach((id: number, index: number) => {
    if (index === 0) {
      queryString += `jobOpeningIds=${id}`;
    } else {
      queryString += `&jobOpeningIds=${id}`;
    }
  });

  return `${url}?${queryString}`;
}

export function formatLargerCount(value: string | number) {
  const numericValue = typeof value === "string" ? parseFloat(value) : value;

  if (numericValue >= 1000000) {
    return (numericValue / 1000000).toFixed(1) + "M";
  } else if (numericValue >= 1000) {
    return (numericValue / 1000).toFixed(1) + "k";
  } else {
    return numericValue?.toString();
  }
}

export function removeSpacesDashesBrackets(inputString: string) {
  // Remove spaces, dashes, and brackets using regular expressions
  return inputString.replace(/[ \-()\[\]{}]/g, "");
}
