import { API_CONFIG_URLS } from "../constants/config";
import { addWorkflowPayloadType, listingPayloadType } from "../types";
import ApiService from "../util/api.service";
import { addQueryParameters, constructExternalFormUrl, utilService } from "../util/utils.service";

const WorkflowApiService = {
  getListing,
  addWorkflow,
  deleteWorkflow,
  editWorkflow,
  getExternalWorkflow
};

async function getListing(payload: listingPayloadType) {
  const url = utilService.createDynamicUrl(API_CONFIG_URLS.WORKFLOW.LISTING, payload);
  const response = await ApiService.get(url);
  return response;
}

async function addWorkflow(payload: addWorkflowPayloadType) {
  const response = await ApiService.post(API_CONFIG_URLS.WORKFLOW.ADD_WORKFLOW, payload);
  return response;
}

async function deleteWorkflow(payload: { id: number }) {
  const url = utilService.createDynamicUrl(API_CONFIG_URLS.WORKFLOW.DELETE_WORKFLOW, payload);
  const response = await ApiService.remove(url);
  return response;
}

async function editWorkflow(payload: addWorkflowPayloadType, id: number) {
  const url = utilService.createDynamicUrl(API_CONFIG_URLS.WORKFLOW.EDIT_WORKFLOW, { id });
  const response = await ApiService.put(url, payload);
  return response;
}

async function getExternalWorkflow(payload: any) {
  const url = constructExternalFormUrl(API_CONFIG_URLS.WORKFLOW.EXTERNAL_WORKFLOW, payload);
  const response = await ApiService.get(url);
  return response;
}

export default WorkflowApiService;
