import React, { useContext } from "react";
import { WarningOutlined } from "@ant-design/icons";

import CustomModal from "../../components/Modal";
import { CustomButton } from "../../components/Button";
import { queryClient } from "../../apiServices/client";
import { AuthContext } from "../../context/AuthContext";
import { STORAGE_CONST } from "../../constants/constant";
import { useLogout } from "../Pages/AuthPages/authQueries";

import "./index.scss";

interface modalProps {
  visible: boolean;
  onSuccess: () => void;
  onCancel: () => void;
}

const LogoutModal: React.FC<modalProps> = ({ visible, onCancel, onSuccess }) => {
  const [, dispatch] = useContext(AuthContext);
  const logoutSuccess = () => {
    onCancel();
    dispatch({ type: "LOGOUT" });
    queryClient.setQueryData([STORAGE_CONST.TOKEN], null);
    queryClient.setQueryData([STORAGE_CONST.USER_INFO], null);
  };
  const { mutate: logout, isLoading: isLoggingOut } = useLogout(logoutSuccess);

  return (
    <CustomModal
      visible={visible}
      title="Confirmation"
      className="logout-modal"
      onCancel={onCancel}
      closable={false}
      footer={[
        <CustomButton title="Cancel" btnColor="white" onClick={onCancel} className="noirProMedium" />,
        <CustomButton
          isLoading={isLoggingOut}
          disabled={isLoggingOut}
          onClick={logout as any}
          type="primary"
          className="confirm-button noirProMedium"
          title="Logout"
          btnColor="blue"
  
        />
      ]}
    >
      <div
        style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}
        className="modal-content"
      >
        <WarningOutlined className="warning-icon" />
        <p className="noirProRegular gx-fs-md hr-mt-10 hr-mb-20">Are you sure you want to logout the application?</p>
      </div>
    </CustomModal>
  );
};

export default LogoutModal;
