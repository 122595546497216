import React from "react";
import { useQuery } from "@tanstack/react-query";

import FullPageLoader from "../../../components/FullPageLoader";
import { userInfoType } from "../../../types";
import { STORAGE_CONST } from "../../../constants/constant";
import { useUserDetail, useUserPermissions } from "../AuthPages/authQueries";

const AuthenticatedAppRoutes = React.lazy(() => import("../../../routes/AuthenticatedRoutes"));

const MainApp = () => {
  const { data: userInfo }: { data?: userInfoType } = useQuery([STORAGE_CONST.USER_INFO], () => {}, { enabled: false });
  const { isLoading: userDetailsLoading } = useUserDetail();
  const { isLoading: userPermissionsLoading } = useUserPermissions();

  return userInfo === null || userDetailsLoading || userPermissionsLoading ? (
    <FullPageLoader />
  ) : (
    <AuthenticatedAppRoutes />
  );
};

export default MainApp;
