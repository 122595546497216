import React from "react";
import { Card, Form } from "antd";
import { useNavigate } from "react-router-dom";

import { useLogin } from "./authQueries";
import { loginPayload } from "../../../types";
import AuthWrapper from "../../../layouts/AuthWrapper";
import { VALIDATE } from "../../../constants/constant";
import FloatInput from "../../../components/FloatInput";
import { CustomButton } from "../../../components/Button";
import CustomPassword from "../../../components/CustomPassword";
import { UNAUTHENTICATED_ROUTES } from "../../../constants/route-constant";

import styles from "./Auth.module.scss";

const Login: React.FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const { mutate: login, isLoading: loggingIn } = useLogin();

  const onFinish = (data: loginPayload) => {
    login(data);
  };

  return (
    <AuthWrapper>
      <Card className={`${styles["card-container"]}`}>
        <div className={`${styles["login-container"]}`}>
          <h1 className={`hr-mb-40 ${styles["heading-input"]}`}>Login</h1>

          <Form form={form} onFinish={onFinish} initialValues={{ isRemember: false, email: null, password: null }}>
            <Form.Item name="email" rules={VALIDATE.EMAIL as never}>
              <FloatInput label="Email Address" />
            </Form.Item>

            <Form.Item name="password" className={`${styles.password}`} rules={VALIDATE.PASSWORD}>
              <CustomPassword label="Password" />

              {/* do not remove */}
              {/* <FloatLabel label="Password" value={form.getFieldValue("Password")}>
                <Input.Password
                  value={form.getFieldValue("Password")}
                  iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                />
              </FloatLabel> */}
            </Form.Item>

            <p
              className={`${styles.remember} justify-end hr-mb-30`}
              onClick={() => navigate(UNAUTHENTICATED_ROUTES.FORGOT_PASSWORD)}
            >
              Forgot Password?
            </p>

            <CustomButton
              className={`${styles["btn-auth"]}`}
              type="primary"
              htmlType="submit"
              isLoading={loggingIn}
              title="Login"
            />

            <p className={`${styles.para}`}>
              {`${"Don't have an account?"} `}
              <span
                className={`${styles.primary}`}
                onClick={() => {
                  navigate(UNAUTHENTICATED_ROUTES.SIGN_UP);
                }}
              >
                Sign up
              </span>
            </p>
          </Form>
        </div>
      </Card>
    </AuthWrapper>
  );
};

export default Login;
