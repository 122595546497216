import React from "react";
import { Col, Image, Row } from "antd";
import Logo from "../assets/images/hrv-logo.png";

import styles from "./AuthWrapper.module.scss";

type Auth = {
  children: React.ReactElement;
};

const AuthWrapper: React.FC<Auth> = ({ children }) => {
  return (
    <div className={`${styles["wrapper-container"]}`}>
      <Row className={`${styles["row-container"]}`} justify="space-between">
        <Col xs={24} sm={24} md={10} lg={8} xl={8} xxl={8}>
          <Image preview={false} src={Logo} alt="app logo" />
          <h4 className={`${styles.stream}`}>
            Streamlining the <br />
            <span>Mobile Recruiting Process</span>
          </h4>
          <p className={`${styles["para-wrap"]}`}>
            Let your Candidates apply easily and quickly on their mobile phones.
          </p>
        </Col>

        <Col xs={24} sm={24} md={13} lg={14} xl={9} xxl={8} className={`${styles["right-section"]}`}>
          <Row justify="end" className={`${styles["h-100"]}`}>
            <Col sm={24} lg={24} xl={24} xxl={24} className="self-center">
              {children}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default AuthWrapper;
