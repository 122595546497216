import { ReactComponent as DashboardIcon } from "../../assets/icons/sidebarIcons/DashboardIcon.svg";
import { ReactComponent as JobOpeningIcon } from "../../assets/icons/sidebarIcons/JobOpeningIcon.svg";
import { ReactComponent as LocationIcon } from "../../assets/icons/sidebarIcons/LocationIcon.svg";
import { ReactComponent as SubsidiariesIcon } from "../../assets/icons/sidebarIcons/SubsidiariesIcon.svg";
import { ReactComponent as DepartmentIcon } from "../../assets/icons/sidebarIcons/DepartmentIcon.svg";
import { ReactComponent as HiringTeamIcon } from "../../assets/icons/sidebarIcons/HiringTeamIcon.svg";
import { ReactComponent as SettingsIcon } from "../../assets/icons/sidebarIcons/SettingsIcon.svg";
import { ReactComponent as FormIcon } from "../../assets/icons/sidebarIcons/FormIcon.svg";
import { ReactComponent as HelpIcon } from "../../assets/icons/sidebarIcons/HelpIcon.svg";
import { ReactComponent as localization } from "../../assets/icons/sidebarIcons/localization.svg";
import { ReactComponent as WorkflowIcon } from "../../assets/icons/sidebarIcons/WorkflowIcon.svg";
import { ReactComponent as LogoIcon } from "../../assets/icons/sidebarIcons/LogoIcon.svg";

export const sidebarIcons = {
  DashboardIcon,
  JobOpeningIcon,
  LocationIcon,
  SubsidiariesIcon,
  DepartmentIcon,
  HiringTeamIcon,
  SettingsIcon,
  FormIcon,
  HelpIcon,
  localization,
  WorkflowIcon,
  LogoIcon
};
