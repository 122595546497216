import { CloseOutlined, FileImageOutlined, InboxOutlined } from "@ant-design/icons";
import { Form, Upload } from "antd";
import notificationService from "../../util/notification.service";
import { dummyRequest, utilService } from "../../util/utils.service";
import { FileUploadProps } from "./types";
const { Dragger } = Upload;

function FileUpload({
  file = {},
  setFile,
  loading = false,
  setFieldValue,
  allowedTypes,
  multipleFiles,
  maxFiles,
  name
}: FileUploadProps) {
  //   return <>File Upload Component</>;

  const allowedTypesArray = allowedTypes.split(",").map((type) => type.trim().substring(1).toUpperCase());

  const onFileRemove = (_file: any) => {
    return new Promise<boolean>((resolve, reject) => {
      setFieldValue(name, undefined);
      setFile({});
      resolve(_file);
    });
  };
  const uploadOptions = {
    accept: allowedTypes,
    showUploadList: false,
    multiple: multipleFiles,
    maxCount: maxFiles,
    customRequest: dummyRequest,
    beforeUpload(file: any) {
      if (!(file.type === "image/png")) {
        notificationService.error(`Only ${allowedTypesArray} files are allowed!`);
        return Promise.reject();
      } else {
        const fileSizeLimit = file.size / 1024 / 1024 < 2;
        if (!fileSizeLimit) {
          notificationService.error(`File must be smaller than ${2}MB!`);
          return Promise.reject();
        }
      }
    },
    onChange: (info: any) => {
      let value = info.fileList.filter((file: any) => !!file.status);
      value = value?.find((fileInfo: any) => true)?.originFileObj;
      setFile(value);
    },
    onFileRemove: onFileRemove
  };
  const getFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  return (
    <>
      <Form.Item
        name={name}
        rules={[{ required: true, message: "Please upload a signature" }]}
        getValueFromEvent={getFile}
      >
        <Dragger {...uploadOptions} disabled={loading}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
          <p className="ant-upload-hint">
            Support for a single upload. Strictly prohibited from uploading any sensitive data.
          </p>
        </Dragger>
      </Form.Item>
      {!utilService.isEmpty(file) && (
        <div className="hr-mt-20  upload-resume-list">
          {/* {wordFormats.includes(file?.type) ? (
            <FileWordOutlined className="hr-fs-22" />
          ) : (
            <FilePdfOutlined className="hr-fs-22" />
          )} */}
          <FileImageOutlined className="hr-fs-22" />
          <p className="hr-fs-14 hr-ml-10 hr-mr-10">{file?.name}</p>
          <CloseOutlined className="cursor hr-fs-14" onClick={onFileRemove} disabled={loading} />
        </div>
      )}
    </>
  );
}

export default FileUpload;
