import React from "react";
import { Navigate } from "react-router-dom";
import { queryClient } from "../apiServices/client";
import { STORAGE_CONST } from "../constants/constant";
import useDocumentTitle from "../hooks/useDocumentTitle";
import { AUTHENTICATED_ROUTES } from "../constants/route-constant";

interface propType {
  title: string;
  defaultTitle?: string;
  children: React.ReactElement;
}

const PublicRoute: React.FC<propType> = ({ title, children, defaultTitle = "Dashboard" }) => {
  const isTokenExist = queryClient.getQueryData([STORAGE_CONST.TOKEN]);
  useDocumentTitle(isTokenExist ? defaultTitle : title);

  return isTokenExist ? <Navigate replace to={AUTHENTICATED_ROUTES.JOB_OPENING} /> : children;
};

export default PublicRoute;
