import ApiService from "../util/api.service";
import { formTemplatePayload, listingPayloadType } from "../types";
import { API_CONFIG_URLS } from "../constants/config";
import { utilService } from "../util/utils.service";

const FormTemplateApiService = {
  getListing,
  addFormTemplate,
  deleteFormTemplate,
  updateFormTemplate,
  getFormTemplate,
  getInternalForm,
  getExternalFormTemplate
};

async function getListing(payload: listingPayloadType) {
  const url = utilService.createDynamicUrl(API_CONFIG_URLS.FORM_TEMPLATE.LISTING, payload);
  const response = await ApiService.get(url);
  return response;
}

async function getFormTemplate(id: any) {
  const url = utilService.createDynamicUrl(API_CONFIG_URLS.FORM_TEMPLATE.DYNAMIC_TEMPLATE, { id });
  const response = await ApiService.get(url);
  return response;
}

async function getExternalFormTemplate(id: any, query: any) {
  const url = utilService.createDynamicUrl(API_CONFIG_URLS.FORM_TEMPLATE.EXTERNAL_FORM, { id });
  const response = await ApiService.get(url, query);
  return response;
}

async function addFormTemplate(payload: formTemplatePayload) {
  const response = await ApiService.post(API_CONFIG_URLS.FORM_TEMPLATE.ADD_TEMPLATE, payload);
  return response;
}

async function updateFormTemplate(payload: formTemplatePayload, id: number | undefined) {
  const url = utilService.createDynamicUrl(API_CONFIG_URLS.FORM_TEMPLATE.DYNAMIC_TEMPLATE, { id });
  const response = await ApiService.put(url, payload);
  return response;
}

async function deleteFormTemplate(payload: { id: number }) {
  const url = utilService.createDynamicUrl(API_CONFIG_URLS.FORM_TEMPLATE.DYNAMIC_TEMPLATE, payload);
  const response = await ApiService.remove(url);
  return response;
}

async function getInternalForm(payload: { id: number }) {
  const url = utilService.createDynamicUrl(API_CONFIG_URLS.FORM_TEMPLATE.INTERNAL_FORM, payload);
  const response = await ApiService.get(url);
  return response;
}

export default FormTemplateApiService;
