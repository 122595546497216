export const FIELD_LABEL_CONSTANTS = {
  EMAIL: "email",
  EMAIL_ADDRESS: "email address",
  NAME: "name",
  FIRST_NAME: "first name",
  LAST_NAME: "last name",
  FULL_NAME: "full name",
  PHONE: "phone",
  PHONE_NUMBER: "phone number",
  CONTACT: "contact",
  CONTACT_NUMBER: "contact number",
  MOBILE_TELEPHONE: "mobile telephone",
  HOME_TELEPHONE: "home telephone",
  CELL_PHONE: "cell phone"
};

export const FORM_PAYLOAD_CONSTANTS = {
  EMAIL: "email",
  FULLNAME: "fullName",
  PHONE_NUMBER: "phoneNumber",
  SIGNATURE_TEXT: "signatureText",
  SIGNATURE: "signature",
  SIGNATURE_TYPE: "signatureType",
  SECTIONS: "sections",
  FORM_TEMPLATE_ID: "formTemplateId",
  RESPONSE: "response",
  FIELD_VALUES: "fieldValues",
  VALIDATION: "validation"
};
