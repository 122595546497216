import { Button, Card, Image } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";

import AuthWrapper from "../../../layouts/AuthWrapper";
import Success from "../../../assets/images/account-successful.png";
import { UNAUTHENTICATED_ROUTES } from "../../../constants/route-constant";

import styles from "./Auth.module.scss";

const ResetSuccessful = () => {
  const navigate = useNavigate();

  return (
    <AuthWrapper>
      <Card className={`${styles["card-container"]}`}>
        <div className={`${styles["successful-container"]}`}>
          <div className={`hr-mb-25 ${styles["img-success"]}`}>
            <Image preview={false} src={Success} />
          </div>
          <h1 className={`hr-mb-40 ${styles["heading-input"]}`}>Password Reset Successfully</h1>

          <p className={`${styles["para-success"]}`}>
            Your password has been successfully reset. Click below to login manually.
          </p>

          {/* <Button
            onClick={() => {
              navigate(UNAUTHENTICATED_ROUTES.SIGN_UP);
            }}
            className={`${styles["btn-auth"]}`}
            type="primary"
            htmlType="submit"
          >
            Continue
          </Button> */}
          <p
            className={`${styles["back-to"]}`}
            onClick={() => {
              navigate(UNAUTHENTICATED_ROUTES.LOGIN);
            }}
          >
            Back To Login
          </p>
        </div>
      </Card>
    </AuthWrapper>
  );
};

export default ResetSuccessful;
