const reportController = "/Report";
const authController = "/Authentication";
const lookupController = "/Lookup";
const jobOpeningController = "/JobOpening";
const departmentController = "/Department";
const locationController = "/CompanyLocation";
const candidateController = "/Candidate";
const mediaController = "/Media";
const formTemplate = "/FormTemplate";
const interviewSchedule = "InterviewSchedule";
const permissionController = "/Permission";
const workflowController = "/Workflow";
const localizationController = "/Language";
const standardFieldsController = "/StandardField";
const shareController = "/Share/linkedin";

const listingUrl =
  "list?companyId={companyId}&companyLocationId={companyLocationId}&status={status}&candidateId={candidateId}&SearchBy={searchBy}&Pagination.Offset={offset}&Pagination.Count={count}";

const languageListingUrl = `list?companyId={companyId}&SearchBy={searchBy}&Pagination.Offset={offset}&Pagination.Count={count}`;
export const API_CONFIG_URLS = {
  AUTH: {
    LOGIN: `${authController}/login`,
    LOGOUT: `${authController}/logout`,
    FORGET_PASSWORD: `${authController}/forget-password`,
    RESET_PASSWORD: `${authController}/reset-password`,
    SET_PASSWORD: `${authController}/set-password`,
    SIGNUP: `${authController}/sign-up`,
    USER_DETAIL: `${authController}/me`,
    HIRING_TEAM_LISTING: `${authController}/hiring-team/${listingUrl}`,
    PROFILE: `${authController}/profile`,
    HIRING_TEAM: `${authController}/hiring-team`,
    UPDATE_HIRING_TEAM: `${authController}/hiring-team/{id}`,
    UPDATE_PASSWORD: `${authController}/update-password`
  },
  LOOKUP: {
    ROLES: `${lookupController}/roles`,
    EXPERIENCES: `${lookupController}/experiences`,
    LOCATIONS: `${lookupController}/locations`,
    DEPARTMENTS: `${lookupController}/departments`,
    ACCOUNTS: `${lookupController}/accounts`,
    LOCATION_BY_SEARCH: `${lookupController}/locations-by-search`,
    LOCATION_BY_JOB_ID: `${lookupController}/job-opening-locations`,
    JOB_OPENINGS: `${lookupController}/job-openings`,
    LOCATION_USERS: `${lookupController}/location-users`,
    FORM_TEMPLATES: `${lookupController}/form-templates`,
    WORK_FLOWS: `${lookupController}/workflows`,
    LANGUAGES: `${lookupController}/languages`,
    FIELD_CATEGORIES: `${lookupController}/field-categories`
  },
  JOB_OPENING: {
    LISTING: `${jobOpeningController}/${listingUrl}`,
    GET_JOB_OPENING: `${jobOpeningController}/{id}`,
    DELETE_JOB_OPENING: `${jobOpeningController}/{id}`,
    UPDATE_STATUS: `${jobOpeningController}/{id}/status`,
    JOB: `${jobOpeningController}`,
    ADD_FORM_TEMPLATE: `${jobOpeningController}/{id}/form-template`,
    TRANSFER_JOB_OPENING: `${jobOpeningController}/{id}/transfer`,
    ADD_LOCATIONS: `${jobOpeningController}/{id}/location`,
    EXTERNAL_LIST: `${jobOpeningController}/external-list`
  },
  DEPARTMENT: {
    LISTING: `${departmentController}/list?companyId={companyId}&SearchBy={searchBy}&Pagination.Offset={offset}&Pagination.Count={count}`,
    DEPARTMENT: `${departmentController}`,
    UPDATE_DEPARTMENT: `${departmentController}/{id}`
  },
  LOCATION: {
    LISTING: `${locationController}/${listingUrl}`,
    DELETE_LOCATION: `${locationController}/{id}`,
    USER_LIST: `${locationController}/{id}/users/list?SearchBy={searchBy}&Pagination.Offset={offset}&Pagination.Count={count}`,
    ADD_EDIT_DELETE_USER: `${locationController}/{id}/user`,
    ADD_COMPANY_LOCATION: locationController,
    UPDATE_COMPANY_LOCATION: `${locationController}/{id}`,
    LOCATION_DETAILS: `${locationController}/{id}`,
    UPDATE_LOCATION_STATUS: `${locationController}/{id}/status`,
    RECRUITMENT: `${locationController}/recruitment-collaterals`,
    RECRUITMENT_ADD: `${locationController}/recruitment-collateral`,
    RECRUITMENT_LIST: `${locationController}/recruitment-collaterals/list`,
    RECRUITMENT_BY_ID: `${locationController}/recruitment-collateral/{recruitmentCollateralId}`
  },
  CANDIDATE: {
    LISTING: `${candidateController}/list?jobOpeningId={jobOpeningId}&SearchBy={searchBy}&Pagination.Offset={offset}&Pagination.Count={count}`,
    UPDATE_STATUS: `${candidateController}/{id}/status`,
    ADD_RECRUITING_NOTE: `${candidateController}/{id}/recruitment-note`,
    RECRUITMENT_NOTE_LISTING: `${candidateController}/{candidateId}/recruitment-notes/list?Pagination.Offset={offset}&Pagination.Count={count}`,
    DELETE_RECRUITMENT_NOTE: `${candidateController}/{id}/recruitment-note/{recruitmentNoteId}`,
    EDIT_RECRUITMENT_NOTE: `${candidateController}/{id}/recruitment-note/{recruitmentNoteId}`,
    TRANSFER_CANDIDATE: `${candidateController}/{id}/transfer`,
    GET_CANDIDATE: `${candidateController}/{id}`,
    ADD_CANDIDATE: `${candidateController}`,
    DETAILS: `${candidateController}/{id}`,
    UPDATE_DISPOSITION: `${candidateController}/{id}/disposition`,
    UPDATE_SESSION: `${candidateController}/session`
  },
  MEDIA: {
    PROFILE_UPLOAD: `${mediaController}/upload-profile`,
    PROFILE_DELETE: `${mediaController}/delete-profile`,
    UPLOAD_ATTACHMENT: `${mediaController}/upload-attachment`,
    UPLOAD_MEDIA: `${mediaController}/upload-media`,
    UPLOAD_COMPANY_LOGO: `${mediaController}/upload-company-logo`,
    DELETE_COMPANY_LOGO: `${mediaController}/{id}`,
    UPLOAD_RC: `${mediaController}/upload-recruitment-collateral`,
    UPLOAD_SIGNATURE: `${mediaController}/upload-signature`
  },
  FORM_TEMPLATE: {
    LISTING: `${formTemplate}/${listingUrl}`,
    ADD_TEMPLATE: `${formTemplate}`,
    DYNAMIC_TEMPLATE: `${formTemplate}/{id}`,
    INTERNAL_FORM: `${formTemplate}/{id}`,
    EXTERNAL_FORM: `${formTemplate}/external/{id}`
  },
  INTERVIEW_SCHEDULE: {
    SCHEDULE: `${interviewSchedule}`,
    SCHEDULE_LISTING: `${interviewSchedule}/list?SearchBy={searchBy}&Pagination.Offset={offset}&Pagination.Count={count}&candidateApplicationId={candidateApplicationId}&startDate={startDate}&endDate={endDate}`,
    GET_INTERVIEW_SCHEDULE: `${interviewSchedule}/{id}`
  },
  REPORT: {
    APPLICATION_REPORT: `${reportController}/applications-report/list`,
    APPLICANTS_HIRED_REPORT: `${reportController}/applicants-hired-report/list`,
    RECRUITING_ACTIVITY_REPORT: `${reportController}/recruiting-activity-report/list`,

    USER_LOCATIONS_REPORT: `${reportController}/user-locations-report/list`,
    EEO_REPORT: `${reportController}/eeo-report/list`,
    EEO_REPORT_2: `${reportController}/eeo-report-2/list`,
    APPLICANT_SOURCES_REPORT: `${reportController}/applicant-sources-report/list`,
    LOGINS_REPORT: `${reportController}/logins-report/list`,
    APPLICANT_FIELDS_REPORT: `${reportController}/applicant-fields-report/list`,
    LOCATION_STATS_REPORT: `${reportController}/location-stats-report/list`,

    EXPORT_APPLICATION_REPORT: `${reportController}/applications-report/export`,
    EXPORT_HIRED_REPORT: `${reportController}/applicants-hired-report/export`,
    EXPORT_RECRUITING_REPORT: `${reportController}/recruiting-activity-report/export`,

    EXPORT_USER_LOCATIONS_REPORT: `${reportController}/user-locations-report/export`,
    EXPORT_EEO_REPORT: `${reportController}/eeo-report/export`,
    EXPORT_EEO_REPORT_2: `${reportController}/eeo-report-2/export`,
    EXPORT_APPLICANT_SOURCES_REPORT: `${reportController}/applicant-sources-report/export`,
    EXPORT_LOGINS_REPORT: `${reportController}/logins-report/export`,
    EXPORT_APPLICANT_FIELDS_REPORT: `${reportController}/applicant-fields-report/export`,
    EXPORT_LOCATION_STATS_REPORT: `${reportController}/location-stats-report/export`
  },
  PERMISSION: {
    GET_PERMISSIONS: `${permissionController}/selected/list`,
    GET_PERMISSIONS_LIST: `${permissionController}/list`
  },
  WORKFLOW: {
    LISTING: `${workflowController}/${listingUrl}`,
    ADD_WORKFLOW: `${workflowController}`,
    DELETE_WORKFLOW: `${workflowController}/{id}`,
    EDIT_WORKFLOW: `${workflowController}/{id}`,
    EXTERNAL_WORKFLOW: `${workflowController}/external`
  },
  LANGUAGE: {
    LISTING: `${localizationController}/${languageListingUrl}`,
    ADD_LANGUAGE: `${localizationController}`,
    GET_LANGUAGE: `${localizationController}/{id}`,
    UPDATE_STATUS: `${localizationController}/{id}/status`,
    UPDATE_DEFAULT: `${localizationController}/{id}/default`,
    LANGUAGE_DOWNLOAD: `${localizationController}/{id}/download?companyId={companyId}`,
    DELETE_LANGUAGE: `${localizationController}/{id}`
  },
  STANDARD_FIELDS: {
    LISTING: `${standardFieldsController}/list`
  },
  SHARE: {
    MEDIA: `${shareController}/share`,
    ACCESS_TOKEN: `${shareController}/access-token`
  }
};

export const multiFormDataHeader = {
  headers: {
    "Content-Type": "multipart/form-data",
    Accept: "text/plain"
  }
};
