import { useMutation, useQuery } from "@tanstack/react-query";

import { queryClient } from "../../../apiServices/client";
import { Permission, STORAGE_CONST } from "../../../constants/constant";
import notificationService from "../../../util/notification.service";
import { AuthApiService, LookupApiService } from "../../../apiServices";
import { permissionBasedRoutes } from "../../../util/permissionsService";
import {
  forgetPasswordPayload,
  loginPayload,
  lookupDataType,
  permissionListItemType,
  resetPasswordPayload,
  roleType,
  setPasswordPayload,
  signUpPayload
} from "../../../types";

export const authQueryKeys = {
  roles: ["LOOKUP_ROLES"],
  userDetail: ["USER_DETAIL"],
  experiences: ["LOOKUP_EXPERIENCES"],
  userPermissions: ["USER_PERMISSIONS"],
  permissionsList: ["PERMISSIONS_LIST"]
};

const lookupSelect = ({ ok, data, response }: { ok: boolean; response: any; data: any }) => {
  if (ok) {
    const mutatedData = data.map((item: lookupDataType) => {
      return {
        value: item.id,
        label: item.name
      };
    });
    return mutatedData;
  }
  throw response?.message;
};

//using this lookup to display Admin role only while signing up
const adminRoleLookupSelect = ({ ok, data, response }: { ok: boolean; response: any; data: any }) => {
  if (ok) {
    const mutatedData = data
      ?.filter((item: lookupDataType) => item.name === "Admin")
      .map((item: lookupDataType) => ({
        value: item?.id,
        label: item?.name
      }));
    return mutatedData;
  }
  throw response?.message;
};

export const modifyPermissionsList = ({ ok, data, response }: { ok: boolean; response: any; data: any }) => {
  if (ok) {
    const updatedData = data?.filter((permission:Permission) => {
      return Object.values(permissionBasedRoutes).includes(permission.value);
  });
    const mutatedData = updatedData?.map((item: permissionListItemType) => {
      const { roles } = item;

      const transformedRoles = roles.reduce((acc: any, role: roleType) => {
        acc[role.name] = role.isSelected;
        return acc;
      }, {});

      return {
        ...item,
        ...transformedRoles
      };
    });
    return mutatedData;
  }
  throw response?.message;
};

// MUTATIONS

export const useLogin = () => {
  return useMutation((payload: loginPayload) => AuthApiService.login(payload), {
    onSuccess: ({ ok, response, data }: { ok: boolean; response: any; data: any }) => {
      if (ok) {
        queryClient.setQueryData([STORAGE_CONST.TOKEN], data.token);
        queryClient.invalidateQueries(authQueryKeys.userPermissions);
        return data;
      }
      notificationService.error(response.message);
      throw response.message;
    },
    onError: (err) => {
      throw err;
    }
  });
};

export const useForgetPassword = (onSuccess: any) => {
  return useMutation(async (payload: forgetPasswordPayload) => await AuthApiService.forgetPassword(payload), {
    onSuccess: ({ ok, response, data }: { ok: boolean; response: any; data: any }) => {
      if (ok) {
        onSuccess();
        notificationService.success(response?.message);
        return data;
      }
      notificationService.error(response?.message);
      throw response.message;
    },
    onError: (err) => {
      throw err;
    }
  });
};

export const useResetPassword = (onSuccess: any) => {
  return useMutation(async (payload: resetPasswordPayload) => await AuthApiService.resetPassword(payload), {
    onSuccess: ({ ok, response, data }: { ok: boolean; response: any; data: any }) => {
      if (ok) {
        onSuccess();
        notificationService.success("Password Reset Successfully");
        return data;
      }
      notificationService.error(response?.message);
      throw response.message;
    },
    onError: (err) => {
      throw err;
    }
  });
};

export const useSetPassword = (onSuccess: () => void) => {
  return useMutation(async (payload: setPasswordPayload) => await AuthApiService.setPassword(payload), {
    onSuccess: ({ ok, response, data }: { ok: boolean; response: any; data: any }) => {
      if (ok) {
        onSuccess();
        notificationService.success("Success");
        return data;
      }
      notificationService.error(response?.message);
      throw response.message;
    },
    onError: (err) => {
      throw err;
    }
  });
};

export const useSignUp = (onSuccess: () => void) => {
  return useMutation(
    async (payload: signUpPayload) => {
      const { ok, response, data } = await AuthApiService.signup(payload);
      if (ok) {
        onSuccess();
        notificationService.success(response?.message);
        return data;
      }
      notificationService.error(response?.message);
      throw response?.message;
    },
    {
      onError: (err) => {
        throw err;
      }
    }
  );
};

export const useLogout = (onSuccess: () => void) => {
  return useMutation(() => AuthApiService.logout(), {
    onSuccess: ({ ok, response, data }) => {
      if (ok) {
        onSuccess();
        return data;
      }
      throw response?.message;
    },
    onError: (err) => {
      throw err;
    }
  });
};

// QUERIES

export const useUserDetail = () => {
  return useQuery(
    authQueryKeys.userDetail,
    async () => {
      const { ok, response, data } = await AuthApiService.userDetails();
      if (ok) {
        queryClient.setQueryData([STORAGE_CONST.USER_INFO], data);
        return data;
      }
      throw response?.message;
    },
    {
      onError: (err) => {
        throw err;
      },
      staleTime: 0
    }
  );
};

export const useLookupRoles = () => {
  return useQuery(authQueryKeys.roles, () => LookupApiService.userRoles(), {
    onError: (err) => {
      throw err;
    },
    select: lookupSelect
    // select: adminRoleLookupSelect
  });
};

export const useLookupExperiences = () => {
  return useQuery(authQueryKeys.experiences, () => LookupApiService.userExperiences(), {
    onError: (err) => {
      throw err;
    },
    select: lookupSelect
  });
};

export const useUserPermissions = () => {
  return useQuery(
    authQueryKeys.userPermissions,
    async () => {
      const { ok, response, data } = await AuthApiService.getPermissions();
      if (ok) {
        queryClient.setQueryData([STORAGE_CONST.USER_PERMISSIONS], data);
        return data;
      }
      throw response?.message;
    },
    {
      onError: (err) => {
        throw err;
      },
      staleTime: 0
    }
  );
};

export const usePermissionsList = () => {
  return useQuery(authQueryKeys.permissionsList, () => AuthApiService.getPermissionsList(), {
    onError: (err) => {
      throw err;
    },
    staleTime: 0,
    select: modifyPermissionsList
  });
};
