import React, { useState } from "react";

import "./index.scss";
import { Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

type FloatLabelProps = {
  label: string;
  value: any;
  required?: boolean;
  children?: React.ReactElement;
  className?: string;
  onClick?: () => void;
  tip?: string;
};

const FloatLabel = ({ label, value, required, children, onClick, className = "", tip }: FloatLabelProps) => {
  const [focus, setFocus] = useState(false);

  const isOccupied = focus || (value && value.length !== 0);

  const labelClass = isOccupied ? "label as-label" : "label as-placeholder";
  const requiredMark = required ? <span className="text-danger">*</span> : null;

  return (
    <div
      className={`float-label ${focus && "border-label"} ${className}`}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <label className={labelClass} onClick={onClick}>
        {label} {requiredMark}
        {tip && (
          <Tooltip title={tip} className="text-primary">
            <QuestionCircleOutlined />
          </Tooltip>
        )}
      </label>

      {children}
    </div>
  );
};

export default FloatLabel;
