import { Col, Form } from "antd";
import { NumericFormat, PatternFormat } from "react-number-format";

import { fieldType } from "../../../../../types";
import FloatDate from "../../../../../components/FloatDate";
import FloatInput from "../../../../../components/FloatInput";
import { getLabelTranslation } from "../../../../../util/utils.service";
import { VALIDATION_CONSTANTS, translationType } from "../../../../../constants/constant";

type paragraphType = {
  field: fieldType;
  getter: (id: string) => any;
  translation: translationType;
  screenColumns: number;
  conditionalFieldNames: any;
  getCustomValidation: (validation: string) => any;
  getConditionArr: (conditionalFields: any, getter: any) => any;
  checkCondition: (allOne: string, conditionArr: Array<boolean>) => boolean;
};

const Paragraph = (props: paragraphType) => {
  const {
    field,
    getter,
    translation,
    screenColumns,
    conditionalFieldNames,
    checkCondition,
    getConditionArr,
    getCustomValidation
  } = props;
  const {
    label,
    placeholder,
    required,
    type,
    id,
    condition,
    validation = "",
    validationMessage,
    tip,
    predefinedValue
  } = field;
  const name = id;
  const lowercasedType = type.toLowerCase();
  const { showHide, allOne, conditionalFields } = condition || {};

  function getFormField(
    validationType: string,
    label: string,
    lowercasedType: string,
    name: string | number,
    placeholder: string,
    tip: string
  ) {
    switch (validationType) {
      case VALIDATION_CONSTANTS.DATE:
        return (
          <FloatDate
            tip={getLabelTranslation(tip, [tip, name], translation) ?? ""}
            label={getLabelTranslation(label, [lowercasedType, name], translation)}
            type={VALIDATION_CONSTANTS.DATE}
          />
        );
      case VALIDATION_CONSTANTS.DATE_RANGE:
        return (
          <FloatDate
            tip={getLabelTranslation(tip, [tip, name], translation) ?? ""}
            label={getLabelTranslation(label, [lowercasedType, name], translation)}
            type={VALIDATION_CONSTANTS.DATE_RANGE}
          />
        );
      case VALIDATION_CONSTANTS.NUMBER:
        return (
          <NumericFormat
            maxLength={9}
            allowNegative={false}
            customInput={(props) => {
              return (
                <FloatInput
                  {...props}
                  label={getLabelTranslation(label, [lowercasedType, name], translation)}
                  placeholder={placeholder}
                  maxWordLength={9}
                  tip={getLabelTranslation(tip, [tip, name], translation) ?? ""}
                />
              );
            }}
          />
        );
      case VALIDATION_CONSTANTS.PHONE:
        return (
          <PatternFormat
            format="+1 (###) ###-####"
            mask="_"
            type="tel"
            customInput={(props) => {
              return (
                <FloatInput
                  {...props}
                  label={getLabelTranslation(label, [lowercasedType, name], translation)}
                  placeholder={placeholder}
                  tip={getLabelTranslation(tip, [tip, name], translation) ?? ""}
                />
              );
            }}
          />
        );
      default:
        return (
          <FloatInput
            tip={getLabelTranslation(tip, [tip, name], translation) ?? ""}
            label={getLabelTranslation(label, [lowercasedType, name], translation)}
            placeholder={placeholder}
          />
        );
    }
  }
  return (
    <Col xs={24} sm={24} md={screenColumns} lg={screenColumns} xl={screenColumns} xxl={screenColumns}>
      {!condition ? (
        <>
          <Form.Item
            name={id}
            rules={[
              validation && getCustomValidation(validation),

              {
                required: required,
                message: validationMessage
                  ? validationMessage
                  : getLabelTranslation("This field is required", ["validation", "required"], translation)
              }
            ].filter((rule) => rule !== undefined)}
            className="external-form-item"
            initialValue={predefinedValue}
          >
            {getFormField(validation, label, lowercasedType, name, placeholder, tip)}
          </Form.Item>
        </>
      ) : (
        <Form.Item noStyle dependencies={conditionalFieldNames}>
          {() => {
            const condition = checkCondition(allOne, getConditionArr(conditionalFields, getter));
            const shouldShow = showHide === (condition ? "show" : "hide");

            return shouldShow ? (
              <>
                <Form.Item
                  name={name}
                  rules={[
                    validation && getCustomValidation(validation),
                    {
                      required: required,
                      message: validationMessage
                        ? validationMessage
                        : getLabelTranslation("This field is required", ["validation", "required"], translation)
                    }
                  ].filter((rule) => rule !== undefined)}
                  className="external-form-item"
                  initialValue={predefinedValue}
                >
                  {getFormField(validation, label, lowercasedType, name, placeholder, tip)}
                </Form.Item>
              </>
            ) : (
              <></>
            );
          }}
        </Form.Item>
      )}
    </Col>
  );
};

export default Paragraph;
