import { DatePicker } from "antd";
import React, { useState } from "react";

import FloatLabel from "../FloatLabel";

import dayjs from "dayjs";
import { VALIDATION_CONSTANTS } from "../../constants/constant";
import { DATE_TIME_FORMAT } from "../../util/dateTime.service";
import "./index.scss";

const { RangePicker } = DatePicker;

type FloatDateParams = {
  label: string;
  required?: boolean;
  placeholder?: string;
  maxWordLength?: number;
  type: string;
  tip?: string;
  [key: string]: any;
};

const FloatDate: React.FC<FloatDateParams> = ({ label, type, value = "", required, placeholder, tip, ...rest }) => {
  const inputRef = React.useRef<any>();
  const [isFocused, setIsFocused] = useState(false);

  const handleInputFocus = () => {
    inputRef.current.focus();
  };

  return (
    <FloatLabel label={label} value={value} required={required} onClick={handleInputFocus} tip={tip}>
      {type === VALIDATION_CONSTANTS.DATE ? (
        <DatePicker
          ref={inputRef}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          placeholder={isFocused ? "Select date": ""}
          className="external-date-picker pad-left"
          format={DATE_TIME_FORMAT.REVERSED_FORMAT}
          {...rest}
        />
      ) : (
        <RangePicker
          ref={inputRef}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          className="external-date-picker pad-left"
          {...rest}
          format={DATE_TIME_FORMAT.REVERSED_FORMAT}
          placeholder={isFocused ? ["Start Date", "End Date"] : ["", ""]}
        />
      )}
    </FloatLabel>
  );
};

export default FloatDate;
