import { useState } from "react";
import { Button, Card, Form } from "antd";
import { useNavigate } from "react-router-dom";

import PersonalDetail from "./PersonalDetail";
import AuthWrapper from "../../../layouts/AuthWrapper";
import { VALIDATE } from "../../../constants/constant";
import FloatInput from "../../../components/FloatInput";
import CustomPassword from "../../../components/CustomPassword";
import { UNAUTHENTICATED_ROUTES } from "../../../constants/route-constant";

import styles from "./Auth.module.scss";
import { useLookupRoles } from "./authQueries";

type payloadType = {
  fullName: string;
  email: string;
  password: string;
  confirmPassword?: string;
};

const SignUp = () => {
  const [state, setState] = useState(false);
  const [payload, setPayload] = useState<payloadType>({
    fullName: "",
    email: "",
    password: ""
  });

  const navigate = useNavigate();

  const { data: lookupRoles = [] } = useLookupRoles();

  const handleSubmit = (data: payloadType) => {
    delete data.confirmPassword;
    setPayload((prev) => {
      return { ...prev, ...data };
    });
    setState(true);
  };

  return (
    <>
      <div className={state ? "hidden" : ""}>
        <AuthWrapper>
          <Card className={`${styles["card-container"]}`}>
            <div className={`${styles["signup-container"]}`}>
              <h1 className={`hr-mb-40 ${styles["heading-input"]}`}>Sign Up</h1>
              <Form onFinish={handleSubmit}>
                <Form.Item name="fullName" rules={VALIDATE.NAME}>
                  <FloatInput label="Name" value="" />
                </Form.Item>
                <Form.Item name="email" rules={VALIDATE.EMAIL as never}>
                  <FloatInput label="Email Address" value="" />
                </Form.Item>
                <Form.Item name="password" rules={[...VALIDATE.PASSWORD, ...VALIDATE.PASSWORD_PATTERN]}>
                  <CustomPassword label="Password" />
                </Form.Item>
                <Form.Item
                  name="confirmPassword"
                  dependencies={["password"]}
                  rules={[
                    ...VALIDATE.CONFIRM_PASSWORD,
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject("Passwords do not match");
                      }
                    })
                  ]}
                >
                  <CustomPassword label="Confirm Password" />
                </Form.Item>

                <Button className={`${styles["btn-auth"]}`} type="primary" htmlType="submit">
                  Sign Up
                </Button>

                <p className={`${styles.para}`}>
                  {`${"Already have an account?"} `}
                  <span
                    onClick={() => {
                      navigate(UNAUTHENTICATED_ROUTES.LOGIN);
                    }}
                  >
                    Login
                  </span>
                </p>
              </Form>
            </div>
          </Card>
        </AuthWrapper>
      </div>

      <div className={!state ? "hidden" : ""}>
        <PersonalDetail setPrevState={setState} payload={payload} lookupRoles={lookupRoles} />
      </div>
    </>
  );
};

export default SignUp;
