import React, { useState } from "react";
import { Button, Card, Form } from "antd";
import { useNavigate } from "react-router-dom";

import CheckEmail from "./CheckEmail";
import FloatInput from "../../../components/FloatInput";
import AuthWrapper from "../../../layouts/AuthWrapper";
import { UNAUTHENTICATED_ROUTES } from "../../../constants/route-constant";

import styles from "./Auth.module.scss";
import { forgetPasswordPayload } from "../../../types";
import { useForgetPassword } from "./authQueries";
import { VALIDATE } from "../../../constants/constant";

const ForgotPassword = () => {
  const [form] = Form.useForm();
  const [state, setState] = useState(false);
  const navigate = useNavigate();
  const onSuccess = () => {
    setState(true);
  };
  const { mutate: forgetPassword, isLoading } = useForgetPassword(onSuccess);
  const onFinish = (data: forgetPasswordPayload) => {
    forgetPassword(data);
  };
  return (
    <>
      {!state ? (
        <AuthWrapper>
          <Card className={`${styles["card-container"]}`}>
            <div className={`${styles["forgot-container"]}`}>
              <h1 className={`hr-mb-50 ${styles["heading-input"]}`}>Forgot Password</h1>
              <Form form={form} onFinish={onFinish} initialValues={{ email: null }}>
                <Form.Item name="email" rules={VALIDATE.EMAIL as never}>
                  <FloatInput label="Email Address" />
                </Form.Item>

                <Button
                  className={`${styles["btn-auth"]}`}
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                  disabled={isLoading}
                >
                  Reset Password
                </Button>

                <p
                  className={`${styles["back-to"]}`}
                  onClick={() => {
                    navigate(UNAUTHENTICATED_ROUTES.LOGIN);
                  }}
                >
                  Back To Login
                </p>
              </Form>
            </div>
          </Card>
        </AuthWrapper>
      ) : (
        <CheckEmail email={form.getFieldValue("email")} loading={isLoading} onSubmit={forgetPassword} />
      )}
    </>
  );
};

export default ForgotPassword;
