import React from "react";
import { Avatar } from "antd";

import { utilService } from "../../util/utils.service";

interface propParams {
  imageUrl: string | null | undefined;
  name: string | undefined;
  size?: number;
  className?: string;
}

const { getAvatarText } = utilService;

const CustomAvatar: React.FC<propParams> = ({ imageUrl, name, size = 40, className }) => {
  return (
    <Avatar src={imageUrl} className={className} size={size}>
      {!imageUrl && <h2>{getAvatarText(name ?? "")}</h2>}
    </Avatar>
  );
};

export default CustomAvatar;
