import React, { useState } from "react";
import { Button, Card, Form } from "antd";
import { useSearchParams } from "react-router-dom";

import ResetSuccessful from "./ResetSuccessful";
import { useResetPassword } from "./authQueries";
import { VALIDATE } from "../../../constants/constant";
import AuthWrapper from "../../../layouts/AuthWrapper";
import CustomPassword from "../../../components/CustomPassword";

import styles from "./Auth.module.scss";

const ResetPassword = () => {
  const [form] = Form.useForm();
  const [state, setState] = useState(false);
  let [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const onSuccess = () => {
    setState(true);
  };
  const { mutate: updatePassword, isLoading } = useResetPassword(onSuccess);
  const onFinish = ({ updatedPassword }: { updatedPassword: string }) => {
    updatePassword({ token: token ?? "", updatedPassword });
  };

  return (
    <>
      {!state ? (
        <AuthWrapper>
          <Card className={`${styles["card-container"]}`}>
            <div className={`${styles["reset-container"]}`}>
              <h1 className={`hr-mb-40 ${styles["heading-input"]}`}>Reset Password</h1>
              <Form form={form} onFinish={onFinish} initialValues={{ password: null, updatedPassword: undefined }}>
                <Form.Item name="password" rules={[...VALIDATE.PASSWORD, ...VALIDATE.PASSWORD_PATTERN]}>
                  <CustomPassword label="Password" />
                </Form.Item>
                <Form.Item
                  name="updatedPassword"
                  dependencies={["Password"]}
                  required={false}
                  rules={[
                    ...VALIDATE.CONFIRM_PASSWORD,
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject("Passwords do not match");
                      }
                    })
                  ]}
                >
                  <CustomPassword label="Confirm Password" />
                </Form.Item>

                <Button
                  className={`${styles["btn-auth"]}`}
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                  disabled={isLoading}
                >
                  Reset Password
                </Button>
              </Form>
            </div>
          </Card>
        </AuthWrapper>
      ) : (
        <ResetSuccessful />
      )}
    </>
  );
};

export default ResetPassword;
