import ApiService from "../util/api.service";
import { API_CONFIG_URLS } from "../constants/config";
import { scheduleInterviewPayload } from "../types";
import { utilService } from "../util/utils.service";
import { interviewSchedulePayload, scheduleListingPayload } from "../types/JobOpening/candidateTypes";

const InterviewScheduleApiService = {
  scheduleInterview,
  getScheduleListing,
  getInterviewSchedule,
  deleteInterviewSchedule,
  editInterviewSchedule
};

async function scheduleInterview(payload: scheduleInterviewPayload) {
  const response = await ApiService.post(API_CONFIG_URLS.INTERVIEW_SCHEDULE.SCHEDULE, payload);
  return response;
}

async function getScheduleListing(payload: scheduleListingPayload) {
  const url = utilService.createDynamicUrl(API_CONFIG_URLS.INTERVIEW_SCHEDULE.SCHEDULE_LISTING, payload);
  const response = await ApiService.get(url);
  return response;
}

async function getInterviewSchedule(payload: interviewSchedulePayload) {
  const url = utilService.createDynamicUrl(API_CONFIG_URLS.INTERVIEW_SCHEDULE.GET_INTERVIEW_SCHEDULE, payload);
  const response = await ApiService.get(url);
  return response;
}

async function deleteInterviewSchedule(payload: interviewSchedulePayload) {
  const url = utilService.createDynamicUrl(API_CONFIG_URLS.INTERVIEW_SCHEDULE.GET_INTERVIEW_SCHEDULE, payload);
  const response = await ApiService.remove(url);
  return response;
}

async function editInterviewSchedule(payload: scheduleInterviewPayload, id: number) {
  const url = utilService.createDynamicUrl(API_CONFIG_URLS.INTERVIEW_SCHEDULE.GET_INTERVIEW_SCHEDULE, { id });
  const response = await ApiService.put(url, payload);
  return response;
}

export default InterviewScheduleApiService;
