export const PermissionConstants = {
  SET_THEMES: "set_themes",
  MANAGE_GROUPS: "manage_groups",
  MANAGE_ROLES: "manage_roles",
  MANAGE_DOCUMENT_UPLOADS: "manage_document_uploads",
  MANAGE_API_SETTINGS: "manage_api_settings",
  ALL_CANDIDATE_DATA: "all_candidate_data",
  VIEW_EEO_REPORTING: "view_eeo_reporting",
  VIEW_EEO: "view_eeo",
  EDIT_APPLICATION: "edit_application",
  OPEN_REQUISITION: "open_requisition",
  EDIT_REQUISITION: "edit_requisition",
  CLOSE_REQUISITION: "close_requisition",
  VIEW_REQUISITION_HISTORY: "view_requisition_history",
  VIEW_APPLICATION_HISTORY: "view_application_history",
  VIEW_NOTES: "view_notes",
  EDIT_NOTES: "edit_notes",
  EDIT_APPLICATION_STATUS: "edit_application_status",
  EDIT_APPLICATION_DISPOSITION: "edit_application_disposition",
  SET_APPLICATION_STATUS: "set_application_status",
  SET_APPLICATION_DISPOSITION: "set_application_disposition",
  SET_UP_SYSTEM_WIDE_EMAIL_TEMPLATE: "set_up_system_wide_email_template",
  EDIT_EMAIL_TEMPLATE: "edit_email_template",
  REPORT_VIEW: "report_view",
  APPROVE_JOB_REQUISITION: "approve_job_requisition",
  CREATE_JOB_REQUISITION_REASONS: "create_job_requisition_reasons",
  EDIT_JOB_REQUISITION_REASONS: "edit_job_requisition_reasons",
  MANAGE_APPLICATION_LANGUAGES: "manage_application_languages",
  MANAGE_OFFER_LETTER_TEMPLATES: "manage_offer_letter_templates",
  MANAGE_CUSTOM_HELP_TEXT: "manage_custom_help_text",
  MANAGE_APPLICATION_TEMPLATES: "manage_application_templates",
  MANAGE_SOCIAL_MEDIA_INTEGRATIONS: "manage_social_media_integrations",
  POST_TO_SOCIAL_MEDIA: "post_to_social_media",
  MANAGE_RECRUITING_PERMISSIONS: "manage_recruiting_permissions",
  SET_UP_AGENCY_ACCESS: "set_up_agency_access",
  SET_UP_JOB_BOARD_ACCESS: "set_up_job_board_access",
  SET_UP_INTERNAL_CANDIDATE_SEARCHES: "set_up_internal_candidate_searches",
  RUN_CANDIDATE_SEARCHES_FOR_NEARBY_LOCATIONS: "run_candidate_searches_for_nearby_locations",
  MANAGE_DATA_PRIVACY_CONFIGURATIONS: "manage_data_privacy_configurations",
  DELETE_DUPLICATE_APPLICATIONS: "delete_duplicate_applications",
  UPLOAD_APPLICATIONS_TO_SYSTEM: "upload_applications_to_system",
  MANAGE_FILE_STORAGE_RETENTION_LIMITS: "manage_file_storage_retention_limits",
  MANAGE_SESSION_TIMEOUT_LIMITS: "manage_session_timeout_limits",
  CREATE_JOB_PAGES: "create_job_pages",
  CREATE_QUALIFYING_QUESTIONS: "create_qualifying_questions",
  EDIT_QUALIFYING_QUESTIONS: "edit_qualifying_questions",
  EDIT_JOB_PAGES: "edit_job_pages",
  MANAGE_CAREER_PAGE: "manage_career_page",
  INITIATE_DRUG_SCREEN: "initiate_drug_screen",
  INITIATE_ASSESSMENT: "initiate_assessment",
  VIEW_DRUG_SCREEN_PF: "view_drug_screen_pf",
  VIEW_ASSESSMENT_SUMMARY: "view_assessment_summary",
  VIEW_BACKGROUND_SUMMARY_PF_DECISIONAL: "view_background_summary_pf_decisional",
  VIEW_FULL_DRUG_SCREEN_DETAIL: "view_full_drug_screen_detail",
  VIEW_FULL_BACKGROUND_DETAIL: "view_full_background_detail",
  VIEW_FULL_ASSESSMENT: "view_full_assessment",
  EDIT_CUSTOMER: "edit_customer",
  MANAGE_FORMS: "manage_forms",
  MANAGE_LOCATIONS: "manage_locations",
  MANAGE_LOCATION_USERS: "manage_location_users",
  MANAGE_DEPARTMENTS: "manage_departments",
  MANAGE_HIRING_TEAM: "manage_hiring_team",
  VIEW_SCHEDULER: "view_scheduler",
  MANAGE_SCHEDULER: "manage_scheduler",
  MANAGE_LANGUAGE: "manage_application_languages"
};
