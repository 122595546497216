import { IFormState, sliderConst } from "../types";

export const PAGINATION_CONST = {
  PAGE_SIZE: 10,
  PAGE_SIZE_LARGE: 100
};

export const FORM_STEP = {
  JOB_DETAILS: 1,
  ADD_WORKFLOW: 2,
  LOCATION_FORM: 3
};

export const DRAG_TYPE = {
  PARAGRAPH: "PARAGRAPH",
  TEXT_AREA: "TEXT_AREA",
  CHECKBOX: "CHECKBOX",
  RADIO: "RADIO",
  DROP_DOWN: "DROP_DOWN",
  NOTE: "NOTE",
  FIELD: "FIELD",
  SECTION_FIELD: "SECTION_FIELD"
};

export const FORM_CONTEXT_IV: IFormState = {
  // New work
  selectedFieldId: null,
  selectedSectionId: null,
  selectedChildId: null,
  titleIds: [],
  formDetails: {
    sections: []
  }
};

export const defaultFieldObject = {
  id: 0,
  type: DRAG_TYPE.PARAGRAPH,
  value: "",
  placeholder: "",
  required: false,
  error: "",
  label: "",
  condition: null
};

export const SOCIAL_MEDIA_ENUM = [
  { name: "", image: "" },
  { name: "LinkedIn", image: require("../assets/images/linkedIn.png") },
  { name: "Indeed", image: require("../assets/images/indeed.png") },
  { name: "Glassdoor", image: require("../assets/images/glassdoor.png") }
];

export const columnTypes = {
  // INVALID: "Invalid",
  NEW_CANDIDATE: "New Candidate",
  PHONE_SCREENED: "Screened",
  INTERVIEW: "Scheduled for Interview",
  INTERVIEWED: "Interviewed",
  ARCHIVED: "Archived",
  HIRED: "Hired",
  REJECTED: "Rejected",
  SCHEDULED: "Scheduled",
  MRV_BACKGROUND_ORDERED: "MRV/Background Ordered",
  NEW_LEAD: "New Lead",
  NO_CONTACT_MADE: "No contact made by IC",
  SHOWED_AND_MET: "Showed and met with the contractor",
  NO_SHOWED: "No Showed",
  SIGNED_AGREEMENT: "Signed Agreement",
  DECLINED_TO_SIGN: "Declined to sign",
  DIRECT_CLIENT: "Direct Client"

  //
  // IN_REVIEW: "In-Review",
  // SHORTLISTED: "Shortlisted"
};

export enum cardType {
  CANDIDATES = "Candidates"
}

export enum pdfBoxTypes {
  PDF_DRAGGABLE_BOX = "pdf draggable box"
}

export const PAGE_SIZE = 10;

let eventGuid = 0;
let todayStr = new Date().toISOString().replace(/T.*$/, ""); // YYYY-MM-DD of today
const alphanumericRegex = /^[a-zA-Z0-9]+$/;
const alphanumericDashRegex = /^[a-zA-Z0-9-]+$/;
export const atLeast3Alphanumeric = /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9\s]{3,}$/;
export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
// export const numberRegex = /^\d+$/;
export const numberRegex = /^(1?)(-| ?)(\()?([0-9]{3})(\)|-| |\)-|\) )?([0-9]{3})(-| )?([0-9]{4}|[0-9]{4})$/;

export const dateRegex = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
// export const usPhone = /^\(\d{3}\)\s\d{3}\-\d{4}$/;
export const passwordRegex = /^(?=.*?[0-9])(?=.*?[A-Z])(?=.*?[#?!@$%^&*\-_+{}.><,]).{8,}$/;
const loginPasswordRegex = /^[^\s]+$/;
export const urlPattern =
  "^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?";
export const zipCodeRegex = /^(\d{5}-\d{4}|\d{5}|[A-Z]\d[A-Z]\s\d[A-Z]\d)$/;
export const ssnRegex = /^(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/;
const domainRegex = /^[a-z]+(?:-[a-z]+)*$/;

export const INITIAL_EVENTS = [
  {
    id: createEventId(),
    title: "All-day event",
    start: todayStr
  },
  {
    id: createEventId(),
    title: "Interview with John",
    start: todayStr + "T12:00:00"
  }
];

export function createEventId() {
  return String(eventGuid++);
}
export enum STORAGE_CONST {
  TOKEN = "TOKEN",
  USER_INFO = "USER_INFO",
  USER_PERMISSIONS = "USER_PERMISSIONS"
}

export const ERROR_MESSAGES = {
  TEMPLATE_NAME_REQUIRED: "Form name is required",
  INVALID_TEMPLATE_NAME: "Invalid template name",
  NAME_REQUIRED: "Name is required",
  FIRST_NAME_REQUIRED: "First name is required",
  INVALID_FIRST_NAME: "Invalid first name",
  LAST_NAME_REQUIRED: "Last name is required",
  INVALID_LAST_NAME: "Invalid last name",

  EMAIL_REQUIRED: "Email address is required",
  INVALID_EMAIL: "Email is invalid",
  PHONE_REQUIRED: "Phone is required",
  INVALID_PHONE: "Phone number is invalid",
  LABEL_REQUIRED: "Label is required",
  INVALID_LABEL: "Label contains only alpha numeric character and at least 3 characters.",
  INVALID_PLACEHOLDER: "Placeholder contains only alpha numeric character and at least 3 characters.",
  INVALID_CHECKBOX_VALUE: "Checkbox value contains only alpha numeric character and at least 3 characters.",
  INVALID_CHECKBOX_LABEL: "Checkbox label contains only alpha numeric character and at least 3 characters.",

  CHECKBOX_LABEL_REQUIRED: "Checkbox label is required",
  CHECKBOX_VALUE_REQUIRED: "Checkbox value is required",
  RADIO_LABEL_REQUIRED: "Radio label is required",
  RADIO_VALUE_REQUIRED: "Radio value is required",
  SECTION_TITLE_REQUIRED: "Section title is required",
  INVALID_SECTION_TITLE: "Section title label contains only alpha numeric character and at least 3 characters.",
  DROPDOWN_LABEL_REQUIRED: "Dropdown label is required",

  INVALID_DATE: "Please enter date in DD-MM-YYYY format",

  INVALID_SSN: "Please enter a valid SSN",
  INVALID_ZIP: "Please enter a valid zip code",
  INVALID_INTEGER: "Please enter a valid number"
};

export const VALIDATE = {
  EMAIL: [
    { required: true, message: ERROR_MESSAGES.EMAIL_REQUIRED },
    { type: "email", message: ERROR_MESSAGES.INVALID_EMAIL }
  ],
  PASSWORD: [
    { required: true, message: "Password is required" },
    {
      pattern: new RegExp(loginPasswordRegex),
      message: "Spaces are not allowed in the password."
    }
  ],
  PASSWORD_PATTERN: [
    {
      pattern: new RegExp(passwordRegex),
      message:
        "Password must be at least 8 characters and contain 1 number, 1 uppercase letter, 1 lowercase letter and 1 symbol."
    }
  ],
  CONFIRM_PASSWORD: [{ required: true, message: "Confirm password is required" }],
  NAME: [{ required: true, message: ERROR_MESSAGES.NAME_REQUIRED }],
  COMPANY_NAME: [{ required: true, message: "Company name is required" }],
  NUMBER: [
    { required: true, message: "Number is required" },
    {
      min: 17,
      max: 17,
      message: "You must enter a valid number."
    }
  ],
  PHONE: (message: string) => [
    { required: true, message: message ? message : "Phone number is required" },
    {
      min: 14,
      max: 14,
      message: "You must enter a valid number."
    }
  ],
  ADDRESS: (message: string) => [{ required: true, message: message ? message : "Address is required" }],
  ROLE: [{ required: true, message: "Role is required" }],
  DEPARTMENT_NUMBER: [
    { required: true, message: "ID is required" },
    {
      min: 1,
      max: 15,
      message: "Cost number should not exceed 15 digits."
    },
    { pattern: alphanumericDashRegex, message: "Only alphanumeric characters and dash is allowed" }
  ],
  JOB_TITLE: [{ required: true, message: "Job title is required" }],
  JOB_DESC: [{ required: true, message: "Job description is required" }],
  START_DATE: [{ required: true, message: "Start date is required" }],
  END_DATE: [{ required: true, message: "End date is required" }],
  NO_OF_OPENING: [{ required: true, message: "No. of opening is required" }],
  HOURLY_RATE: [{ required: true, message: "Hourly rate is required" }],
  POSITION_TYPE: [{ required: true, message: "Position type is required" }],
  EXPERIENCE: [{ required: true, message: "Experience is required" }],
  DEPARTMENT: [{ required: true, message: "Department is required" }],
  LOCATION: [{ required: true, message: "Location is required" }],
  // REGION: [{ pattern: alphanumericRegex, message: "Region can only contain alpha numeric character." }],
  TEMPLATE_NAME: [{ required: true, message: "Template name is required" }],
  WORKFLOW_NAME: [
    { required: true, message: "Workflow name is required" },
    { min: 3, max: 100, message: "Name should be more than 3 and less than 100 characters" }
  ],

  RESUME_UPLOAD_TEXT: [{ required: true, message: "Resume upload text is required" }],
  WORKFLOW_FORM_LIST: [
    {
      validator: async (_: any, names: any) => {
        if (!names?.length) {
          return Promise.reject(new Error("Please add atleast 1 form template"));
        }
      }
    }
  ],
  ALL_ONE: [{ required: true, message: "Please select an option" }],
  SHOW_HIDE: [{ required: true, message: "Please select an option" }],
  FIELD: [{ required: true, message: "Please select a field" }],
  FIELD_VALUE: [{ required: true, message: "Please enter a value" }],
  FILLED_OPTION: [{ required: true, message: "Please select an option" }],
  INTERVIEWER_LIST: [
    {
      validator: async (_: any, names: any) => {
        if (!names?.length) {
          return Promise.reject(new Error("Please add atleast 1 interviewer"));
        }
      }
    }
  ],
  REGION: [{ min: 1, max: 55, message: "Region should not exceed 55 characters" }],
  MARKET: [{ min: 1, max: 55, message: "Market should not exceed 55 characters" }],
  MIN_RATE: [{ required: true, message: "Minimum rate is required" }],
  MAX_RATE: [{ required: true, message: "Maximum rate is required" }],
  DOMAIN: [
    { pattern: new RegExp(domainRegex), message: "Only characters and hyphens are allowed" },
    { max: 40, message: "Domain should not exceed 40 characters" },
    { required: true, message: "Domain is required" }
  ]
};

export const POSITION_TYPE_OPTIONS = [
  {
    value: 1,
    label: "Part time"
  },
  {
    value: 2,
    label: "Full time"
  }
];

export const CANDIDATE_STATUS = {
  // NEW_CANDIDATE = 1,
  // PHONE_SCREENED = 2,
  // SCHEDULED_FOR_INTERVIEW = 3,
  // SHORTLISTED = 4,
  // HIRED = 5,
  // REJECTED = 6

  //
  // INVALID: 0,
  NEW_CANDIDATE: 1,
  PHONE_SCREENED: 2,
  INTERVIEW: 3,
  INTERVIEWED: 4,
  ARCHIVED: 5,
  HIRED: 6,
  REJECTED: 7,
  SCHEDULED: 8,
  MRV_BACKGROUND_ORDERED: 9,
  NEW_LEAD: 10,
  NO_CONTACT_MADE: 11,
  SHOWED_AND_MET: 12,
  NO_SHOWED: 13,
  SIGNED_AGREEMENT: 14,
  DECLINED_TO_SIGN: 15,
  DIRECT_CLIENT: 16
  // IN_REVIEW: 17,
  // SHORTLISTED: 18
};

export enum LANGUAGE_STATUS {
  ACTIVE = 2,
  INACTIVE = 3
}

export const LANGUAGE_STATUS_OPTIONS = [
  { label: "Active", value: LANGUAGE_STATUS.ACTIVE },
  { label: "Inactive", value: LANGUAGE_STATUS.INACTIVE }
];

export enum JOB_STATUS {
  ACTIVE = 2,
  INACTIVE = 3,
  ALL = 4
}

export const JOB_STATUS_OPTIONS = [
  { label: "Active", value: JOB_STATUS.ACTIVE },
  { label: "Expired", value: JOB_STATUS.INACTIVE } //discussed with backend to change the label only
];

export const JOB_STATUS_FILTER_OPTIONS = [
  { label: "All", value: JOB_STATUS.ALL },
  { label: "Active", value: JOB_STATUS.ACTIVE },
  { label: "Expired", value: JOB_STATUS.INACTIVE } //discussed with backend to change the label only
];

export const CANDIDATE_STATUS_OPTIONS = [
  // { label: columnTypes.NEW_CANDIDATE, value: CANDIDATE_STATUS.NEW_CANDIDATE },
  // { label: columnTypes.PHONE_SCREENED, value: CANDIDATE_STATUS.PHONE_SCREENED },
  // { label: columnTypes.INTERVIEW, value: CANDIDATE_STATUS.INTERVIEW },
  //
  // { label: columnTypes.INVALID, value: CANDIDATE_STATUS.INVALID },
  { label: columnTypes.NEW_CANDIDATE, value: CANDIDATE_STATUS.NEW_CANDIDATE },
  { label: columnTypes.PHONE_SCREENED, value: CANDIDATE_STATUS.PHONE_SCREENED },
  { label: columnTypes.INTERVIEW, value: CANDIDATE_STATUS.INTERVIEW },
  { label: columnTypes.INTERVIEWED, value: CANDIDATE_STATUS.INTERVIEWED },
  { label: columnTypes.ARCHIVED, value: CANDIDATE_STATUS.ARCHIVED },
  { label: columnTypes.HIRED, value: CANDIDATE_STATUS.HIRED },
  { label: columnTypes.REJECTED, value: CANDIDATE_STATUS.REJECTED },
  { label: columnTypes.SCHEDULED, value: CANDIDATE_STATUS.SCHEDULED },
  { label: columnTypes.MRV_BACKGROUND_ORDERED, value: CANDIDATE_STATUS.MRV_BACKGROUND_ORDERED },
  { label: columnTypes.NEW_LEAD, value: CANDIDATE_STATUS.NEW_LEAD },
  { label: columnTypes.NO_CONTACT_MADE, value: CANDIDATE_STATUS.NO_CONTACT_MADE },
  { label: columnTypes.SHOWED_AND_MET, value: CANDIDATE_STATUS.SHOWED_AND_MET },
  { label: columnTypes.NO_SHOWED, value: CANDIDATE_STATUS.NO_SHOWED },
  { label: columnTypes.SIGNED_AGREEMENT, value: CANDIDATE_STATUS.SIGNED_AGREEMENT },
  { label: columnTypes.DECLINED_TO_SIGN, value: CANDIDATE_STATUS.DECLINED_TO_SIGN },
  { label: columnTypes.DIRECT_CLIENT, value: CANDIDATE_STATUS.DIRECT_CLIENT }
  // { label: columnTypes.IN_REVIEW, value: CANDIDATE_STATUS.IN_REVIEW },
  // { label: columnTypes.SHORTLISTED, value: CANDIDATE_STATUS.SHORTLISTED }
];

export enum CONTEXT_ACTIONS {
  ADD_TITLE_ID = "ADD_TITLE_ID",
  DELETE_TITLE_ID = "DELETE_TITLE_ID",
  UPDATE_TEXT_FIELD = "UPDATE_TEXT_FIELD",
  UPDATE_TITLE = "UPDATE_TITLE",
  ADD_SECTION = "ADD_SECTION",
  DELETE_SECTION = "DELETE_SECTION",
  ADD_FIELD = "ADD_FIELD",
  DELETE_FIELD = "DELETE_FIELD",
  SET_SELECTED_FIELD_ID = "SET_SELECTED_FIELD_ID",
  ADD_CHILD = "ADD_CHILD",
  DELETE_CHILD = "DELETE_CHILD",
  UPDATE_RADIO_CHECK = "UPDATE_RADIO_CHECK",
  UPDATE_CHECKBOX_CHECK = "UPDATE_CHECKBOX_CHECK",
  UPDATE_VALUE = "UPDATE_VALUE",
  UPDATE_RADIO_VALUE = "UPDATE_RADIO_VALUE",
  SET_ERRORS = "SET_ERRORS",
  SET_FORM_DETAILS = "SET_FORM_DETAILS",
  DRAG_SECTIONS = "DRAG_SECTIONS",
  UPDATE_RADIO_CHILD = "UPDATE_RADIO_CHILD",
  DRAG_FIELDS = "DRAG_FIELDS",
  ADD_CONDITION = "ADD_CONDITION",
  ADD_STANDARD_FIELD = "ADD_STANDARD_FIELD",
  RE_ORDER_FIELDS = "RE_ORDER_FIELDS"
}

export enum PDF_CONTEXT_ACTIONS {
  ADD_FIELD = "ADD_TEXT_FIELD",
  UPDATE_FIELD = "UPDATE_FIELD",
  DELETE_FIELD = "DELETE_FIELD",
  COPY_FIELD = "COPY_FIELD",
  UPDATE_POSITION = "UPDATE_POSITION",
  UPDATE_STATE = "UPDATE_STATE"
}

export enum PDF_FIELD_FORMAT {
  image = "image",
  text = "text",
  phone = "phone"
}

export enum PDF_FIELD_TYPE {
  NUMBER = PDF_FIELD_FORMAT.phone,
  QR_CODE = PDF_FIELD_FORMAT.image,
  TEXT_FIELD = PDF_FIELD_FORMAT.text
}

export const PDF_TYPE_TITLE: { [key: string]: string } = {
  [PDF_FIELD_FORMAT.image]: "QR Code Field",
  [PDF_FIELD_FORMAT.text]: "Text Field",
  [PDF_FIELD_FORMAT.phone]: "Number Field"
};

export const wordFormats = [
  "application/vnd.openxmlformats-officedocument.wordprocessingm",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
];
export const supportedFileFormat = ["application/pdf"];
export enum TRANSFER_TYPE {
  DEPARTMENT = 1,
  LOCATION = 2
}
export const locOptions = [
  {
    label: "Seattle, WA, USA",
    value: "ChIJVTPokywQkFQRmtVEaUZlJRA",
    locationNumber: null,
    market: null,
    region: null
  },
  {
    label: "Seneca Rocks, WV, USA",
    value: "ChIJufuwlFXVtIkR0gWBZF4cjz0",
    locationNumber: null,
    market: null,
    region: null
  },
  {
    label: "KoderLabs, Texas 114, Southlake, TX, USA",
    value: "ChIJt0qQPmicToYRm4HLFYULSCA",
    locationNumber: null,
    market: null,
    region: null
  },
  {
    label: "Severn, MD, USA",
    value: "ChIJE8z66IDjt4kR1JF8RwkgxSU",
    locationNumber: null,
    market: null,
    region: null
  },
  {
    label: "Secaucus, NJ, USA",
    value: "ChIJD-XGzLZXwokRTw5UpowEXV8",
    locationNumber: null,
    market: null,
    region: null
  }
];
export const SLIDER_CONST: sliderConst = {
  [PDF_FIELD_FORMAT.phone]: { MIN: 16, MAX: 30 },
  [PDF_FIELD_FORMAT.image]: { MIN: 100, MAX: 300 },
  [PDF_FIELD_FORMAT.text]: { MIN: 16, MAX: 30 }
};
export const DEFAULT_PDF_VALUES = {
  TOP: 0,
  LEFT: 0,
  TEXT_SIZE: 18,
  QR_CODE_SIZE: 150
};

export enum DISPOSITION_TYPE {
  NONE = 0,
  HIRED = 1,
  QUALIFIED_CANDIDATE_SELECTED = 2,
  BACKGROUND_CHECK_FAILED = 3,
  CANDIDATE_WITHDREW = 4,
  OFFER_DECLINED = 5,
  DUPLICATE = 6,
  INELIGIBLE = 7
}

export const DISPOSITION_LABEL = {
  NONE: "None",
  HIRED: "Hired",
  QUALIFIED_CANDIDATE_SELECTED: "Qualified Candidate Selected",
  BACKGROUND_CHECK_FAILED: "Background Check Failed",
  CANDIDATE_WITHDREW: "Candidate Withdrew",
  OFFER_DECLINED: "Offer Declined",
  DUPLICATE: "Duplicate",
  INELIGIBLE: "Ineligible"
};

export const DISPOSITION_TYPE_OPTIONS = [
  { label: DISPOSITION_LABEL.NONE, value: DISPOSITION_TYPE.NONE, disabled: true },
  { label: DISPOSITION_LABEL.HIRED, value: DISPOSITION_TYPE.HIRED },
  { label: DISPOSITION_LABEL.QUALIFIED_CANDIDATE_SELECTED, value: DISPOSITION_TYPE.QUALIFIED_CANDIDATE_SELECTED },
  { label: DISPOSITION_LABEL.BACKGROUND_CHECK_FAILED, value: DISPOSITION_TYPE.BACKGROUND_CHECK_FAILED },
  { label: DISPOSITION_LABEL.CANDIDATE_WITHDREW, value: DISPOSITION_TYPE.CANDIDATE_WITHDREW },
  { label: DISPOSITION_LABEL.OFFER_DECLINED, value: DISPOSITION_TYPE.OFFER_DECLINED },
  { label: DISPOSITION_LABEL.DUPLICATE, value: DISPOSITION_TYPE.DUPLICATE },
  { label: DISPOSITION_LABEL.INELIGIBLE, value: DISPOSITION_TYPE.INELIGIBLE }
];

export const CALENDAR_VIEW_CONSTANTS = {
  MONTH: "dayGridMonth",
  WEEK: "timeGridWeek"
};

export const ACTION_TYPE = {
  VIEW: 1,
  EDIT: 2,
  DELETE: 3,
  DOWNLOAD: 4
};

export enum SESSION_REFERER {
  QR = "/",
  SMS = "text"
}
export const ACTION_BUTTONS = [
  {
    pdfFieldType: PDF_FIELD_TYPE.NUMBER,
    title: "Add Phone Number"
  },
  {
    pdfFieldType: PDF_FIELD_TYPE.QR_CODE,
    title: "Add QR Code"
  },
  {
    pdfFieldType: PDF_FIELD_TYPE.TEXT_FIELD,
    title: "Add Text Field"
  }
];

export const FORM_TEMPLATE_CONSTANTS = {
  FORM_SETTINGS: "FORM_SETTINGS",
  SHOW_FORM: "SHOW_FORM",
  FORM_DATA_MOBILE: "FORM_DATA_MOBILE",
  FORM_DATA_WEB: "FORM_DATA_WEB"
};

export const FILLED_OPTIONS = {
  IS_EQUALS: "is_equals",
  CONTAINS: "contains",
  NOT_EQUALS: "not_equals",
  IS_FILLED: "filled"
};

export const VALIDATION_CONSTANTS = {
  EMAIL: "EMAIL",
  NUMBER: "NUMBER",
  DATE: "DATE",
  PHONE: "PHONE",
  ZIP: "ZIP",
  DATE_RANGE: "DATE_RANGE",
  SSN: "SSN"
};

export const CHILD_TYPE = [DRAG_TYPE.RADIO, DRAG_TYPE.CHECKBOX, DRAG_TYPE.DROP_DOWN];

export const PAYLOAD_KEY = {
  OFFSET: "Pagination.Offset",
  COUNT: "Pagination.Count",
  SORT_NAME: "sort.name",
  SORT_ORDERBY: "sort.orderBy"
};

export const STANDARD_FIELD_CATEGORIES = {
  PERSONAL_INFORMATION: 275,
  EEO_INFORMATION: 282
};

export enum FORM_PREVIEW_KEYS {
  WEB_PREVIEW = 1,
  MOBILE_PREVIEW = 2
}

export enum LOCATION_STATUS {
  ACTIVE = 1,
  INACTIVE = 2
}

export type translationType = {
  [key: string]: string | null;
};
export type optionTypes = {
  label: string;
  value: string;
};

export enum PDF_PAGES_MOVEMENT {
  BACKWARD = 1,
  FORWARD = 2
}

export type DeleteImageType = {
  id: number;
};

type Role = {
  name: string;
  isSelected: boolean;
};

export type Permission = {
  id: number;
  label: string;
  value: string;
  roles: Role[];
};

export const LINKEDIN_ERRORS = {
  USER_CANCELLED_LOGIN: "user_cancelled_login"
};
