import { QueryClient } from "@tanstack/react-query";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
// import { getNextPageParam } from "./util/utils.service"; ** Fix this

export const STALE_TIME = 5 * 60 * 1000; // 5 minutes
// export const STALE_TIME = 1 * 1000; // 1 second
export const CACHE_TIME = 10 * 24 * 60 * 60 * 1000; // 2 days
export const MAX_AGE = 10 * 24 * 60 * 60 * 1000; // 2 days

// type pageParams = { ** Don't Remove
//   lastPage: {
//     totalRecords: number;

//   };
//   allPages: {
//     length: number;
//   };
// }
// type lastPageParams = { ** Don't Remove
//   totalRecords: number;
// };
// type allPageParams = {
//   length: number;
// };

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: STALE_TIME,
      cacheTime: CACHE_TIME,
      retry: false,
      refetchOnWindowFocus: false,
      // getNextPageParam: (lastPage, allPages) => ** Fix this
      //   getNextPageParam(lastPage, allPages),
      onError: (e) => {
        console.log("api error ", e);
      }
    },
    mutations: {
      onError: (e) => {
        console.log("mutation api error ", e);
      }
    }
  }
});

const storage = createSyncStoragePersister({
  storage: window.localStorage,
  key: "my-app-query-client"
});

const persistOptions = {
  disabled: false,
  maxAge: MAX_AGE,
  persister: storage
};

const ApiClientProvider = (props: React.PropsWithChildren) => {
  const { children } = props;

  return (
    <PersistQueryClientProvider client={queryClient} persistOptions={persistOptions}>
      {children}
    </PersistQueryClientProvider>
  );
};

export default ApiClientProvider;

