import ApiService from "../util/api.service";
import { API_CONFIG_URLS } from "../constants/config";
import { addDepartmentPayload, listingPayloadType, queryParameter } from "../types";
import { utilService } from "../util/utils.service";

const DepartmentApiService = {
  getListing,
  addDepartment,
  updateDepartment,
  deleteDepartment
};

async function getListing(payload: listingPayloadType) {
  const url = utilService.createDynamicUrl(API_CONFIG_URLS.DEPARTMENT.LISTING, payload);
  const response = await ApiService.get(url);
  return response;
}

async function addDepartment(payload: addDepartmentPayload) {
  const response = await ApiService.post(API_CONFIG_URLS.DEPARTMENT.DEPARTMENT, payload);
  return response;
}

async function updateDepartment(payload: addDepartmentPayload, id: queryParameter) {
  const url = utilService.createDynamicUrl(API_CONFIG_URLS.DEPARTMENT.UPDATE_DEPARTMENT, id);
  const response = await ApiService.put(url, payload);
  return response;
}

async function deleteDepartment(id: queryParameter) {
  const url = utilService.createDynamicUrl(API_CONFIG_URLS.DEPARTMENT.UPDATE_DEPARTMENT, id);
  const response = await ApiService.remove(url);
  return response;
}

export default DepartmentApiService;
