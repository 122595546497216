import React, { useState } from "react";
import { Badge, Dropdown, Image, Spin, Switch } from "antd";

// import { ReactComponent as NotificationIcon } from "../../assets/icons/NotificationIcon.svg";
import styles from "./Notification.module.scss";
import NotificationIcon from "../../assets/icons/NotificationIcon.svg";
interface propParams {
  isFetching?: boolean;
  unReadCount?: number;
  data: Array<NotificationResponseType>;
}

type NotificationResponseType = {
  description: string;
  createdAt: string;
  type: number;
  id: number;
};

const NotificationBell: React.FC<propParams> = ({ data = [], isFetching, unReadCount = 2 }) => {
  const [toggleState, setToggleState] = useState<boolean>(false);

  const handleNotificationRedirection = (type: number, id: number) => {
    console.log(type, id);
  };

  const renderDropDownContent = () => {
    return (
      <div className={`${styles["dropdown-menu"]}`}>
        <div className="d-flex-row justify-sb hr-mb-22">
          <h4 className="text-primary poppins-medium">Notifications</h4>
          <Switch checked={toggleState} className="custom-toggle" onChange={() => setToggleState((prev) => !prev)} />
        </div>

        {isFetching && !data.length && <Spin />}

        {!isFetching && !data.length && (
          <div className={`${styles["dropdown-menu-item"]}`}>
            <p className="font-14">No new notification available</p>
          </div>
        )}

        {data.map((item: NotificationResponseType, index: number) => {
          const { type, id } = item;
          return (
            <div
              key={index}
              onClick={() => handleNotificationRedirection(type, id)}
              className={`${styles["dropdown-menu-item"]} hr-mb-15`}
            >
              <p className="font-14">{item?.description}</p>
              <p className="font-12">{item?.createdAt}</p>
            </div>
          );
        })}

        {isFetching && !!data.length && <Spin className="text-center w-100" />}
      </div>
    );
  };

  return (
    <Dropdown trigger={["click"]} placement="bottomLeft" dropdownRender={renderDropDownContent} arrow>
      <Badge className="custom-badge" dot={false} showZero={false}>
        {/* <Image preview={false} src={NotificationIcon} alt="notification" className="cursor" /> */}
      </Badge>
    </Dropdown>
  );
};

export default NotificationBell;
