import React, { useContext } from "react";
import { Button, Card, Image } from "antd";

import AuthWrapper from "../../../layouts/AuthWrapper";
import { AuthContext } from "../../../context/AuthContext";
import Success from "../../../assets/images/account-successful.png";

import styles from "./Auth.module.scss";
import { queryClient } from "../../../apiServices/client";
import { STORAGE_CONST } from "../../../constants/constant";
import { AUTHENTICATED_ROUTES } from "../../../constants/route-constant";
import { useNavigate } from "react-router";

type dataType = {
  token: string;
};
interface propParams {
  data: dataType;
}

const AccountSuccess: React.FC<propParams> = ({ data }) => {
  const [, dispatch] = useContext(AuthContext);
  const navigate =useNavigate();
  const handleClick = (goToDashboard: boolean) => {
    !goToDashboard &&
      dispatch({
        type: "LOGIN_TO_JOB_OPENING"
      });
    queryClient.setQueryData([STORAGE_CONST.TOKEN], data.token);
      if(!goToDashboard){
        setTimeout(()=>{
          navigate(AUTHENTICATED_ROUTES.CREATE_JOB, { state: { fromJobsOpening: true } })
        },500)
      }
    
  };

  return (
    <AuthWrapper>
      <Card className={`${styles["card-container"]}`}>
        <div className={`${styles["account-container"]}`}>
          <div className={`hr-mb-25 ${styles["img-success"]}`}>
            <Image preview={false} src={Success} />
          </div>
          <h1 className={`hr-mb-40 ${styles["heading-input"]}`}>Account Created Successfully</h1>

          <p className={`hr-mb-20 ${styles["para-success"]}`}>
            With our simple mobile forms, our clients receive candidate applications quickly. Using our easy-to-use
            dashboard, clients easily sort through applications and hire the right candidate for the position
          </p>
          <p className={`hr-mb-20 ${styles["para-success"]}`}>Do you want to create a requisition?</p>

          <Button
            onClick={() => handleClick(false)}
            className={`${styles["btn-auth"]}`}
            type="primary"
            htmlType="submit"
          >
            Yes
          </Button>
          <p onClick={() => handleClick(true)} className={`${styles["back-to"]}`}>
            Go to dashboard
          </p>
        </div>
      </Card>
    </AuthWrapper>
  );
};

export default AccountSuccess;
