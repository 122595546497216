import ApiService from "../util/api.service";
import { utilService } from "../util/utils.service";
import { API_CONFIG_URLS } from "../constants/config";
import {
  collateralUpdate,
  addEditUserPayloadType,
  editPdfState,
  listingPayloadType,
  locationType,
  pdfListItem,
  pdfStateType,
  updateStatusPayloadType
} from "../types";

const LocationApiService = {
  getListing,
  deleteLocation,
  getUserList,
  addEditDeleteUser,
  addCompanyLocation,
  updateCompanyLocation,
  getCompanyLocation,
  updateLocationStatus,
  getRecruitmentList,
  getRecruitment,
  updateRecruitment,
  addRecruitment,
  deleteRecruitment
};

async function getListing(payload: listingPayloadType) {
  const url = utilService.createDynamicUrl(API_CONFIG_URLS.LOCATION.LISTING, payload);
  const response = await ApiService.get(url);
  return response;
}

async function deleteLocation(id: { id: number }) {
  const url = utilService.createDynamicUrl(API_CONFIG_URLS.LOCATION.DELETE_LOCATION, id);
  const response = await ApiService.remove(url);
  return response;
}

async function getUserList(payload: listingPayloadType) {
  const url = utilService.createDynamicUrl(API_CONFIG_URLS.LOCATION.USER_LIST, payload);
  const response = await ApiService.get(url);
  return response;
}

async function addEditDeleteUser(payload: addEditUserPayloadType) {
  const url = utilService.createDynamicUrl(API_CONFIG_URLS.LOCATION.ADD_EDIT_DELETE_USER, { id: payload.id });
  const response = await ApiService.put(url, payload.body);
  return response;
}

async function addCompanyLocation(payload: locationType) {
  const response = await ApiService.post(API_CONFIG_URLS.LOCATION.ADD_COMPANY_LOCATION, payload);
  return response;
}

async function updateCompanyLocation(payload: locationType, id: number | undefined) {
  const url = utilService.createDynamicUrl(API_CONFIG_URLS.LOCATION.UPDATE_COMPANY_LOCATION, { id });
  const response = await ApiService.put(url, payload);
  return response;
}

async function getCompanyLocation(id: number | undefined) {
  const url = utilService.createDynamicUrl(API_CONFIG_URLS.LOCATION.LOCATION_DETAILS, { id });
  const response = await ApiService.get(url);
  return response;
}

async function updateLocationStatus(payload: updateStatusPayloadType) {
  const url = utilService.createDynamicUrl(API_CONFIG_URLS.LOCATION.UPDATE_LOCATION_STATUS, { id: payload.id });
  const response = await ApiService.put(url, payload.body);
  return response;
}

async function getRecruitmentList(payload: { companyId: number | undefined }) {
  const result = await ApiService.get(API_CONFIG_URLS.LOCATION.RECRUITMENT_LIST, payload);
  const { ok, response, data } = result;
  if (ok) {
    return data;
  }
  throw response;
}

async function getRecruitment(payload: { recruitmentCollateralId: number |undefined }) {
  const url = utilService.createDynamicUrl(API_CONFIG_URLS.LOCATION.RECRUITMENT_BY_ID, payload);
  const result = await ApiService.get(url);
  const { ok, response, data } = result;
  if (ok) {
    return data;
  }
  throw response?.message;
}

async function updateRecruitment(payload: collateralUpdate, recruitmentCollateralId: number) {
  const url = utilService.createDynamicUrl(API_CONFIG_URLS.LOCATION.RECRUITMENT_BY_ID, { recruitmentCollateralId });
  const result = await ApiService.put(url, payload);
  const { ok, response, data } = result;
  if (ok) {
    return data;
  }
  throw response?.message;
}

async function deleteRecruitment(recruitmentCollateralId: number | undefined) {
  const url = utilService.createDynamicUrl(API_CONFIG_URLS.LOCATION.RECRUITMENT_BY_ID, { recruitmentCollateralId });
  const result = await ApiService.remove(url);
  const { ok, response, data } = result;
  if (ok) {
    return data;
  }
  throw response?.message;
}

async function addRecruitment(payload: collateralUpdate) {
  const url = API_CONFIG_URLS.LOCATION.RECRUITMENT_ADD;
  const result = await ApiService.post(url, payload);
  const { ok, response, data } = result;
  if (ok) {
    return data;
  }
  throw response?.message;
}

export default LocationApiService;
