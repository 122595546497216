import "./assets/fonts/styles.scss";
import "./styles/app-style.scss";
import "./styles/utils.scss";

import { DndProvider } from "react-dnd";
import { BrowserRouter } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { HTML5Backend } from "react-dnd-html5-backend";

import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import weekYear from "dayjs/plugin/weekYear";
import localeData from "dayjs/plugin/localeData";
import weekOfYear from "dayjs/plugin/weekOfYear";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";

import MainApp from "./containers/Pages/App";
import { useQuery } from "@tanstack/react-query";
import ApiClientProvider from "./apiServices/client";
import { STORAGE_CONST } from "./constants/constant";
import FullPageLoader from "./components/FullPageLoader";
import { AuthContextProvider } from "./context/AuthContext";
import UnAuthenticatedRoutes from "./routes/UnAuthenticatedRoutes";

dayjs.extend(weekday);
dayjs.extend(weekYear);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);

const RegisterRoutes = () => {
  // In React Query v4, if you set the enabled option to false, the query's isLoading property will always be true.
  // This behavior is documented and may necessitate the use of additional state to show a loader during hydration..
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { data } = useQuery([STORAGE_CONST.TOKEN], () => {}, { enabled: false });

  useEffect(() => {
    setTimeout(() => setIsLoading(false), 500);
  }, []);

  if (isLoading) {
    return <FullPageLoader />;
  }

  if (data) {
    return (
      <React.Suspense fallback={<FullPageLoader />}>
        <MainApp />
      </React.Suspense>
    );
  } else {
    return <UnAuthenticatedRoutes />;
  }
};

const App = () => {
  return (
    <BrowserRouter>
      <DndProvider backend={HTML5Backend}>
        <AuthContextProvider>
          <ApiClientProvider>
            <RegisterRoutes />
          </ApiClientProvider>
        </AuthContextProvider>
      </DndProvider>
    </BrowserRouter>
  );
};

export default App;
