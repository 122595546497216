import { Upload, Form } from "antd";
import { FilePdfOutlined, CloseOutlined, FileWordOutlined } from "@ant-design/icons";

import { CustomButton } from "../../../../components/Button";
import { dummyRequest, utilService } from "./../../../../util/utils.service";
import { wordFormats, supportedFileFormat } from "../../../../constants/constant";
import notificationService from "../../../../util/notification.service";

import "./ExternalForm.scss";

const ResumeSection = ({
  file = {},
  setFile,
  loading = false,
  setFieldValue,
  resumeUploadText,
  uploadBtnText,
  required = false,
  validationMessage
}: {
  file: any;
  setFile: any;
  loading: boolean;
  setFieldValue: any;
  resumeUploadText: string;
  uploadBtnText: string;
  required: Boolean;
  validationMessage: string;
}) => {
  const onFileRemove = (_file: any) => {
    return new Promise<boolean>((resolve, reject) => {
      setFieldValue("resume", undefined);
      setFile({});
      resolve(_file);
    });
  };
  const uploadOptions = {
    showUploadList: false,
    accept: ".pdf, .docx, .doc",
    // listType: "picture",
    customRequest: dummyRequest,
    maxCount: 1,
    beforeUpload(file: any) {
      if (![...supportedFileFormat, ...wordFormats].includes(file.type)) {
        return Promise.reject();
      } else {
        const fileSizeLimit = file.size / 1024 / 1024 < 2;
        if (!fileSizeLimit) {
          notificationService.error(`File must be smaller than ${2}MB!`);
          return Promise.reject();
        }
      }
    },
    onChange: (info: any) => {
      let value = info.fileList.filter((file: any) => !!file.status);
      value = value?.find((fileInfo: any) => true)?.originFileObj;
      setFile(value);
    },
    onFileRemove: onFileRemove
  };
  const getFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  return (
    <div className="hr-mt-20">
      <h5 className="poppins-semibold ">{resumeUploadText}</h5>
      <div className="hr-mt-20">
        <Form.Item
          name="resume"
          getValueFromEvent={getFile}
          rules={[{ required: required as boolean, message: validationMessage }]}
        >
          <Upload className="upload-file" {...uploadOptions} disabled={loading}>
            <CustomButton
              htmlType="button"
              title={uploadBtnText}
              btnColor="white"
              className=" hr-fs-14 poppins-normal hr-mr-10"
              disabled={loading}
            />
          </Upload>
        </Form.Item>
      </div>
      {!utilService.isEmpty(file) && (
        <div className="hr-mt-20  upload-resume-list">
          {wordFormats.includes(file?.type) ? (
            <FileWordOutlined className="hr-fs-22" />
          ) : (
            <FilePdfOutlined className="hr-fs-22" />
          )}

          <p className="hr-fs-14 hr-ml-10 hr-mr-10">{file?.name}</p>
          <CloseOutlined className="cursor hr-fs-14" onClick={onFileRemove} />
        </div>
      )}
      <div></div>
    </div>
  );
};

export default ResumeSection;
