import { Card, Checkbox, Col, Form, Row } from "antd";
import { getLabelTranslation } from "../../../../util/utils.service";
import { ExternalListingsLoader } from "./ExternalListingsLoader";

function NearbyLocationsListing(props: any) {
  return (
    <Row className="w-100 hr-mt-20" justify={"center"}>
      <Col xs={24} lg={12} className="hr-mt-5">
        <h3 className="poppins-bold">
          {getLabelTranslation("Nearby Locations", ["text", "otherLocations"], props.translation)}
        </h3>

          {props?.isLoading ? (
            <ExternalListingsLoader rows={3} size="large" />
          ) : (
            <div className="jobs-scroll-view hr-mt-5">
            <Form.Item name={"jobLocationIds"} noStyle>
              <Checkbox.Group className="w-100">
                {props?.data?.slice(1).map((location: any) => {
                  return (
                    <Card className="hr-mb-10 " key={location?.companyLocationId}>
                      <Checkbox value={location?.companyLocationId}>
                        <h4 className="poppins-medium">{location?.address}</h4>
                      </Checkbox>
                    </Card>
                  );
                })}
              </Checkbox.Group>
            </Form.Item>
            </div>
          )}
      </Col>
    </Row>
  );
}

export default NearbyLocationsListing;
