import { useMutation, useQuery } from "@tanstack/react-query";

import notificationService from "./../../../../util/notification.service";
import FormTemplateApiService from "./../../../../apiServices/formTemplate-service";
import { queryClient } from "../../../../apiServices/client";
import { sessionPayload } from "../../../../types/JobOpening/candidateTypes";
import { addCandidatePayload } from "../../../../types";
import { CandidateApiService, LocationApiService, MediaApiService } from "./../../../../apiServices";

export const formQueryKeys = {
  locationsSearch: (payload: any) => ["LOOKUP_LOCATIONS_BY_SEARCH", payload],
  formTemplate: (id: any) => ["FORM_TEMPLATE", id],
  companyLocation: (id: any) => ["COMPANY_LOCATION", id]
};

export const useGetExternalFormTemplate = (id: any, query: any) => {
  return useQuery(
    formQueryKeys.formTemplate(id),
    async () => {
      const { data, response, ok } = await FormTemplateApiService.getExternalFormTemplate(id, query);
      if (ok) {
        return data;
      }
      notificationService.error(response?.message);
      throw response.message;
    },
    {
      enabled: Boolean(id),
      onError: (err: any) => {
        throw err;
      }
    }
  );
};

export const useUploadAttachment = () => {
  return useMutation(async (payload: any) => {
    const { ok, response, data } = await MediaApiService.uploadAttachment(payload);
    if (ok) {
      return data;
    }
    notificationService.error(response.message);
    throw response.message;
  });
};

export const useAddCandidate = (onSuccess?: any) => {
  return useMutation(async (payload: addCandidatePayload) => {
    const { ok, response, data } = await CandidateApiService.addCandidate(payload);
    if (ok) {
      queryClient.invalidateQueries(["CANDIDATE_QUERY_LISTING"]);
      onSuccess();
      return data;
    }
    notificationService.error(response.message);
    throw response.message;
  });
};

export const useGetCompanyLocation = (id: any) => {
  return useQuery(
    formQueryKeys.companyLocation(id),
    async () => {
      const { data, response, ok } = await LocationApiService.getCompanyLocation(id);
      if (ok) {
        return data;
      }
      notificationService.error(response?.message);
      throw response.message;
    },
    {
      enabled: Boolean(id),
      onError: (err: any) => {
        throw err;
      },
      staleTime: 0
    }
  );
};

export const useCandidateSession = () => {
  return useMutation((payload: sessionPayload) => CandidateApiService.updateSession(payload));
};

export const useUploadSignature = () => {
  return useMutation(async (payload: any) => {
    const { ok, response, data } = await MediaApiService.uploadSignature(payload);
    if (ok) {
      return data;
    }
    notificationService.error(response.message);
    throw response.message;
  });
};
