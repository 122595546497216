import { API_CONFIG_URLS } from "../constants/config";
import { updateStatusPayloadType } from "../types";

import { addLanguagePayloadType } from "../types/Language/languageType";


import ApiService from "../util/api.service";
import { utilService } from "../util/utils.service";

const LocalizationApiService = {
  getListing,
  getLanguage,
  addLanguage,
  deleteLanguage,
  downloadLanguage,
  updateLanguageStatus,
  updateLanguageDefault,
  updateLanguage
};
async function getListing(payload: any) {
  const url = utilService.createDynamicUrl(API_CONFIG_URLS.LANGUAGE.LISTING, payload);
  const response = await ApiService.get(url);
  return response;
}

async function getLanguage(id: number | undefined, companyId: number | undefined) {
  const url = utilService.createDynamicUrl(API_CONFIG_URLS.LANGUAGE.GET_LANGUAGE, { id });
  const response = await ApiService.get(url, { companyId });
  return response;
}

async function addLanguage(payload: addLanguagePayloadType) {
  const response = await ApiService.post(API_CONFIG_URLS.LANGUAGE.ADD_LANGUAGE, payload);
  return response;
}

async function updateLanguageStatus(payload: updateStatusPayloadType) {
  const url = utilService.createDynamicUrl(API_CONFIG_URLS.LANGUAGE.UPDATE_STATUS, { id: payload.id });
  const response = await ApiService.put(url, payload.body);
  return response;
}

async function updateLanguageDefault(payload: {id:number}) {
  const url = utilService.createDynamicUrl(API_CONFIG_URLS.LANGUAGE.UPDATE_DEFAULT, { id: payload });
  const response = await ApiService.put(url);
  return response;
}
async function deleteLanguage(payload: { id: number }) {
  const url = utilService.createDynamicUrl(API_CONFIG_URLS.LANGUAGE.DELETE_LANGUAGE, { id: payload });
  const response = await ApiService.remove(url);
  return response;
}

async function downloadLanguage(payload: any) {
  const url =  utilService.createDynamicUrl(API_CONFIG_URLS.LANGUAGE.LANGUAGE_DOWNLOAD, {id:payload.id, companyId:payload.companyId});
  const response = await ApiService.get(url)
  return response;
}


async function updateLanguage(payload: addLanguagePayloadType, id: number | undefined) {
  const url = utilService.createDynamicUrl(API_CONFIG_URLS.LANGUAGE.GET_LANGUAGE, { id });
  const response = await ApiService.put(url, payload);
  return response;
}
export default LocalizationApiService;

