import { queryClient } from "../apiServices/client";
import { STORAGE_CONST } from "../constants/constant";
import { PermissionConstants } from "../constants/permissionConstants";
import { AUTHENTICATED_ROUTES, UNAUTHENTICATED_ROUTES } from "../constants/route-constant";

export const permissionBasedRoutes = {
  [AUTHENTICATED_ROUTES.CREATE_JOB]: PermissionConstants.OPEN_REQUISITION,
  [AUTHENTICATED_ROUTES.UPDATE_JOB_OPENING]: PermissionConstants.EDIT_REQUISITION,
  [AUTHENTICATED_ROUTES.JOB_CREATION_SUCCESS]: PermissionConstants.OPEN_REQUISITION,
  [AUTHENTICATED_ROUTES.JOB_OPENING]: PermissionConstants.VIEW_REQUISITION_HISTORY,
  [AUTHENTICATED_ROUTES.JOB_DETAILS]: PermissionConstants.VIEW_REQUISITION_HISTORY,
  [AUTHENTICATED_ROUTES.JOB_CANDIDATE_DETAILS]: PermissionConstants.VIEW_REQUISITION_HISTORY,
  [AUTHENTICATED_ROUTES.DEPARTMENTS]: PermissionConstants.MANAGE_DEPARTMENTS,
  [AUTHENTICATED_ROUTES.FORMS]: PermissionConstants.MANAGE_FORMS,
  [AUTHENTICATED_ROUTES.ADD_FORM_TEMPLATES]: PermissionConstants.MANAGE_FORMS,
  [AUTHENTICATED_ROUTES.EDIT_FORM_TEMPLATES]: PermissionConstants.MANAGE_FORMS,
  [AUTHENTICATED_ROUTES.LOCATIONS]: PermissionConstants.MANAGE_LOCATIONS,
  [AUTHENTICATED_ROUTES.HIRING_TEAM]: PermissionConstants.MANAGE_HIRING_TEAM,
  [AUTHENTICATED_ROUTES.SETTINGS]: PermissionConstants.EDIT_CUSTOMER,
  [AUTHENTICATED_ROUTES.APPLICATION_REPORT]: PermissionConstants.REPORT_VIEW,
  [AUTHENTICATED_ROUTES.APPLICANT_HIRED_REPORT]: PermissionConstants.REPORT_VIEW,
  [AUTHENTICATED_ROUTES.RECRUITING_ACTIVITY_REPORT]: PermissionConstants.REPORT_VIEW,
  [AUTHENTICATED_ROUTES.USER_LOCATIONS_REPORT]: PermissionConstants.REPORT_VIEW,
  [AUTHENTICATED_ROUTES.EEO_REPORT_1]: PermissionConstants.REPORT_VIEW,
  [AUTHENTICATED_ROUTES.EEO_REPORT_2]: PermissionConstants.REPORT_VIEW,
  [AUTHENTICATED_ROUTES.APPLICANT_SOURCES_REPORT]: PermissionConstants.REPORT_VIEW,
  [AUTHENTICATED_ROUTES.LOGINS_REPORT]: PermissionConstants.REPORT_VIEW,
  [AUTHENTICATED_ROUTES.APPLICANT_FIELDS_REPORT]: PermissionConstants.REPORT_VIEW,
  [AUTHENTICATED_ROUTES.LOCATION_STATS_REPORT]: PermissionConstants.REPORT_VIEW,

  [AUTHENTICATED_ROUTES.LOCALIZATION]: PermissionConstants.MANAGE_APPLICATION_LANGUAGES,
  [AUTHENTICATED_ROUTES.ADD_LOCALIZATION]: PermissionConstants.MANAGE_APPLICATION_LANGUAGES,
  [AUTHENTICATED_ROUTES.WORKFLOWS]: PermissionConstants.CREATE_JOB_PAGES,
  [AUTHENTICATED_ROUTES.ADD_WORKFLOW]: PermissionConstants.CREATE_JOB_PAGES,
  [AUTHENTICATED_ROUTES.EDIT_WORKFLOW]: PermissionConstants.CREATE_JOB_PAGES
};

export const routesWithoutPermissions = [
  AUTHENTICATED_ROUTES.EXTERNAL_FORM,
  AUTHENTICATED_ROUTES.EXTERNAL_JOB_LOCATION,
  AUTHENTICATED_ROUTES.JOB_LOCATION_DETAILS,
  AUTHENTICATED_ROUTES.PROFILE,
  AUTHENTICATED_ROUTES.CHANGE_PASSWORD,
  AUTHENTICATED_ROUTES.EDIT_PROFILE,
  AUTHENTICATED_ROUTES.FORM_PREVIEW,
  UNAUTHENTICATED_ROUTES.THANKYOU_PAGE
];

export type userPermissionsType = {
  permissions: Array<string>;
};

export const disableDropdown = (canSet: boolean, canEdit: boolean, condition: boolean) => {
  if (canSet && !canEdit && condition) return false;
  else if (!canSet && !canEdit) return true;
  else return false;
};

export const hasPermission = (permission: string) => {
  const userPermissions: userPermissionsType | undefined = queryClient.getQueryData([STORAGE_CONST.USER_PERMISSIONS]);
  const { permissions } = userPermissions || { permissions: [] };

  return permissions?.includes(permission);
};
