import PublicRoute from "./PublicRoute";
import Login from "../containers/Pages/AuthPages/Login";
import SignUp from "../containers/Pages/AuthPages/SignUp";
import { Routes, Route, Navigate } from "react-router-dom";
import SetPassword from "../containers/Pages/AuthPages/SetPassword";
import ResetPassword from "../containers/Pages/AuthPages/ResetPassword";
import ForgotPassword from "../containers/Pages/AuthPages/ForgotPassword";
import ExternalForm from "../containers/Pages/DashboardPages/ExternalForm";
import ExternalJobLocation from "../containers/Pages/DashboardPages/ExternalJobLocation";
import ThankyouPage from "../containers/Pages/DashboardPages/ThankyouPage";
import { AUTHENTICATED_ROUTES, UNAUTHENTICATED_ROUTES } from "../constants/route-constant";

const UnAuthenticatedRoutes = () => {
  return (
    <Routes>
      <Route
        path={UNAUTHENTICATED_ROUTES.LOGIN}
        element={
          <PublicRoute title="Login">
            <Login />
          </PublicRoute>
        }
      />
      <Route
        path={UNAUTHENTICATED_ROUTES.SIGN_UP}
        element={
          <PublicRoute title="Sign Up">
            <SignUp />
          </PublicRoute>
        }
      />
      <Route
        path={UNAUTHENTICATED_ROUTES.RESET_PASSWORD}
        element={
          <PublicRoute title="Reset Password">
            <ResetPassword />
          </PublicRoute>
        }
      />
      <Route
        path={UNAUTHENTICATED_ROUTES.SET_PASSWORD}
        element={
          <PublicRoute title="Set Password">
            <SetPassword />
          </PublicRoute>
        }
      />
      <Route
        path={UNAUTHENTICATED_ROUTES.FORGOT_PASSWORD}
        element={
          <PublicRoute title="Forgot Password">
            <ForgotPassword />
          </PublicRoute>
        }
      />
      <Route
        path={AUTHENTICATED_ROUTES.EXTERNAL_FORM}
        element={
          <PublicRoute title="Form">
            <ExternalForm />
          </PublicRoute>
        }
      />
      <Route
        path={AUTHENTICATED_ROUTES.EXTERNAL_JOB_LOCATION}
        element={
          <PublicRoute title="External Job Location">
            <ExternalJobLocation />
          </PublicRoute>
        }
      />
      <Route
        path={UNAUTHENTICATED_ROUTES.THANKYOU_PAGE}
        element={
          <PublicRoute title="Thankyou">
            <ThankyouPage />
          </PublicRoute>
        }
      />
      <Route
        path="*"
        element={
          <PublicRoute title="Login">
            <Navigate replace to={UNAUTHENTICATED_ROUTES.LOGIN} />
          </PublicRoute>
        }
      />
    </Routes>
  );
};

export default UnAuthenticatedRoutes;
