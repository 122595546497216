import React, { useReducer, createContext } from "react";

export const AuthContext = createContext();

const initialState = {
  isLogin: false,
  redirectJobOpening: false
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        isLogin: true
      };
    case "LOGIN_TO_JOB_OPENING":
      return {
        ...state,
        isLogin: true,
        redirectJobOpening: true
      };
    case "LOGOUT":
      return {
        ...state,
        isLogin: false,
        redirectJobOpening: false
      };

    default:
      throw new Error();
  }
};

export const AuthContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <AuthContext.Provider value={[state, dispatch]}>{props.children}</AuthContext.Provider>;
};
