import React from "react";
import { Input } from "antd";

import Float from "../FloatLabel";
import { ReactComponent as EyeOpen } from "../../assets/icons/eye-open.svg";
import { ReactComponent as EyeClose } from "../../assets/icons/eye-close.svg";

type CustomPasswordParams = {
  label: string;
  type?: string;
  value?: string;
  required?: boolean;
};

const CustomPassword = ({ label, type, value = "", ...rest }: CustomPasswordParams) => {
  const inputRef = React.useRef<any>(null);
  const handleInputFocus = () => {
    inputRef.current.focus();
  };

  return (
    <Float label={label} value={value} onClick={handleInputFocus}>
      <Input.Password
        ref={inputRef}
        {...rest}
        className="cursor pad-left"
        iconRender={(visible) => (visible ? <EyeOpen /> : <EyeClose />)}
      />
    </Float>
  );
};

export default CustomPassword;
