import { useState } from "react";
import { PAGE_SIZE, PAYLOAD_KEY } from "../constants/constant";

type gridPropType = {
  jobOpeningId?: number | null;
  companyId?: number;
  companyLocationId?: number | null;
  status?: number;
  candidateId?: number;
  pagination?: paginationObjType;
  searchBy?: string;
  sort?: sortObjType;
  pageSize?: number;
  filters?: any[];
  [key: string]: any;
};

export type gridRequestType = {
  jobOpeningId?: number | null;
  companyId: number | null;
  companyLocationId: number | null;
  status: number | null;
  candidateId: number | null;
  pagination: paginationObjType;
  searchBy: string;
  sort: sortObjType;
  filters: any[];
  currentPage: number;
  [key: string]: any;
};

type paginationObjType = {
  offset: number;
  count: number;
};

type sortObjType = {
  name: string;
  orderBy: string;
};

const useGridRequest = (props?: gridPropType) => {
  const INITIAL_VALUES = {
    companyId: props?.companyId ?? null,
    companyLocationId: props?.companyLocationId ?? null,
    status: props?.status ?? null,
    candidateId: props?.candidateId ?? null,
    pagination: props?.pagination ?? { offset: 0, count: props?.pageSize ?? PAGE_SIZE },
    [PAYLOAD_KEY.OFFSET]: 0,
    [PAYLOAD_KEY.COUNT]: props?.pageSize ?? PAGE_SIZE,
    searchBy: props?.searchBy ?? "",
    sort: props?.sort ?? { name: "", orderBy: "" },
    filters: props?.filters ?? [],
    currentPage: 1,
    ...props
  };

  const [gridRequest, setGridRequest] = useState(INITIAL_VALUES);

  const onSearch = (searchValue: string) => {
    const request: gridRequestType = { ...gridRequest, searchBy: searchValue, currentPage: 1 };
    request.pagination.offset = 0;
    setGridRequest(request);
  };

  const onPagination = (offset: number, count: number) => {
    const request: gridRequestType = {
      ...gridRequest,
      pagination: { offset: offset, count: count },
      [PAYLOAD_KEY.OFFSET]: offset,
      [PAYLOAD_KEY.COUNT]: count
    };
    setGridRequest(request);
  };

  const onSort = (columName: string, orderBy: string) => {
    const request: gridRequestType = { ...gridRequest, sort: { name: columName, orderBy: orderBy } };
    request.pagination.offset = 0;
    setGridRequest(request);
  };

  const clearSort = () => {
    const request: gridRequestType = { ...gridRequest, sort: { name: "", orderBy: "" } };
    request.pagination.offset = 0;
    setGridRequest(request);
  };

  const onFilter = (key: string, value: string | number, optionalPayload = {}) => {
    let request: gridRequestType = { ...gridRequest, ...optionalPayload };
    request[key] = value;
    request.pagination.offset = 0;
    setGridRequest(request);
  };
  const onMultiFilters = (obj: any, optionalPayload = {}) => {
    let request: gridRequestType = { ...gridRequest, ...optionalPayload };
    for (const key in obj) {
      request[key] = obj[key];
    }

    request.pagination.offset = 0;
    request[PAYLOAD_KEY.OFFSET] = 0;
    setGridRequest(request);
  };

  const removeFilter = (key: string) => {
    let request: gridRequestType = { ...gridRequest };
    request[key] = null;
    request.pagination.offset = 0;
    setGridRequest(request);
  };

  const clearFilters = () => {
    const request: gridRequestType = { ...gridRequest, filters: [] };
    request.pagination.offset = 0;
    setGridRequest(request);
  };

  const resetPayload = () => {
    const request: gridRequestType = { ...gridRequest, ...INITIAL_VALUES };
    setGridRequest(request);
  };

  const getNormalizeRequest = (props: any) => {
    const { pagination, searchBy, filters, sort, ...rest } = gridRequest;

    const payload = {
      [PAYLOAD_KEY.OFFSET]: pagination.offset,
      [PAYLOAD_KEY.COUNT]: pagination.count,
      searchBy: searchBy,
      [PAYLOAD_KEY.SORT_NAME]: sort.name,
      [PAYLOAD_KEY.SORT_ORDERBY]: sort.orderBy,
      ...props,
      ...rest
    };
    return payload;
  };

  return {
    gridRequest,
    onSearch,
    onFilter,
    onMultiFilters,
    onPagination,
    onSort,
    clearFilters,
    removeFilter,
    clearSort,
    resetPayload,
    setGridRequest,
    getNormalizeRequest
  };
};

export default useGridRequest;
