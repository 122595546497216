import { Modal } from "antd";
import React, { FC, ReactNode } from "react";

import { ReactComponent as Close } from "../../assets/icons/close.svg";

import "./index.scss";

interface CustomModalProps {
  visible: boolean;
  title?: string | ReactNode;
  className?: string;
  children: ReactNode;
  onOk?: () => void;
  onCancel: () => void;
  footer?: any;
  closable?:boolean
}

const CustomModal: FC<CustomModalProps> = ({ visible, title, children, onOk, onCancel, className, footer = null, closable=true }) => {
  return (
    <Modal
      open={visible}
      title={title}
      onOk={onOk}
      onCancel={onCancel}
      className={`custom-modal ${className ?? ""}`}
      footer={footer}
      closeIcon={<Close />}
      centered
      destroyOnClose
      closable={closable}
    >
      {children}
    </Modal>
  );
};

export default CustomModal;
