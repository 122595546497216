import ApiService from "../util/api.service";
import { utilService } from "../util/utils.service";
import { API_CONFIG_URLS } from "../constants/config";
import { addCandidatePayload, addEditNotesPayload, listingPayloadType } from "../types";
import { sessionPayload } from "../types/JobOpening/candidateTypes";

const CandidateApiService = {
  getListing,
  updateStatus,
  addCandidate,
  getCandidate,
  addRecruiterNote,
  updateDisposition,
  transferCandidate,
  editRecruiterNotes,
  getCandidateDetails,
  getRecruiterListing,
  deleteRecruiterNotes,
  updateSession
};

async function getListing(payload: listingPayloadType) {
  const url = utilService.createDynamicUrl(API_CONFIG_URLS.CANDIDATE.LISTING, payload);
  const response = await ApiService.get(url);
  return response;
}

async function updateStatus(candidateId: number, status: number) {
  const url = utilService.createDynamicUrl(API_CONFIG_URLS.CANDIDATE.UPDATE_STATUS, { id: candidateId });
  const response = await ApiService.put(url, { status });
  return response;
}

async function getRecruiterListing(payload: listingPayloadType) {
  const url = utilService.createDynamicUrl(API_CONFIG_URLS.CANDIDATE.RECRUITMENT_NOTE_LISTING, payload);
  const response = await ApiService.get(url);
  return response;
}

async function addRecruiterNote(payload: addEditNotesPayload) {
  const url = utilService.createDynamicUrl(API_CONFIG_URLS.CANDIDATE.ADD_RECRUITING_NOTE, payload.url);
  const response = await ApiService.post(url, payload.body);
  return response;
}

async function deleteRecruiterNotes(payload: { id: number; recruitmentNoteId: number }) {
  const url = utilService.createDynamicUrl(API_CONFIG_URLS.CANDIDATE.DELETE_RECRUITMENT_NOTE, payload);
  const response = await ApiService.remove(url);
  return response;
}

async function editRecruiterNotes(payload: addEditNotesPayload) {
  const url = utilService.createDynamicUrl(API_CONFIG_URLS.CANDIDATE.EDIT_RECRUITMENT_NOTE, payload.url);
  const response = await ApiService.put(url, payload.body);
  return response;
}

async function getCandidate(payload: { id: number }) {
  const url = utilService.createDynamicUrl(API_CONFIG_URLS.CANDIDATE.GET_CANDIDATE, payload);
  const response = await ApiService.get(url);
  return response;
}
async function addCandidate(payload: addCandidatePayload) {
  const response = await ApiService.post(API_CONFIG_URLS.CANDIDATE.ADD_CANDIDATE, payload);
  return response;
}

async function getCandidateDetails(candidateId: number | undefined) {
  const url = utilService.createDynamicUrl(API_CONFIG_URLS.CANDIDATE.DETAILS, { id: candidateId });
  const response = await ApiService.get(url);
  return response;
}

async function transferCandidate(payload: { jobOpeningLocationId: number[] }, candidateId: number | undefined) {
  const url = utilService.createDynamicUrl(API_CONFIG_URLS.CANDIDATE.TRANSFER_CANDIDATE, { id: candidateId });
  const response = await ApiService.put(url, payload);
  return response;
}
async function updateDisposition(candidateId: number | undefined, disposition: number | undefined) {
  const url = utilService.createDynamicUrl(API_CONFIG_URLS.CANDIDATE.UPDATE_DISPOSITION, { id: candidateId });
  const response = await ApiService.put(url, { disposition });
  return response;
}

async function updateSession(payload: sessionPayload) {
  const url = API_CONFIG_URLS.CANDIDATE.UPDATE_SESSION;
  const { data, response, ok } = await ApiService.post(url, payload);
  if (ok) {
    return data;
  } else throw response;
}

export default CandidateApiService;
