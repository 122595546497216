import React, { useEffect, useState } from "react";
import { Input } from "antd";

import FloatLabel from "../FloatLabel";

type FloatInputParams = {
  label: string;
  type?: string;
  value?: string;
  required?: boolean;
  placeholder?: string;
  maxWordLength?: number;
  [key: string]: any;
  tip?: string;
};

const FloatInput: React.FC<FloatInputParams> = ({
  label,
  type,
  value = "",
  required,
  placeholder,
  maxWordLength,
  tip,
  ...rest
}) => {
  const inputRef = React.useRef<any>();
  const [isFocused, setIsFocused] = useState(false);

  const handleInputFocus = () => {
    inputRef.current.focus();
  };

  return (
    <FloatLabel label={label} value={value} required={required} onClick={handleInputFocus} tip={tip}>
      <Input
        ref={inputRef}
        {...rest}
        type={type}
        value={value}
        maxLength={maxWordLength}
        onFocus={placeholder ? () => setIsFocused(true) : undefined}
        onBlur={placeholder ? () => setIsFocused(false) : undefined}
        placeholder={isFocused ? placeholder : undefined}
        className="pad-left"
      />
    </FloatLabel>
  );
};

export default FloatInput;
