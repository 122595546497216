import { useMutation, useQuery } from "@tanstack/react-query";

import notificationService from "../../../../util/notification.service";
import { queryClient } from "../../../../apiServices/client";
import { LookupApiService, WorkflowApiService } from "../../../../apiServices";
import { addWorkflowPayloadType, listingPayloadType } from "../../../../types";

export const workflowQueryKeys = {
  workflow: (id: number | undefined) => ["WORKFLOW", id],
  listing: (payload: listingPayloadType) => ["WORKFLOW_LISTING", payload],
  workflowLookup: (id: number | undefined) => ["WORKFLOW_LOOKUP", id]
};

//SELECTORS
export const workflowSelect = ({ ok, data, response }: { ok: boolean; response: any; data: any }) => {
  if (ok) {
    const mutatedData = data.map((item: any) => {
      return {
        value: item.id,
        label: item?.name,
        ...item
      };
    });
    return mutatedData;
  }
  notificationService.error(response.message);
  throw response?.message;
};

// QUERIES

export const useLookupWorkflows = (companyId?: number) => {
  return useQuery(workflowQueryKeys.workflowLookup(companyId), () => LookupApiService.getWorkFlows(companyId), {
    onError: (err) => {
      throw err;
    },
    select: workflowSelect,
    staleTime: 0
  });
};

export const useWorkflowList = (payload: listingPayloadType) => {
  return useQuery(
    workflowQueryKeys.listing(payload),
    async () => {
      const { ok, response, data } = await WorkflowApiService.getListing(payload);
      if (ok) {
        return data;
      }
      throw response?.message;
    },
    {
      onError: (err) => {
        throw err;
      }
    }
  );
};

export const useAddWorkflow = (onSuccess: any) => {
  return useMutation((payload: addWorkflowPayloadType) => WorkflowApiService.addWorkflow(payload), {
    onSuccess: ({ data, ok, response }) => {
      if (ok) {
        notificationService.success("Workflow Added Successfully");
        onSuccess();
        return data;
      } else notificationService.error(response.message);
    },
    onError: (err) => {
      throw err;
    }
  });
};

export const useRemoveWorkflow = (onSuccess: () => void) => {
  return useMutation((id: { id: number }) => WorkflowApiService.deleteWorkflow(id), {
    onSuccess: ({ ok, response, data }) => {
      if (ok) {
        onSuccess();
        queryClient.invalidateQueries(["WORKFLOW_LISTING"]);
        return data;
      }
      throw response?.message;
    },
    onError: (err: string) => {
      notificationService.error(err, "");
      throw err;
    }
  });
};

export const useEditWorkflow = (onSuccess: any, id: number) => {
  return useMutation((payload: addWorkflowPayloadType) => WorkflowApiService.editWorkflow(payload, id), {
    onSuccess: ({ data, ok, response }) => {
      if (ok) {
        notificationService.success("Workflow Updated Successfully");
        onSuccess();
        return data;
      } else notificationService.error(response.message);
    },
    onError: (err) => {
      throw err;
    }
  });
};

export const useGetExternalWorkflow = (payload: any) => {
  return useQuery(
    workflowQueryKeys.workflow(payload),
    async () => {
      const { data, response, ok } = await WorkflowApiService.getExternalWorkflow(payload);
      if (ok) {
        return data;
      }
      notificationService.error(response?.message);
      throw response.message;
    },
    {
      // enabled: Boolean(id),
      onError: (err: any) => {
        throw err;
      },
      staleTime: 0
    }
  );
};
