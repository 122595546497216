import ApiService from "../util/api.service";
import { removeProperties, utilService } from "../util/utils.service";
import { API_CONFIG_URLS } from "../constants/config";
import {
  addFormTemplateRes,
  addLocationsType,
  jobDetailsPayload,
  listingPayloadType,
  transferJobOpeningType,
  updateStatusPayloadType
} from "../types";

const JobOpeningApiService = {
  getListing,
  deleteJobOpening,
  updateJobStatus,
  addJobDetails,
  associateFormTemplate,
  transferJobOpening,
  getJobOpening,
  addLocations,
  updateJobDetails,
  updateLocations,
  getExternalList
};

async function getListing(payload: listingPayloadType) {
  const url = utilService.createDynamicUrl(API_CONFIG_URLS.JOB_OPENING.LISTING, payload);
  const response = await ApiService.get(url);
  return response;
}

async function getJobOpening(payload: { id: number }) {
  const url = utilService.createDynamicUrl(API_CONFIG_URLS.JOB_OPENING.GET_JOB_OPENING, payload);
  const response = await ApiService.get(url);
  return response;
}

async function deleteJobOpening(payload: { id: number }) {
  const url = utilService.createDynamicUrl(API_CONFIG_URLS.JOB_OPENING.DELETE_JOB_OPENING, payload);
  const response = await ApiService.remove(url);
  return response;
}

async function updateJobStatus(payload: updateStatusPayloadType) {
  const url = utilService.createDynamicUrl(API_CONFIG_URLS.JOB_OPENING.UPDATE_STATUS, { id: payload.id });
  const response = await ApiService.put(url, payload.body);
  return response;
}

async function addJobDetails(payload: jobDetailsPayload) {
  const response = await ApiService.post(API_CONFIG_URLS.JOB_OPENING.JOB, payload);
  return response;
}

async function updateJobDetails(payload: jobDetailsPayload, id: number | undefined) {
  const url = utilService.createDynamicUrl(API_CONFIG_URLS.JOB_OPENING.DELETE_JOB_OPENING, { id });
  const response = await ApiService.put(url, payload);
  return response;
}

async function associateFormTemplate(payload: addFormTemplateRes, id: number | undefined) {
  const url = utilService.createDynamicUrl(API_CONFIG_URLS.JOB_OPENING.ADD_FORM_TEMPLATE, { id });
  const response = await ApiService.post(url, payload);
  return response;
}

async function addLocations(payload: addLocationsType, id: number | undefined) {
  const url = utilService.createDynamicUrl(API_CONFIG_URLS.JOB_OPENING.ADD_LOCATIONS, { id });
  const response = await ApiService.post(url, payload);
  return response;
}

async function updateLocations(payload: addLocationsType, id: number | undefined) {
  const url = utilService.createDynamicUrl(API_CONFIG_URLS.JOB_OPENING.ADD_LOCATIONS, { id });
  const response = await ApiService.put(url, payload);
  return response;
}

async function transferJobOpening(payload: transferJobOpeningType) {
  const url = utilService.createDynamicUrl(API_CONFIG_URLS.JOB_OPENING.TRANSFER_JOB_OPENING, { id: payload.id });
  const response = await ApiService.put(url, payload.body);
  return response;
}
async function getExternalList(payload: listingPayloadType) {
  const response = await ApiService.get(
    API_CONFIG_URLS.JOB_OPENING.EXTERNAL_LIST,
    removeProperties(payload, ["candidateId", "sort", "companyId", "filters", "pagination", "searchBy", "status"])
  );
  return response;
}

export default JobOpeningApiService;

