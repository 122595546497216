import { useMutation, useQuery } from "@tanstack/react-query";

import notificationService from "../../../../util/notification.service";
import LocalizationApiService from "../../../../apiServices/Localization-services";
import { queryClient } from "../../../../apiServices/client";
import { exportToFile } from "../../../../util/utils.service";
import { LookupApiService } from "../../../../apiServices";
import { addLanguagePayloadType } from "../../../../types/Language/languageType";
import { downloadLanguageType, listingPayloadType, updateStatusPayloadType } from "../../../../types";

export const languageQueryKeys = {
  listing: (payload: listingPayloadType) => ["LANGUAGE_LIST", payload],
  language: (id: number | undefined) => ["LANGUAGE", id],
  language_lookup: (companyId: number | undefined) => ["LOOKUP", companyId]
};

//SELECTORS
export const listModify = ({ ok, data, response }: { ok: boolean; response: any; data: any }) => {
  if (ok) {
    let defaultId;
    data.list?.forEach((item: any) => {
      if (item?.name === "English") {
        defaultId = item?.id;
      }
      return item;
    });
    return {
      ...data,
      defaultId
    };
  }
  throw response?.message;
};

export const languagesSelect = ({ ok, data, response }: { ok: boolean; response: any; data: any }) => {
  if (ok) {
    let defaultLanguage = {};
    const mutatedData = data?.map((language: any) => {
      language["label"] = language.name;
      language["value"] = language.id;
      if (language.isDefault) defaultLanguage = language;
      return language;
    });

    return {
      list: mutatedData,
      defaultLanguage
    };
  }
  throw response?.message;
};

//QUERIES
export const useLocalizationList = (payload: any) => {
  return useQuery(languageQueryKeys.listing(payload), () => LocalizationApiService.getListing(payload), {
    onError: (err) => {
      throw err;
    },
    staleTime: 0,
    select: listModify
  });
};

export const useGetLanguage = (id: number | undefined, companyId: number | undefined) => {
  return useQuery(
    languageQueryKeys.language(id),
    async () => {
      const { data, ok, response } = await LocalizationApiService.getLanguage(id, companyId);
      if (ok) {
        return data;
      }
      notificationService.error(response.message);
      throw response?.message;
    },
    {
      onError: (err) => {
        throw err;
      },
      staleTime: 0
    }
  );
};

export const useAddLanguage = (onSuccess: () => void) => {
  return useMutation(
    async (payload: addLanguagePayloadType) => {
      const { ok, response, data } = await LocalizationApiService.addLanguage(payload);
      if (ok) {
        onSuccess();
        return data;
      }
      notificationService.error(response?.message);
      throw response?.message;
    },
    {
      onError: (err) => {
        throw err;
      }
    }
  );
};

export const useUpdateLanguage = (id: number | undefined, onSuccess: () => void) => {
  return useMutation(
    async (payload: addLanguagePayloadType) => {
      const { ok, response, data } = await LocalizationApiService.updateLanguage(payload, id);
      if (ok) {
        onSuccess();
        return data;
      }
      notificationService.error(response?.message);
      throw response?.message;
    },
    {
      onError: (err) => {
        throw err;
      }
    }
  );
};

export const useUpdateLanguageStatus = () => {
  return useMutation((payload: updateStatusPayloadType) => LocalizationApiService.updateLanguageStatus(payload), {
    onSuccess: ({ ok, response, data }) => {
      if (ok) {
        queryClient.invalidateQueries(["LANGUAGE_LIST"]);
        return data;
      }
      notificationService.error(response.message);
      throw response?.message;
    },
    onError: (err) => {
      throw err;
    }
  });
};

export const useUpdateLanguageDefault = () => {
  return useMutation((id: { id: number }) => LocalizationApiService.updateLanguageDefault(id), {
    onSuccess: ({ ok, response, data }) => {
      if (ok) {
        queryClient.invalidateQueries(["LANGUAGE_LIST"]);
        return data;
      }
      notificationService.error(response?.message);
      throw response?.message;
    },
    onError: (err) => {
      throw err;
    }
  });
};

export const useDeleteLanguage = (onSuccess: () => void) => {
  return useMutation((id: { id: number }) => LocalizationApiService.deleteLanguage(id), {
    onSuccess: ({ ok, response, data }) => {
      if (ok) {
        onSuccess();
        queryClient.invalidateQueries(["LANGUAGE_LIST"]);
        return data;
      }
      notificationService.error(response.message);
      throw response?.message;
    },
    onError: (err) => {
      throw err;
    }
  });
};

export const useDownloadLanguage = () => {
  return useMutation((payload: downloadLanguageType) => LocalizationApiService.downloadLanguage(payload), {
    onSuccess: ({ ok, response, data, header }, dummyPayload) => {
      if (ok) {
        exportToFile(data, dummyPayload.languageName + " Language", "csv");
        return data;
      }
      throw response?.message;
    },
    onError: (err) => {
      throw err;
    }
  });
};

export const useLookupLanguages = (companyId?: number) => {
  return useQuery(languageQueryKeys.language_lookup(companyId), () => LookupApiService.getLanguages(companyId), {
    onError: (err) => {
      throw err;
    },
    select: languagesSelect,
    enabled: Boolean(companyId)
  });
};
