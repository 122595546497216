import React, { useState } from "react";
import { Button, Card, Form } from "antd";
import { PatternFormat } from "react-number-format";

import AccountSuccess from "./AccountSuccess";
import { VALIDATE } from "../../../constants/constant";
import AuthWrapper from "../../../layouts/AuthWrapper";
import FloatInput from "../../../components/FloatInput";
import { useLookupRoles, useSignUp } from "./authQueries";
import FloatSelect from "../../../components/FloatSelect/index";

import styles from "./Auth.module.scss";

type prevPayloadType = {
  fullName: string;
  email: string;
  password: string;
};
type payloadType = {
  companyName: string;
  companyEmail: string;
  companyPhoneNumber: string;
  roleId: string;
};
interface propParams {
  setPrevState: (a: boolean) => void;
  payload: prevPayloadType;
  lookupRoles: Array<any>;
}

const PersonalDetail: React.FC<propParams> = ({ setPrevState, payload, lookupRoles }) => {
  const [state, setState] = useState(false);

  const onSignUpSuccess = () => setState(true);

  const { data: signUpData, mutate: signUp, isLoading: signingUp } = useSignUp(onSignUpSuccess);

  const handleSubmit = (data: payloadType) => {    
    const newPayload = {
      ...payload,
      ...data
    };
    signUp(newPayload);
  };

  const adminRole = lookupRoles?.filter((role: any) => role.label === "Admin");

  return !state ? (
    <AuthWrapper>
      <Card className={`${styles["card-container"]}`}>
        <div className={`${styles["personal-container"]}`}>
          <h1 className={`hr-mb-40 ${styles["heading-input"]}`}>Personal Details</h1>
          <Form onFinish={handleSubmit}>
            <Form.Item name="companyName" rules={VALIDATE.COMPANY_NAME}>
              <FloatInput label="Company Name" />
            </Form.Item>
            <Form.Item name="companyEmail" rules={VALIDATE.EMAIL as never}>
              <FloatInput label="Company Email" />
            </Form.Item>
            <Form.Item
              name="companyPhoneNumber"
              rules={[
                ...VALIDATE.NUMBER,
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("companyPhoneNumber") === value.split("_")[0]) {
                      return Promise.resolve();
                    }
                    return Promise.reject("Invalid Phone Number");
                  }
                })
              ]}
            >
              <PatternFormat
                format="+1 (###) ###-####"
                mask="_"
                type="tel"
                label="Phone Number"
                customInput={FloatInput}
              />
            </Form.Item>
            <Form.Item name="domain" rules={VALIDATE.DOMAIN}>
              <FloatInput label="Domain" />
            </Form.Item>
            <Form.Item name="roleId" rules={VALIDATE.ROLE} initialValue={"1100CA57-3AAB-40C5-9C37-00FCD582BEEF"}>
              <FloatSelect label="Role" options={lookupRoles} disabled={Boolean(adminRole)} />
            </Form.Item>

            <Button loading={signingUp} className={`${styles["btn-auth"]}`} type="primary" htmlType="submit">
              Next
            </Button>

            <h4 className="text-blue text-center poppins-semibold cursor" onClick={() => setPrevState(false)}>
              Go back
            </h4>
          </Form>
        </div>
      </Card>
    </AuthWrapper>
  ) : (
    <AccountSuccess data={signUpData} />
  );
};

export default PersonalDetail;
