import { Col, Form, Radio, Space, Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

import { fieldChildType, fieldType } from "../../../../../types";
import { translationType } from "../../../../../constants/constant";
import { getLabelTranslation } from "../../../../../util/utils.service";

type radioType = {
  field: fieldType;
  getter: (id: string) => any;
  setter: any;
  translation: translationType;
  isMobile: boolean;
  conditionalFieldNames: any;
  getConditionArr: (conditionalFields: any, getter: any) => any;
  checkCondition: (allOne: string, conditionArr: Array<boolean>) => boolean;
};

const RadioButton = (props: radioType) => {
  const { field, getter, setter, translation, conditionalFieldNames, checkCondition, isMobile, getConditionArr } =
    props;
  const { options, label, required, type, id, condition, validationMessage, tip } = field;
  const name = id;
  const lowercasedType = type.toLowerCase();
  const { showHide, allOne, conditionalFields } = condition || {};
  return (
    <>
      <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className="hr-">
        {!condition ? (
          <div className="radio-group">
            <Space size={4} align={"end"}>
              <label className="external-form-label">
                {getLabelTranslation(label, [lowercasedType, name], translation)}
              </label>
              {tip && (
                <Tooltip title={getLabelTranslation(tip, ["tip", name], translation)} className="text-primary hr-fs-14">
                  <QuestionCircleOutlined />
                </Tooltip>
              )}
            </Space>
            <Form.Item
              name={name}
              rules={[
                {
                  required: required,
                  message: validationMessage
                    ? validationMessage
                    : getLabelTranslation("This field is required", ["validation", "required"], translation)
                }
              ]}
              valuePropName="checked"
              className="external-form-item"
            >
              <Radio.Group
                onChange={(a: any) => setter(name, a.target.value)}
                className={isMobile ? "d-flex-column " : ""}
              >
                {options?.map((radioInfo: fieldChildType) => (
                  <>
                    <Radio key={radioInfo.id} value={radioInfo.id}>
                      {getLabelTranslation(radioInfo.label, ["option", radioInfo?.id], translation)}
                    </Radio>
                  </>
                ))}
              </Radio.Group>
            </Form.Item>
          </div>
        ) : (
          <Form.Item noStyle dependencies={conditionalFieldNames}>
            {() => {
              const condition = checkCondition(allOne, getConditionArr(conditionalFields, getter));
              const shouldShow = showHide === (condition ? "show" : "hide");

              return shouldShow ? (
                <div className="radio-group">
                  <Space size={4} align={"end"}>
                    <label className="external-form-label">
                      {getLabelTranslation(label, [lowercasedType, name], translation)}
                    </label>
                    {tip && (
                      <Tooltip
                        title={getLabelTranslation(tip, ["tip", name], translation)}
                        className="text-primary hr-fs-14"
                      >
                        <QuestionCircleOutlined />
                      </Tooltip>
                    )}
                  </Space>
                  <Form.Item
                    name={name}
                    rules={[
                      {
                        required: required,
                        message: validationMessage
                          ? validationMessage
                          : getLabelTranslation("This field is required", ["validation", "required"], translation)
                      }
                    ]}
                    valuePropName="checked"
                    className="external-form-item"
                  >
                    <Radio.Group
                      onChange={(a: any) => setter(name, a.target.value)}
                      className={isMobile ? "d-flex-column " : ""}
                    >
                      {options?.map((radioInfo: fieldChildType) => (
                        <>
                          <Radio key={radioInfo.id} value={radioInfo.id}>
                            {getLabelTranslation(radioInfo.label, ["option", radioInfo?.id], translation)}
                          </Radio>
                        </>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                </div>
              ) : (
                <></>
              );
            }}
          </Form.Item>
        )}
      </Col>
    </>
  );
};
export default RadioButton;
