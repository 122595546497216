import React from "react";
import { Input } from "antd";

import FloatLabel from "../FloatLabel";

type FloatTextAreaParams = {
  label: string;
  value?: string;
  required?: boolean;
  [key: string]: any;
};

const FloatTextArea: React.FC<FloatTextAreaParams> = ({ label, type, value = "", required, ...rest }) => {
  const inputRef = React.useRef<any>();
  const handleInputFocus = () => {
    inputRef.current.focus();
  };
  return (
    <FloatLabel label={label} value={value} required={required} onClick={handleInputFocus}>
      <Input.TextArea ref={inputRef} {...rest} value={value}  className="pad-left"/>
    </FloatLabel>
  );
};

export default FloatTextArea;
